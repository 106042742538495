import React from 'react'
import PropTypes from 'prop-types'
import { get, flowRight as compose } from 'lodash'
import { connect } from 'react-redux'
import className from 'classnames'
import { withTranslation } from 'react-i18next'

import {
  addNotification,
} from '@redux-action'

import Notification from './Notification'

class Code extends React.Component {
  static propTypes = {
  }

  static defaultProps = {
    withOutCopy: false,
  }

  $code = React.createRef()
  $input = React.createRef()

  componentDidMount = () => {
    if (hljs) {
      hljs.highlightBlock(this.$code.current)
    }
  }

  onCopy = () => {
    const { t } = this.props
    this.$input.current.select()

    if (document.execCommand('copy')) {
      this.props.addNotification({
        message: t('Code.copySuccess'),
        type: Notification.NOTIFICATION_TYPES.success,
      })

      this.$input.current.blur()
    }
  }

  render() {
    const { t } = this.props

    return (
      <pre className='Api__content__pre'>
        <code className='Api__content__pre__code' ref={this.$code}>
          {this.props.children}
        </code>
        {!this.props.withOutCopy &&
          <a
            className='Badge'
            onClick={this.onCopy}
          >
            {t('Misc.copy')}
          </a>
        }
        <textarea
          ref={this.$input}
          value={this.props.children || ''}
          onChange={() => {}}
        />
      </pre>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  addNotification: (params) => dispatch(addNotification(params)),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation()
)(Code)