import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

const ListBullet = styled.li`
  background-position: center center;
  background-size: 9px 7px;
  background-repeat: no-repeat;
  background-color: #fafbfc;
  border: 3px solid #e6eaee;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: relative;
  transition: background-color 0.4s, border-color 0.4s;
  will-change: background-color, border-color;

  &::after {
    content: '';
    display: block;
    width: calc((780px - var(--global-space) * 2) / 4);
    height: 3px;
    background-color: #e6eaee;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: background-color 0.4s;
    will-change: background-color;
    z-index: -1;
  }

  &:first-of-type {
    &::after {
      display: none;
    }
  }

  ${(props) => {
    if (props.done)
      return css`
        background-color: var(--c-green);
        background-image: url('/assets/icon-check-white.svg');
        border-color: var(--c-green);

        &::after {
          background-color: var(--c-green);
        }
      `
    else if (props.current)
      return css`
        border-color: var(--c-green);

        &::after {
          background-color: var(--c-green);
        }
      `
  }}
`

const ProjectNewStep = ({ totalStep, currentStep }) => {
  return (
    <ul
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 auto',
        marginTop: 'calc(var(--global-space) * 1.5)',
        width: '25%',
        position: 'relative',
        zIndex: '1',
      }}
    >
      {Array.from(Array(totalStep)).map((_, i) => (
        <ListBullet
          key={i}
          done={i < currentStep}
          current={i === currentStep}
        />
      ))}
    </ul>
  )
}

export default ProjectNewStep
