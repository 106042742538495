import React from 'react'
import { get } from 'lodash'
import { withTranslation, Trans } from 'react-i18next'

import Switch from '@shared/forms/Switch'

const AutoRefillPane = ({t, user, handleClick, onToggle}) => {
  const autoRefill = get(user, 'attributes.auto_refill', false)

  const autoRefillThreshold = get(user,
    'attributes.auto_refill_threshold',
    0
  ).toLocaleString()

  const autoRefillCredit = get(user,
    'attributes.auto_refill_credit_amount',
    0
  ).toLocaleString()

  const onToggleAutoRefill = () => {
    onToggle({ auto_refill: !autoRefill })
  }

  return (
    <React.Fragment>
      <div className="Credits__L__toggle">
        <p className="Credits__L__toggle__p">
          {t('Credits.autoRefillCreditCard')}
        </p>
        <Switch
          small
          checked={autoRefill}
          onInputChange={onToggleAutoRefill}
        />
      </div>
      {autoRefill && (
        <p className="Credits__L__credit">
          <Trans
            i18nKey="Credits.autoRefillDesc1"
            values={{autoRefillThreshold}}
          >
            When credits less than&nbsp;
            <strong>
              <a onClick={handleClick}>
                {autoRefillThreshold}
              </a>
            </strong>
          </Trans>
          <br />
          <Trans
            i18nKey="Credits.autoRefillDesc2"
            values={{autoRefillCredit}}
          >
            auto refill&nbsp;
            <strong>
              <a onClick={handleClick}>
                {autoRefillCredit}
              </a>
            </strong>
            .
          </Trans>
        </p>
      )}
    </React.Fragment>
  )
}

export default withTranslation()(AutoRefillPane)
