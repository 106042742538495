import styled from '@emotion/styled'

export const Lower = styled.section`
  border-top: 1px solid #e3e3e3;
  padding: calc(var(--global-space) * 0.8) calc(var(--global-space) * 1.2);
  display: flex;
  align-items: center;

  a {
    &:not(:last-of-type) {
      margin-right: calc(var(--global-space) * 1.5);
    }
  }
`
