import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import className from 'classnames'
import moment from 'moment'
import { timeDays } from 'd3-time'
import { withTranslation } from 'react-i18next'

class DateSelector extends React.Component {
  static propTypes = {}

  static defaultProps = {
    selectedDate: moment(),
    onClickDate: () => {},
    right: false,
  }

  state = {
    isActive: false,
    selectedMonth: moment(this.props.selectedDate).startOf('month'),
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      selectedMonth: moment(nextProps.selectedDate).startOf('month'),
    })
  }

  _isActiveDay = (date) => {
    return date.isSame(this.props.selectedDate, 'day')
  }

  _isBetweenDay = (date) => {
    return false
  }

  _isDisabledDate = ({ date, today }) => {
    return date.isSameOrBefore(today)
  }

  _isDisabledMonthPrev = () => {
    return (
      +this.state.selectedMonth.startOf('month') == +moment().startOf('month')
    )
  }

  _isDisabledMonthNext = () => {
    return false
  }

  onTogglePanel = () => {
    this.setState((prevState, props) => ({
      isActive: !prevState.isActive,
    }))
  }

  onGoToMonth = (offset) => () => {
    this.setState((prevState, props) => ({
      selectedMonth: prevState.selectedMonth.add(offset, 'months'),
    }))
  }

  onClick = (date) => () => {
    this.setState(
      (prevState, props) => ({
        isActive: !prevState.isActive,
      }),
      () => {
        this.props.onClickDate(date)()
      }
    )
  }

  renderPanel = (t) => {
    return (
      <div
        className={className('DateSelector__panel', {
          'DateSelector__panel--active': this.state.isActive,
          'DateSelector__panel--right': this.props.right,
        })}
      >
        <div className="DateSelector__panel__header">
          <a
            className="DateSelector__panel__nav"
            disabled={this._isDisabledMonthPrev()}
            onClick={this.onGoToMonth(-1)}
          />
          <p className="DateSelector__panel__current-month">
            {this.state.selectedMonth.format('MMMM YYYY')}
          </p>
          <a
            className="DateSelector__panel__nav DateSelector__panel__nav--next"
            disabled={this._isDisabledMonthNext()}
            onClick={this.onGoToMonth(1)}
          />
        </div>

        {(() => {
          const today = moment()
          const selectedMonth = this.state.selectedMonth
          const firstDayOfMonth = moment(selectedMonth)
            .startOf('month')
            .startOf('week')
          const lastDayOfMonth = moment(selectedMonth)
            .endOf('month')
            .endOf('week')
          const dayInterval = timeDays(firstDayOfMonth, lastDayOfMonth)

          return (
            <ul className="DateSelector__ul">
              {[
                t('DateSelector.su'),
                t('DateSelector.mo'),
                t('DateSelector.tu'),
                t('DateSelector.we'),
                t('DateSelector.th'),
                t('DateSelector.fr'),
                t('DateSelector.sa'),
              ].map((day, i) => (
                <li key={day}>
                  <a className="DateSelector__ul__a DateSelector__ul__a--header">
                    {day}
                  </a>
                </li>
              ))}

              {dayInterval.map((day, i) => {
                const date = moment(day)

                return (
                  <li key={i}>
                    <a
                      onClick={this.onClick(date)}
                      disabled={this._isDisabledDate({ date, today })}
                      className={className('DateSelector__ul__a', {
                        'DateSelector__ul__a--active': this._isActiveDay(date),
                        'DateSelector__ul__a--diff-month':
                          date.month() != this.state.selectedMonth.month(),
                        'DateSelector__ul__a--in-between': this._isBetweenDay(
                          date
                        ),
                      })}
                    >
                      {date.format('D')}
                    </a>
                  </li>
                )
              })}
            </ul>
          )
        })()}
      </div>
    )
  }

  render = () => {
    return withTranslation()(
      <div
        className={className('DateSelector', {
          'DateSelector--left': this.props.left,
        })}
      >
        <a
          className="DateSelector__handle"
          onClick={this.onTogglePanel}
        >
          {this.props.selectedDate.format('YYYY/MM/DD')}
        </a>

        {this.renderPanel()}
      </div>
    )
  }
}

export const DateSelectorKlass = DateSelector
export default DateSelector
