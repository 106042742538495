import { combineReducers } from 'redux'
import { get } from 'lodash'
import moment from 'moment'

import Notification from '@shared/Notification'

import { replaceItemArray } from '@helpers'

const defaultMeta = {
  page: -1,
  per_page: 10,
  total: -1,
  search: null,
  sort: null,
  sort_direction: null,
}

const defaultState = {
  isLoading: false,
  isLoaded: false,
  isError: false,
  meta: defaultMeta,
  data: {},
}

const dataArrayDefaultState = {
  ...defaultState,
  data: [],
}

export const loadingState = (state) => ({
  ...state,
  isLoading: true,
  isLoaded: false,
  isError: false,
})

export const loadedState = (state, data) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  isError: false,
})

export const failedState = (state, data) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  isError: true,
})

const loginAuth = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN_AUTH_CREATE':
    case 'LOGIN_VERIFY_FACTOR_CREATE':
    case 'LOGIN_SIGNUP_CREATE':
      return loadingState(state)
    case 'LOGIN_SIGNUP_CREATE_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data,
      })
    }
    case 'LOGIN_AUTH_CREATE_FAIL':
    case 'LOGIN_VERIFY_FACTOR_CREATE_FAIL':
    case 'LOGIN_SIGNUP_CREATE_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const loginPasswordResetRequest = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN_PASSWORD_RESET_REQUEST_CREATE':
      return loadingState(state)
    case 'LOGIN_PASSWORD_RESET_REQUEST_CREATE_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return {
        ...state,
        data,
      }
    }
    case 'LOGIN_PASSWORD_RESET_REQUEST_CREATE_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const loginPasswordResetSent = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN_PASSWORD_RESET_SENT_LOAD':
      return loadingState(state)
    case 'LOGIN_PASSWORD_RESET_SENT_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data,
      })
    }
    case 'LOGIN_PASSWORD_RESET_SENT_LOAD_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const loginPasswordResetVerify = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN_PASSWORD_RESET_VERIFY_LOAD':
      return loadingState(state)
    case 'LOGIN_PASSWORD_RESET_VERIFY_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data,
      })
    }
    case 'LOGIN_PASSWORD_RESET_VERIFY_LOAD_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const loginPasswordNew = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN_PASSWORD_NEW_CREATE':
      return loadingState(state)
    case 'LOGIN_PASSWORD_NEW_CREATE_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return {
        ...state,
        data,
      }
    }
    case 'LOGIN_PASSWORD_NEW_CREATE_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const dashboardChart = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'DASHBOARD_CHART_LOAD':
      return loadingState(state)
    case 'DASHBOARD_CHART_LOAD_SUCCESS': {
      const meta = get(action, 'payload.data.meta', {})
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        meta,
        data,
      })
    }
    case 'DASHBOARD_CHART_LOAD_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const dashboardTasks = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'DASHBOARD_TASKS_LOAD':
      return loadingState(state)
    case 'DASHBOARD_TASKS_LOAD_SUCCESS': {
      const meta = get(action, 'payload.data.meta', {})
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        meta,
        data: [...state.data, ...data],
      })
    }
    case 'DASHBOARD_TASKS_LOAD_FAIL':
      return failedState(state)
    case 'DASHBOARD_TASKS_CLEAR':
      return dataArrayDefaultState
    default:
      return state
  }
}

const projects = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'PROJECTS_LOAD':
      return loadingState(state)
    case 'PROJECTS_LOAD_SUCCESS': {
      const meta = get(action, 'payload.data.meta', {})
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        meta,
        data,
      })
    }
    case 'PROJECTS_LOAD_FAIL':
      return failedState(state)
    case 'PROJECTS_CLEAR':
      return dataArrayDefaultState
    case 'PROJECT_DELETE':
      return loadingState(state)
    case 'PROJECT_DELETE_SUCCESS': {
      const id = get(action, 'payload.data.data.id')

      return loadedState({
        ...state,
        meta: {
          ...state.meta,
          total: get(state, 'meta.total', 0) - 1,
        },
        data: state.data.filter((x) => x.id !== id),
      })
    }
    case 'PROJECT_UPDATE':
      return loadingState(state)
    case 'PROJECT_UPDATE_SUCCESS':
      const { name, id } = get(action, 'payload.data.data', {})
      const index = state.data.findIndex(({ id: inputId }) => inputId === id)
      const newData = { ...state.data[index], name }

      return loadedState({
        ...state,
        data: replaceItemArray(state.data, newData, index),
      })
    case 'PROJECT_UPDATE_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const project = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case 'PROJECT_LOAD':
      return loadingState(state)
    case 'PROJECT_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data,
      })
    }
    case 'PROJECT_CLEAR':
      return defaultState
    case 'PROJECT_AUTO_REFILL_CREDIT_UPDATE':
      return loadingState(state)
    case 'PROJECT_AUTO_REFILL_CREDIT_UPDATE_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data: { ...state.data, ...data },
      })
    }
    case 'PROJECT_RESET_KEY_UPDATE_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data: {
          ...state.data,
          license_key: get(data, 'license_key'),
        },
      })
    }
    case 'PROJECT_AUTO_REFILL_CREDIT_UPDATE_FAIL':
      return failedState(state)
    case 'PROJECT_KEY_RESET':
      return loadingState(state)
    case 'PROJECT_KEY_RESET_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data,
      })
    }
    default:
      return state
  }
}

const projectChart = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'PROJECT_CHART_LOAD':
      return loadingState(state)
    case 'PROJECT_CHART_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        data,
      })
    }
    case 'PROJECT_CHART_LOAD_FAIL':
      return failedState(state)
    case 'PROJECT_CHART_CLEAR':
      return dataArrayDefaultState
    default:
      return state
  }
}

const projectNew = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case 'PROJECT_CREATE':
      return loadingState(state)
    case 'PROJECT_CREATE_SUCCESS': {
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        data,
      })
    }
    case 'PROJECT_CREATE_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const projectInputTypes = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'PROJECT_INPUT_TYPES_LOAD':
      return loadingState(state)
    case 'PROJECT_INPUT_TYPES_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        data,
      })
    }
    case 'PROJECT_INPUT_TYPES_LOAD_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const projectTemplates = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'PROJECT_TEMPLATES_LOAD':
      return loadingState(state)
    case 'PROJECT_TEMPLATES_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        data,
      })
    }
    case 'PROJECT_TEMPLATES_LOAD_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const projectNgWords = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'PROJECT_NG_WORDS_LOAD':
      return loadingState(state)
    case 'PROJECT_NG_WORDS_LOAD_SUCCESS': {
      const meta = get(action, 'payload.data.meta', {})
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        meta,
        data,
      })
    }
    case 'PROJECT_NG_WORDS_LOAD_FAIL':
      return failedState(state)
    case 'PROJECT_NG_WORDS_CREATE':
      return loadingState(state)
    case 'PROJECT_NG_WORDS_CREATE_SUCCESS':
      return loadedState({
        ...state,
      })
    case 'PROJECT_UPDATE_FAIL':
      return failedState(state)
    case 'PROJECT_NG_WORDS_DELETE':
      return loadingState(state)
    case 'PROJECT_NG_WORDS_DELETE_SUCCESS': {
      return loadedState({
        ...state,
      })
    }
    case 'PROJECT_NG_WORDS_DELETE_FAILED':
      return failedState(state)
    default:
      return state
  }
}

const exportProjectNgWords = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case 'PROJECT_NG_WORDS_EXPORT':
      return loadingState(state)
    case 'PROJECT_NG_WORDS_EXPORT_SUCCESS': {
      return loadedState({
        ...state,
      })
    }
    case 'PROJECT_NG_WORDS_EXPORT_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const user = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case 'USER_LOAD':
      return loadingState(state)
    case 'USER_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data,
      })
    }
    case 'USER_LOAD_FAILED':
      return failedState(state)
    case 'USER_UPDATE':
      return loadingState(state)
    case 'USER_UPDATE_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data,
      })
    }
    case 'USER_UPDATE_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const paymentHistories = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'USER_PAYMENT_HISTORIES_LOAD':
      return loadingState(state)
    case 'USER_PAYMENT_HISTORIES_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data,
      })
    }
    case 'USER_PAYMENT_HISTORIES_LOAD_FAILED':
      return failedState(state)
    default:
      return state
  }
}

const paymentsPurchaseCredit = (state = defaultState, action) => {
  switch (action.type) {
    case 'PAYMENTS_PURCHASE_CREDIT_CREATE':
      return loadingState(state)
    case 'PAYMENTS_PURCHASE_CREDIT_CREATE_SUCCESS':
      return loadedState({
        ...state,
      })
    case 'PAYMENTS_PURCHASE_CREDIT_CREATE_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const userCards = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'USERS_CARDS_LOAD':
    case 'USERS_CARDS_CREATE':
      return loadingState(state)
    case 'USERS_CARDS_CREATE_SUCCESS':
      return loadedState(state)
    case 'USERS_CARDS_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        data,
      })
    }
    case 'USERS_CARDS_DELETE_SUCCESS': {
      const id = get(action, 'payload.data.meta.card_id')

      return {
        ...state,
        data: state.data.filter((x) => x.id !== id),
      }
    }
    case 'USERS_CARDS_CREATE_FAIL':
    case 'USERS_CARDS_LOAD_FAIL':
      return failedState(state)
    case 'USERS_CARDS_CLEAR':
      return dataArrayDefaultState
    default:
      return state
  }
}

const userDefaultCard = (state = defaultState, action) => {
  switch (action.type) {
    case 'USERS_DEFAULT_CARDS_LOAD':
      return loadingState(state)
    case 'USERS_DEFAULT_CARDS_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data,
      })
    }
    case 'USERS_DEFAULT_CARDS_LOAD_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const users2FA = (state = defaultState, action) => {
  switch (action.type) {
    case 'USERS_2FA_LOAD':
      return loadingState(state)
    case 'USERS_2FA_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data,
      })
    }
    case 'USERS_2FA_LOAD_FAIL':
      return failedState(state)
    case 'USERS_2FA_CREATE':
      return loadingState(state)
    case 'USERS_2FA_CREATE_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data: {
          ...state.data,
          ...data,
        },
      })
    }
    case 'USERS_2FA_UPDATE':
    case 'USERS_2FA_DELETE':
    case 'USERS_2FA_ADMIN_DELETE':
      return loadingState(state)
    case 'USERS_2FA_UPDATE_SUCCESS':
    case 'USERS_2FA_DELETE_SUCCESS':
    case 'USERS_2FA_ADMIN_DELETE_SUCCESS':
      return loadedState(state)
    case 'USERS_2FA_CREATE_FAIL':
    case 'USERS_2FA_UPDATE_FAIL':
    case 'USERS_2FA_DELETE_FAIL':
    case 'USERS_2FA_ADMIN_DELETE_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const notifications = (state = { data: [] }, action) => {
  if (action.type.endsWith('_FAIL')) {
    return {
      ...state,
      data: [
        ...state.data,
        {
          index: +moment(),
          key: `${action.type} ${moment().format('HH:mm:ss')}`,
          message:
            get(action, 'error.response.data.meta.message') ||
            get(action, 'error.response.data.errors'),
          type: Notification.NOTIFICATION_TYPES.error,
        },
      ],
    }
  }

  switch (action.type) {
    case 'NOTIFICATION_ADD':
      const params = get(action, 'params', {})

      return {
        ...state,
        data: [
          ...state.data,
          {
            index: +moment(),
            key: `${action.type} ${moment().format('HH:mm:ss')}`,
            message: params.message,
            type: params.type,
          },
        ],
      }
    case 'NOTIFICATION_REMOVE':
      return {
        ...state,
        data: state.data.filter((x) => x.index !== action.params.index),
      }
    default:
      return state
  }
}

const datePickerDefaultProps = {
  startDate: moment()
    .subtract(10, 'days')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
}

const datePicker = (state = { ...datePickerDefaultProps }, action) => {
  switch (action.type) {
    case 'DATEPICKER_UPDATE':
      return {
        ...state,
        ...get(action, 'params'),
      }
    default:
      return state
  }
}

const outputTasks = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'OUTPUT_TASK_LOAD':
      return loadingState(state)
    case 'OUTPUT_TASK_LOAD_SUCCESS': {
      const meta = get(action, 'payload.data.meta', {})
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        meta,
        data: [...state.data, ...data],
      })
    }
    case 'OUTPUT_TASK_LOAD_FAIL':
      return failedState(state)
    case 'OUTPUT_TASK_CLEAR':
      return dataArrayDefaultState
    default:
      return state
  }
}

const outputTasksApi = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'OUTPUT_TASK_API_LOAD':
      return loadingState(state)
    case 'OUTPUT_TASK_API_LOAD_SUCCESS': {
      const meta = get(action, 'payload.data.meta', {})
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        meta,
        data,
      })
    }
    case 'OUTPUT_TASK_API_LOAD_FAIL':
      return failedState(state)
    case 'OUTPUT_TASK_API_CLEAR':
      return dataArrayDefaultState
    default:
      return state
  }
}

const requestInspection = (state = defaultState, action) => {
  switch (action.type) {
    case 'REQUEST_INSPECTION_LOAD':
      return loadingState(state)
    case 'REQUEST_INSPECTION_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', {})

      return loadedState({
        ...state,
        data,
      })
    }
    case 'REQUEST_INSPECTION_LOAD_FAIL':
      return failedState(state)
    case 'REQUEST_INSPECTION_CLEAR':
      return defaultState
    default:
      return state
  }
}

const creditUsage = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'CREDIT_USAGE_LOAD':
      return loadingState(state)
    case 'CREDIT_USAGE_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', [])

      return loadedState({ ...state, data })
    }
    case 'CREDIT_USAGE_LOAD_FAIL':
      return failedState(state)
    case 'CREDIT_USAGE_CLEAR':
      return dataArrayDefaultState
    default:
      return state
  }
}

const taskLog = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'TASK_LOG_LOAD':
      return loadingState(state)
    case 'TASK_LOG_LOAD_SUCCESS': {
      const data = get(action, 'payload.data.data', [])

      return loadedState({ ...state, data })
    }
    case 'TASK_LOG_LOAD_FAIL':
      return failedState(state)
    case 'TASK_LOG_CLEAR':
      return dataArrayDefaultState
    default:
      return state
  }
}

const fileDownload = (state = defaultState, action) => {
  switch (action.type) {
    case 'INVOICE_LOAD':
      return loadingState(state)
    case 'INVOICE_LOAD_SUCCESS':
      return loadedState(state)
    default:
      return defaultState
  }
}

const members = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'MEMBERS_LOAD':
      return loadingState(state)
    case 'MEMBERS_LOAD_SUCCESS': {
      const meta = get(action, 'payload.data.meta', {})
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        meta,
        data,
      })
    }
    case 'MEMBERS_LOAD_FAIL':
      return failedState(state)
    case 'MEMBERS_CLEAR':
      return dataArrayDefaultState
    case 'MEMBER_UPDATE':
      return loadingState(state)
    case 'MEMBER_UPDATE_SUCCESS': {
      const updatedMember = get(action, 'payload.data.data', {})
      const { id } = updatedMember
      const index = state.data.findIndex(({ id: inputId }) => inputId === id)

      return loadedState({
        ...state,
        data: replaceItemArray(state.data, updatedMember, index),
      })
    }
    case 'MEMBER_UPDATE_FAIL':
      return failedState(state)
    case 'MEMBER_DELETE':
      return loadingState(state)
    case 'MEMBER_DELETE_SUCCESS': {
      const id = get(action, 'payload.data.data.id')

      return loadedState({
        ...state,
        meta: {
          ...state.meta,
          total: get(state, 'meta.total', 0) - 1,
        },
        data: state.data.filter((x) => x.id !== id),
      })
    }
    case 'MEMBER_DELETE_FAIL':
      return failedState(state)
    case 'MEMBER_INVITE':
    case 'MEMBER_RESENT_INVITE':
    case 'CHANGE_OWNERSHIP':
      return loadingState(state)
    case 'MEMBER_INVITE_SUCCESS':
    case 'MEMBER_RESENT_INVITE_SUCCESS':
    case 'CHANGE_OWNERSHIP_SUCCESS':
      return loadedState(state)
    case 'MEMBER_INVITE_FAIL':
    case 'MEMBER_RESENT_INVITE_FAIL':
    case 'CHANGE_OWNERSHIP_FAIL':
      return failedState(state)
    default:
      return state
  }
}

const actionLogs = (state = dataArrayDefaultState, action) => {
  switch (action.type) {
    case 'ACTION_LOGS_LOAD': {
      return loadingState(state)
    }
    case 'ACTION_LOGS_LOAD_SUCCESS': {
      const meta = get(action, 'payload.data.meta', {})
      const data = get(action, 'payload.data.data', [])

      return loadedState({
        ...state,
        meta,
        data,
      })
    }
    case 'ACTION_LOGS_LOAD_FAIL': {
      return failedState(state)
    }
    case 'ACTION_LOGS_CLEAR': {
      return dataArrayDefaultState
    }
    default:
      return state
  }
}

const reducers = combineReducers({
  loginAuth,
  loginPasswordResetRequest,
  loginPasswordResetSent,
  loginPasswordResetVerify,
  loginPasswordNew,
  dashboardChart,
  dashboardTasks,
  projects,
  project,
  projectChart,
  projectNew,
  projectNgWords,
  datePicker,
  users2FA,
  paymentsPurchaseCredit,
  notifications,
  outputTasks,
  outputTasksApi,
  projectInputTypes,
  projectTemplates,
  user,
  userCards,
  paymentHistories,
  userDefaultCard,
  requestInspection,
  creditUsage,
  taskLog,
  fileDownload,
  members,
  actionLogs,
  exportProjectNgWords,
})

export default reducers
