import React from 'react'
import className from 'classnames'

const Pagination = ({ total, currentPage, perPage, onChangePage }) => {
  const BUTTON_TO_SHOW = 6
  const totalPage = Math.ceil(total / perPage)

  const onClick = (page) => (e) => {
    onChangePage(page)
  }

  const renderButton = () => {
    if (totalPage <= BUTTON_TO_SHOW) {
      return Array(totalPage)
        .fill()
        .map((_, index) => (
          <button
            key={index + 1}
            data-page={index + 1}
            onClick={onClick(index + 1)}
            className={className('Pagination__item', {
              'Pagination__item--active': currentPage === index + 1,
            })}
          >
            {index + 1}
          </button>
        ))
    }

    let pages = []

    if (currentPage + BUTTON_TO_SHOW > totalPage) {
      // Last 6 page
      pages = ['...']
      for (let i = totalPage - 5; i <= totalPage; i += 1) {
        pages.push(i)
      }
    } else {
      // First half
      if (currentPage === 1) {
        for (let i = currentPage; i <= currentPage + 2; i += 1) {
          pages.push(i)
        }
      } else {
        // Next 2 page of currentPage
        for (let i = currentPage - 1; i <= currentPage + 1; i += 1) {
          pages.push(i)
        }
      }
      pages.push('...')

      // Last half
      for (let i = totalPage - 2; i <= totalPage; i += 1) {
        pages.push(i)
      }
    }

    return pages.map((page) => {
      if (page === '...') {
        return (
          <div
            key={page}
            className="Pagination__item Pagination__item--no-cursor"
          >
            {page}
          </div>
        )
      } else {
        return (
          <button
            key={page}
            data-page={page}
            onClick={currentPage === page ? undefined : onClick(page)}
            className={className('Pagination__item', {
              'Pagination__item--active': currentPage === page,
            })}
          >
            {page}
          </button>
        )
      }
    })
  }

  return (
    <div className="Pagination">
      <div className="Pagination__container">
        <button
          className={className('Pagination__apex Pagination__apex--left')}
          onClick={onClick(1)}
          disabled={currentPage === 1}
        >
          &#60;&#60;
        </button>
        <button
          className={className('Pagination__item Pagination__item--w-border')}
          onClick={onClick(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &#60;
        </button>
        {renderButton()}
        <button
          className={className('Pagination__item Pagination__item--w-border')}
          onClick={onClick(currentPage + 1)}
          disabled={currentPage >= totalPage}
        >
          &#62;
        </button>
        <button
          className={className('Pagination__apex Pagination__apex--right')}
          onClick={onClick(totalPage)}
          disabled={currentPage >= totalPage}
        >
          &#62;&#62;
        </button>
      </div>
    </div>
  )
}

export default Pagination
