import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { get, pickBy, flowRight as compose } from 'lodash'
import moment from 'moment'

import {
  ProjectSection,
  ProjectContent,
  ProjectHeader,
} from '@shared/style/Project.style'
import Notification from '@shared/Notification'

import AddNgWordsForm from './AddNgWordsForm'
import { NgWordTable } from './NgWordTable'

import {
  addNotification,
  clearProjectNgWords,
  createProjectNgWord,
  deleteProjectNgWord,
  exportProjectNgWords,
  getProject,
  getProjectNgWords,
  loadProjectNgWords,
} from '@redux-action'
import { getFile } from '@helpers'

const ProjectNgWords = (props) => {
  const {
    createProjectNgWord,
    deleteProjectNgWord,
    exportProjectNgWords,
    loadProjectNgWords,
    projectNgWords,
    match: {
      params: { id },
    },
    t,
  } = props

  React.useEffect(() => {
    loadProjectNgWords({ project_id: id })
  }, [])

  const _getMeta = () => {
    let page = get(projectNgWords, 'meta.page')

    if (!page || page < 0) {
      page = null
    }

    return pickBy({ page, per_page: get(projectNgWords, 'meta.per_page') })
  }

  const onAddNgWord = (data) => {
    const { word, category } = data

    createProjectNgWord({ word, category, project_id: id }).then((res) => {
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        addNotification({
          message: t('ProjectNgWords.toast.addSuccess'),
          type: Notification.NOTIFICATION_TYPES.success,
        })
        loadProjectNgWords({ ..._getMeta(), project_id: id })
      }
    })
  }

  const onDeleteNgWord = (ngWordId) => {
    deleteProjectNgWord({ project_id: id, ng_word_id: ngWordId }).then(
      (res) => {
        if (get(res, 'type', '').endsWith('_SUCCESS')) {
          addNotification({
            message: t('ProjectNgWords.toast.deleteSuccess'),
            type: Notification.NOTIFICATION_TYPES.success,
          })
          loadProjectNgWords({ ..._getMeta(), project_id: id })
        }
      }
    )
  }

  const onExportNgWord = () => {
    exportProjectNgWords({ project_id: id }).then((res) => {
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        addNotification({
          message: t('ProjectNgWords.toast.exportSuccess'),
          type: Notification.NOTIFICATION_TYPES.success,
        })

        getFile(res, 'csv', `ng-word-${moment().format('YYYY-MM-DD')}`)
      }
    })
  }

  const onChangePage = (page = projectNgWords.meta.page) => {
    clearProjectNgWords()
    loadProjectNgWords({ ..._getMeta(), page, project_id: id })
  }

  return (
    <ProjectContent>
      <div
        className="NgWords"
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 350px',
          columnGap: 'var(--global-space)',
        }}
      >
        <div>
          <ProjectHeader>
            <h3>{t('ProjectNgWords.title')}</h3>

            <button
              onClick={() => {
                onExportNgWord()
              }}
              className="Button Button--green"
            >
              {t('Misc.export')}
            </button>
          </ProjectHeader>
          <ProjectSection>
            <NgWordTable
              t={t}
              ngWords={projectNgWords.data}
              meta={projectNgWords.meta}
              isLoading={projectNgWords.isLoading}
              isLoaded={projectNgWords.isLoaded}
              onDeleteNgWord={onDeleteNgWord}
              onChangePage={onChangePage}
            />
          </ProjectSection>
        </div>
        <div>
          <ProjectHeader>
            <h3>{t('ProjectNgWords.form.addNgWord')}</h3>
          </ProjectHeader>
          <ProjectSection>
            <AddNgWordsForm
              isLoading={projectNgWords.isLoading}
              onSubmit={(data) => onAddNgWord(data)}
              t={t}
            />
          </ProjectSection>
        </div>
      </div>
    </ProjectContent>
  )
}

const mapState = (state) => ({
  project: getProject(state),
  projectNgWords: getProjectNgWords(state),
})

export default compose(
  connect(mapState, {
    addNotification,
    clearProjectNgWords,
    createProjectNgWord,
    deleteProjectNgWord,
    exportProjectNgWords,
    loadProjectNgWords,
  }),
  withTranslation()
)(ProjectNgWords)
