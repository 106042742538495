import React from 'react'
import { withTranslation } from 'react-i18next'

class SectionLoader extends React.Component {
  static propTypes = {
  }

  static defaultProps = {
    customPlaceholder: null,
    alwaysDisplayChildren: false,
  }

  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        {(
          (!this.props.isLoading && this.props.isLoaded && !this.props.isEmpty) ||
          this.props.alwaysDisplayChildren
        ) &&
          <React.Fragment>
            {this.props.children}
          </React.Fragment>
        }

        {this.props.isLoading &&
          <p className='SectionLoader__loading'>{t('Misc.loading')} …</p>
        }

        {!this.props.isLoading && this.props.isLoaded && this.props.isEmpty &&
          <React.Fragment>
            {this.props.customPlaceholder ?
              this.props.customPlaceholder
            :
              <p className='SectionLoader__empty'>{t('Misc.noData')}</p>
            }
          </React.Fragment>
        }

        {!this.props.isLoading && !this.props.isLoaded && this.props.isError &&
          <p className='SectionLoader__error'>{t('Misc.errorOccurred')}</p>
        }
      </React.Fragment>
    )
  }
}

export default withTranslation()(SectionLoader)
