import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import className from 'classnames';

class Class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <svg className='CheckBox' width='16px' height='16px' viewBox='0 0 16 16'>
        <g>
          <rect
            className={className('CheckBox__rect', {
              [`CheckBox__rect--${this.props.color}`]: true,
              'CheckBox__rect--active': this.props.isActive,
            })}
            x='0.5' y='0.5' width='15' height='15' rx='4'
          />
          <path
            className={className('CheckBox__check', {
              [`CheckBox__check--${this.props.color}`]: true,
              'CheckBox__check--active': this.props.isActive,
            })}
            d='M14.792567,4.7640194 L14.1916351,4.18033582 C13.9440543,3.93988806 13.5399202,3.93988806 13.2902096,4.18033582 L6.98891596,10.3028612 L3.68802234,7.09671194 C3.44046915,6.85623731 3.03630745,6.85623731 2.78662447,7.09671194 L2.18566489,7.68039552 C1.9381117,7.92087015 1.9381117,8.31340448 2.18566489,8.5559209 L6.53610106,12.7814612 C6.66095638,12.9027328 6.82174149,12.9643896 6.98468404,12.9643896 C7.1476266,12.9643896 7.31054149,12.9027328 7.43329468,12.7814612 L14.7862053,5.63957164 C15.0401202,5.39501343 15.0401202,5.0045209 14.792567,4.7640194 L14.792567,4.7640194 Z'
          />
        </g>
      </svg>
    );
  }
}

Class.propTypes = {
};

Class.defaultProps = {
  color: 0,
  isActive: false,
};

export default Class;
