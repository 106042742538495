import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import { get } from 'lodash'
import EXIF from 'exif-js'
import { withTranslation } from 'react-i18next'

class ImageLoader extends React.Component {
  static propTypes = {
    url: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    renderAsImg: false,
    renderPlaceholderAsText: false,
  }

  _isMounted = false

  state = {
    needsPlaceholder: false,
    orientation: 1,
  }

  componentDidMount = () => {
    this._isMounted = true

    setTimeout(() => {
      this._loadImage(this.props)
    }, 100)
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.url != prevProps.url) {
      setTimeout(() => {
        this._loadImage(this.props)
      }, 100)
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  _loadImage = (props) => {
    const _img = new Image()
    _img.src = props.url

    // Image passed as props may be changed from undefined, so at first try
    // it might be error. We need to give it another shot once prop changes.
    _img.onload = () => {
      if (this._isMounted) {
        this.setState(
          {
            needsPlaceholder: false,
          },
          () => {
            const _this = this

            EXIF.getData(_img, function() {
              const orientation = EXIF.getTag(this, 'Orientation') || 1

              _this.setState({
                orientation,
              })
            })
          }
        )
      }
    }

    _img.onerror = () => {
      if (this._isMounted) {
        this.setState(() => ({
          needsPlaceholder: true,
        }))
      }
    }
  }

  render = () => {
    const { t } = this.props
    const klassName = className('ImageLoader', this.state.orientation, {
      [`${this.props.klassName}`]: this.props.klassName,
      [`ImageLoader--orientation--${this.state.orientation}`]: this.state
        .orientation,
    })

    if (this.props.renderAsImg && !this.state.needsPlaceholder) {
      return (
        <img
          className={klassName}
          src={this.props.url}
          data-url={this.props.url}
        />
      )
    }

    if (this.props.renderPlaceholderAsText && this.state.needsPlaceholder) {
      return (
        <p
          className={{
            ...klassName,
            'ImageLoader--placeholder-text': true,
          }}
          data-url={this.props.url}
        >
          {t('ImageLoader.noImage')}
        </p>
      )
    }

    return (
      <figure
        className={klassName}
        style={{
          backgroundImage: this.state.needsPlaceholder
            ? 'none'
            : `url(${this.props.url})`,
        }}
        data-url={this.props.url}
      />
    )
  }
}

export default withTranslation()(ImageLoader)
