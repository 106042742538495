import React from 'react'
import PropTypes from 'prop-types'
import { get, startCase } from 'lodash'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import DotsMenu from '../DotsMenu'

class ProjectItem extends React.Component {
  openDeleteModal = (id, value) => () => {
    if (id) {
      this.props.onDeleteProject(id, value)
    }
  }

  openUpdateModal = (id, value) => () => {
    if (id) {
      this.props.onUpdateProject(id, value)
    }
  }

  render = () => {
    const {
      t,
    } = this.props
    const processedTasks = get(this.props, 'data.tasks', []).reduce(
      (sum, cur) => sum + cur.processed,
      0
    )

    return (
      <div className="Table__tr">
        <div className="Table__td">
          <Link
            to={`/app/projects/${get(this.props, 'data.id')}/summary`}
            className="Table__td__link"
          >
            {get(this.props, 'data.name')}
          </Link>
        </div>
        <div className="Table__td">
          {startCase(get(this.props, 'data.template')).replace('Ai', 'AI')}
        </div>
        <div className="Table__td">
          {get(this.props, 'data.remaining_tasks')}
        </div>
        <div className="Table__td">{processedTasks}</div>
        <div className="Table__td">
          <DotsMenu
            data={[
              {
                label: t('Misc.edit'),
                onClick: this.openUpdateModal(
                  get(this.props, 'data.id'),
                  get(this.props, 'data.name')
                ),
              },
              {
                label: t('Misc.delete'),
                onClick: this.openDeleteModal(
                  get(this.props, 'data.id'),
                  get(this.props, 'data.name')
                ),
              },
            ]}
          />
        </div>
      </div>
    )
  }
}

export default withTranslation()(ProjectItem)
