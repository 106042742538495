import React from 'react'
import PropTypes from 'prop-types'
import { get, first, omitBy } from 'lodash'
import className from 'classnames'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  getProjectChart,
  loadProjectChart,
  clearProjectChart,
  getDatePicker,
} from '@redux-action'

import DateSelectorRange from '@shared/forms/DateSelectorRange'
import SectionLoader from '@shared/SectionLoader'
import GraphToggler from '@shared/graphs/GraphToggler'

class ProjectChart extends React.Component {
  componentDidMount = () => {
    this.props.clearProjectChart()
    this.props.loadProjectChart({
      id: get(this.props, 'match.params.id'),
      start_date: get(this.props, 'datePicker.startDate'),
      end_date: get(this.props, 'datePicker.endDate'),
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.datePicker.startDate !== prevProps.datePicker.startDate ||
      this.props.datePicker.endDate !== prevProps.datePicker.endDate
    ) {
      this.props.loadProjectChart({
        id: get(this.props, 'match.params.id'),
        start_date: this.props.datePicker.startDate,
        end_date: this.props.datePicker.endDate,
      })
    }
  }

  render() {
    const dataPack = get(this.props, 'projectChart.data', []).map(
      (data) => {
        return {
          key: moment(data.time, 'YYYY-MM-DD HH:mm:ss ZZ').format(
            'YYYY-MM-DD'
          ),
          data: {
            created: data.created || 0,
            completed: data.processed || 0,
          },
        }
      }
    )

    return (
        <div className="Central__content__section Central__content__section--no-boder-top">
          <div className="Project__filter-selector">
            <DateSelectorRange
              right
              withIcon
            />
          </div>
          <SectionLoader
            isLoading={get(this.props, 'projectChart.isLoading')}
            isLoaded={get(this.props, 'projectChart.isLoaded')}
            isError={get(this.props, 'projectChart.isError')}
            isEmpty={get(this.props, 'projectChart.data.length', 0) === 0}
          >
            <GraphToggler
              axisYLabel="Tasks"
              dataPack={dataPack}
            />
          </SectionLoader>
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
  projectChart: getProjectChart(state),
  datePicker: getDatePicker(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadProjectChart: (params) => dispatch(loadProjectChart(params)),
  clearProjectChart: (params) => dispatch(clearProjectChart(params)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectChart)
