import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash'

import { getUser } from '@redux-action'

const withAuthorization = (requiredRoles = []) => (PageComponent) => {
  const EnhancedComponent = (props) => {
    const { user } = props

    if (!user.isLoaded) return 'Loading...'

    if (requiredRoles.includes(get(user, 'data.attributes.role'))) {
      return <PageComponent {...props} />
    } else {
      return <Redirect to="/app" />
    }
  }

  const mapState = (state) => ({ user: getUser(state) })

  return connect(mapState)(EnhancedComponent)
}

export default withAuthorization
