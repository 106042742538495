import React from 'react'
import { get, flowRight as compose } from 'lodash'
import className from 'classnames'
import { Link, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Cookies from 'js-cookie'
import { withTranslation } from 'react-i18next'

import { getUser, loadUser } from '@redux-action'

import {
  COOKIES_KEY,
  COOKIES_KEY_NOTIFICATION,
  RouteWithSubRoutes,
  isOwner,
  isUser,
} from '@lib/helpers'

import Notification from '@shared/Notification'
import DotsMenu from '@shared/DotsMenu'
import Footer from '@layouts/Footer'

class Main extends React.Component {
  state = {
    redirect: false,
  }

  componentDidMount = () => {
    if (!Cookies.get(COOKIES_KEY)) {
      this.setState({
        redirect: true,
      })
    } else {
      this.props.loadUser().then((res) => {
        const lang = get(res, 'payload.data.data.attributes.language', '')
        const id = get(res, 'payload.data.data.id', '')
        const email = get(res, 'payload.data.data.attributes.email', '')

        if (lang && lang === 'en') {
          this.props.i18n.changeLanguage(lang)
        }
      })
    }
  }

  onSignOut = () => {
    const { t } = this.props

    Cookies.remove(COOKIES_KEY)

    Cookies.set(
      COOKIES_KEY_NOTIFICATION,
      JSON.stringify({
        message: t('Main.signOutSuccess'),
        type: Notification.NOTIFICATION_TYPES.success,
      })
    )

    window.location = '/' // See Landing's `onSubmit`.
  }

  render() {
    const { t, user, i18n } = this.props

    if (this.state.redirect) {
      return <Redirect to="/" />
    }

    // For better UX
    if (get(user, 'data.attributes.language') !== i18n.language) {
      return null
    }

    if (
      get(this.props, 'location.pathname').startsWith('/app/projects/new') ||
      get(this.props, 'location.pathname').startsWith('/app/api')
    ) {
      return (
        <Switch>
          {this.props.routes &&
            this.props.routes.map((route, i) => (
              <RouteWithSubRoutes {...route} key={i} />
            ))}
        </Switch>
      )
    }

    return (
      <div className="Main">
        <div className="Sidebar">
          <h1>
            <Link to="/app">Posmoni</Link>
          </h1>

          {(() => {
            let data = [
              { label: `${t('Main.dashboard')}`, to: '/app' },
              { label: `${t('Main.projects')}`, to: '/app/projects' },
              {
                label: `${t('Main.billnPayments')}`,
                to: '/app/billings',
                children: [
                  {
                    label: `${t('Main.credits')}`,
                    to: '/app/billings/credits',
                  },
                  {
                    label: `${t('Main.paymentMethod')}`,
                    to: '/app/billings/payment-methods',
                  },
                ],
              },
              { label: `${t('Main.userManagement')}`, to: '/app/users' },
              { label: `${t('Main.settings')}`, to: '/app/settings' },
              { label: `${t('ActionLog.actionLog')}`, to: '/app/logs' },
            ]

            if (!isOwner(get(user, 'data.attributes.role'))) {
              data = data.filter((item) => item.to !== '/app/billings')
            }

            if (isUser(get(user, 'data.attributes.role'))) {
              data = data.filter((item) => item.to !== '/app/users')
            }

            const isMatched = (x) => {
              if (
                x.to === '/app/projects' ||
                x.to.startsWith('/app/billings')
              ) {
                return get(this.props, 'location.pathname').startsWith(x.to)
              }

              return get(this.props, 'location.pathname') === x.to
            }

            return (
              <ul className="Sidebar__ul">
                {data.map((x, i) => (
                  <li key={i}>
                    <Link
                      to={x.to}
                      className={className('Sidebar__ul__a', {
                        'Sidebar__ul__a--active': isMatched(x),
                      })}
                    >
                      {x.label}
                    </Link>

                    {x.children && (
                      <ul className="Sidebar__ul__ul">
                        {x.children.map((xx, j) => (
                          <li key={j}>
                            <Link
                              to={xx.to}
                              className={className('Sidebar__ul__ul__a', {
                                'Sidebar__ul__ul__a--active': get(
                                  this.props,
                                  'location.pathname'
                                ).startsWith(xx.to),
                              })}
                            >
                              {xx.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
                <li>
                  <a className="Sidebar__ul__a" onClick={this.onSignOut}>
                    {t('Main.logout')}
                  </a>
                </li>
              </ul>
            )
          })()}

          <div className="Profile">
            <div className="Profile__username">
              <Link to="/app/settings">
                {get(this.props, 'user.data.attributes.email')}
              </Link>

              <div className="Profile__username__dropdown">
                <DotsMenu
                  useCaret
                  data={[
                    {
                      label: t('Main.logout'),
                      onClick: this.onSignOut,
                      nowrap: true,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="Central">
          <Switch>
            {this.props.routes &&
              this.props.routes.map((route, i) => (
                <RouteWithSubRoutes {...route} key={i} />
              ))}
          </Switch>
          <Footer />
        </div>

        <Notification />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: getUser(state),
})

export default compose(
  connect(
    mapStateToProps,
    { loadUser }
  ),
  withTranslation()
)(Main)
