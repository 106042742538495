import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import className from 'classnames'

class Switch extends React.Component {
  static propTypes = {}

  static defaultProps = {
    small: false,
  }

  state = {
    checked: false
  }

  onChange = () => {
    this.setState(prevState => ({checked: !prevState.checked}))
  }

  render() {
    return (
      <label
        className={className('Switch', {
          'Switch--small': this.props.small,
          'Switch--medium': this.props.medium
        })}
      >
        <div
          className={className('Switch__wrapper-outer', {
            'Switch__wrapper-outer--inline': this.props.mode === 'inline'
          })}
        >
          <input
            type='checkbox'
            checked={this.props.checked || this.state.checked}
            name={this.props.name}
            onChange={this.props.onInputChange || this.onChange}
          />
          <div className='Switch__wrapper' />
        </div>
      </label>
    )
  }
}

export default Switch
