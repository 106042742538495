import React from 'react'
import { isEmpty } from 'lodash'
import { withTranslation } from 'react-i18next'

import { CardElement } from 'react-stripe-elements'

class StripeCardForm extends React.Component {
  static defaultProps = {
    onInputChange: () => {},
    onInputCardChange: () => {},
  }

  state = {
    form: {},
  }

  onKeyDown = (e) => {
    const $el = e.target
    const value = $el.value

    if (value.length >= 4 && e.keyCode !== 8 && $el.name !== 'number3') {
      $el.nextElementSibling.focus()
    }

    if (e.keyCode === 8 && isEmpty($el.value) && $el.name !== 'number0') {
      $el.previousElementSibling.focus()
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="CreditCardForm">
        <label className="Label">{t('CreditCardForm.holderName')}</label>
        <input
          type="text"
          className="Input"
          placeholder={t('CreditCardForm.namePlaceholder')}
          name="name"
          onChange={this.props.onInputChange}
        />

        <label className="Label">{t('CreditCardForm.creditCardNumber')}</label>
        <div>
          <div className="Input">
            <CardElement hidePostalCode={true} />
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(StripeCardForm)
