import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import { Button } from '@shared/style'
import {
  HeaderStyle,
  SectionStyle,
  BadgeStyle,
  Triangle,
} from './Endpoint.style'

const PostMethod = ({
  arrayData,
  header,
  headerDesc,
  onSubmit,
  expanded,
  setExpanded,
}) => {
  const { t } = useTranslation()

  const isOpen = header === expanded

  return (
    <React.Fragment>
      <motion.div
        initial={false}
        onClick={() => setExpanded(isOpen ? false : header)}
        css={HeaderStyle}
      >
        <span css={{ textTransform: 'uppercase' }}>{header}</span>
        <span css={{ fontWeight: 'initial' }}>{headerDesc}</span>
        <Triangle isOpen={isOpen} />
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }}
            css={{ marginTop: '20px', overflow: 'hidden' }}
          >
            <form onSubmit={onSubmit}>
              {arrayData.map((item) => (
                <div css={SectionStyle} key={item.field}>
                  <p>{t('ApiExplorer.dataType')}: </p>
                  <span css={BadgeStyle}>{item.dataType}</span>
                  <p>{t('ApiExplorer.parameter')} :</p>
                  <label htmlFor={item.field} css={{ fontWeight: 'bold' }}>
                    {item.field}
                  </label>
                  <p css={{ color: '#b0c1d2' }}>{t('ApiExplorer.description')} :</p>
                  <p css={{ color: '#b0c1d2' }}>{item.desc}</p>
                  {item.input}
                </div>
              ))}
              <Button primary type="submit" css={{ marginTop: '24px' }}>
                {t('ApiExplorer.tryItOut')}
              </Button>
            </form>
          </motion.section>
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default PostMethod
