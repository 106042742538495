import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import className from 'classnames'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { ClickOutside } from '@helpers'

class DotsMenu extends React.Component {
  static defaultProps = {
    data: [],
    useCaret: false,
    customItem: null,
    panelRight: false,
  }

  state = {
    isActive: false,
  }

  onClick = (e) => {
    e.stopPropagation()

    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }))
  }

  onCloseMenu = () => {
    this.state.isActive && this.setState({ isActive: false })
  }

  render() {
    const { isActive, panelRight } = this.state
    const { useCaret } = this.props

    return (
      <div
        onClick={this.onClick}
        className={className('DotsMenu', {
          'DotsMenu--active': isActive,
          'DotsMenu--caret': useCaret,
        })}
      >
        {useCaret ? (
          <a className="DotsMenu__caret" />
        ) : (
          <a className="DotsMenu__handle">
            <svg
              width="4px"
              height="16px"
              viewBox="0 0 4 16"
            >
              <circle
                cx="2"
                cy="2"
                r="2"
              />
              <circle
                cx="2"
                cy="8"
                r="2"
              />
              <circle
                cx="2"
                cy="14"
                r="2"
              />
            </svg>
          </a>
        )}
        <CSSTransition
          in={isActive}
          timeout={400}
          classNames="DotsMenu__panel"
          unmountOnExit
        >
          <ClickOutside
            key="key"
            onClickOutside={this.onCloseMenu}
            className={className('DotsMenu__panel', {
              'DotsMenu__panel--right': panelRight,
            })}
          >
          <ul>
            {this.props.customItem && this.props.customItem}
            {this.props.data.map((x, i) => {
              if (!x.isHidden) {
                return (
                  <li
                    key={i}
                    className={className('DotsMenu__panel__li', {
                      'DotsMenu__panel__li--nowrap': x.nowrap,
                    })}
                  >
                    {x.to ? (
                      <Link
                        to={x.to}
                        className="DotsMenu__panel__a"
                      >
                        {x.label}
                      </Link>
                    ) : (
                      <a
                        className="DotsMenu__panel__a"
                        onClick={x.onClick}
                      >
                        {x.label}
                      </a>
                    )}
                  </li>
                )
              }
            })}
            </ul>
          </ClickOutside>
        </CSSTransition>
      </div>
    )
  }
}

export default DotsMenu
