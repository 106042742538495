import React from 'react'
import { get, omitBy, flowRight as compose } from 'lodash'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  getDashboardChart,
  loadDashboardChart,
  getDashboardTasks,
  loadDashboardTasks,
  clearDashboardTasks,
  getDatePicker,
} from '@redux-action'

import withHeader from '@features/withHeader'

import CentralHeader from '@layouts/CentralHeader'
import DateSelectorRange from '@shared/forms/DateSelectorRange'
import Search from '@shared/forms/Search'
import Select from '@shared/forms/Select'
import { TaskOverview, DashboardTasks } from '@layouts/Dashboard'

const Dashboard = ({
  datePicker,
  dashboardChart,
  dashboardTasks,
  loadDashboardChart,
  loadDashboardTasks,
  clearDashboardTasks,
}) => {
  const [status, setStatus] = React.useState('created')
  const { t } = useTranslation()

  let searchTO = React.useRef()

  React.useEffect(() => {
    clearDashboardTasks()
    loadDashboardTasks(_getMeta())
    loadDashboardChart(_getMetaChart())
  }, [])

  React.useEffect(() => {
    loadDashboardChart(_getMetaChart())
  }, [datePicker.startDate, datePicker.endDate])

  const _getMetaChart = () => {
    const params = omitBy(
      {
        start_date: datePicker.startDate,
        end_date: datePicker.endDate,
        status: get(dashboardChart, 'meta.status'),
      },
      (x) => !x
    )

    return params
  }

  const _getMeta = () => {
    let page = get(dashboardTasks, 'meta.page')

    if (!page || page < 0) {
      page = null
    }

    const params = omitBy(
      {
        sort: 'processed_at', // get(this.props, 'dashboardTasks.meta.sort'),
        page,
        sort_direction: get(dashboardTasks, 'meta.sort_direction'),
        status: get(dashboardTasks, 'meta.status'),
      },
      (x) => !x
    )

    return params
  }

  const onLoadMore = (e) => {
    loadDashboardTasks({
      ..._getMeta(),
      page: (get(dashboardTasks, 'meta.page') || 1) + 1,
    })
  }

  const onChangeTaskType = (e) => {
    clearDashboardTasks()
    loadDashboardTasks({
      ..._getMeta(),
      page: 1,
      status: e.target.value,
    })
  }

  const onChangeChartStatus = ({ target: { value } }) => {
    setStatus(value)
    loadDashboardChart({
      ..._getMetaChart(),
      status: value,
    })
  }

  const onSearch = (e) => {
    const query = e.target.value

    window.clearTimeout(searchTO)
    searchTO = window.setTimeout(() => {
      clearDashboardTasks()
      loadDashboardTasks(
        omitBy(
          {
            ..._getMeta(),
            page: null,
            search_query: query,
          },
          (x) => !x
        )
      )
    }, 1000)
  }

  return (
    <React.Fragment>
      <CentralHeader
        header={t('Dashboard.dashboard')}
        breadcrumbs={[{ label: t('Dashboard.dashboard') }]}
      />

      <div className="Central__sub-header" />

      <div className="Central__content">
        <div className="Central__content__header">
          <h3 className="Central__content__h3">
            {t('Dashboard.taskOverview')}
          </h3>
          <DateSelectorRange
            right
            withIcon
            isLoading={dashboardChart.isLoading}
          />
        </div>

        <TaskOverview
          dashboardChart={dashboardChart}
          onChangeChartStatus={onChangeChartStatus}
          status={status}
        />

        <div className="Central__content__select-search">
          <Select
            value={get(dashboardTasks, 'meta.status', '')}
            isLoading={dashboardTasks.isLoading}
            onChange={onChangeTaskType}
            label={t('Misc.status')}
            options={[
              { label: t('selectOptions.all'), value: '' },
              { label: t('selectOptions.processed'), value: 'processed' },
              { label: t('selectOptions.processing'), value: 'processing' },
              { label: t('selectOptions.unprocessed'), value: 'unprocessed' },
            ]}
          />

          <Search
            placeholder={t('Dashboard.searchProject')}
            onChange={onSearch}
          />
        </div>

        <DashboardTasks
          dashboardTasks={dashboardTasks}
          onLoadMore={onLoadMore}
        />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  dashboardChart: getDashboardChart(state),
  dashboardTasks: getDashboardTasks(state),
  datePicker: getDatePicker(state),
})

export default compose(
  withHeader({ title: 'Customer Dashboard - Posmoni' }),
  connect(mapStateToProps, {
    loadDashboardChart,
    loadDashboardTasks,
    clearDashboardTasks,
  })
)(Dashboard)
