import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Notification from '@shared/Notification'

class Landing extends React.Component {
  render = () => {
    return (
      <div className="Landing">
        <h1 className="Landing__h1">
          <Link to="/">Kiyo</Link>
        </h1>
        {this.props.children}
        <Notification />
      </div>
    )
  }
}

export default Landing
