import React from 'react'
import { get, pickBy } from 'lodash'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import { getProject, getUser } from '@redux-action'

import { ProjectContent, ProjectHeader } from '@shared/style/Project.style'

import ApiList from '@layouts/projects/ApiExplorer/ApiList'
import PreviewResponse from '@layouts/projects/ApiExplorer/PreviewResponse'
import { getTypeFromTemplate } from '@helpers'

const formDefaultState = {
  data: '',
  custom_id: '',
  postback_url: '',
  postback_method: '',
  query: '',
}

const ApiExplorer = (props) => {
  const { project, user } = props

  const [form, setForm] = React.useState(formDefaultState)
  const [preview, setPreview] = React.useState()
  const [answer, setAnswer] = React.useState()

  const { t } = useTranslation()

  React.useEffect(() => {
    setForm((form) => ({
      ...form,
      postback_method: get(project, 'data.attributes.postback_method'),
      postback_url: get(project, 'data.attributes.postback_url'),
    }))
  }, [project])

  const onChange = ({ target: { name, value } }) =>
    setForm((form) => ({ ...form, [name]: value }))

  const onSubmitPost = (e) => {
    e.preventDefault()

    const preparedData = pickBy(form, (x) => x)

    axios
      .post(window.gon.MODERATION_URL, preparedData, {
        headers: { Authorization: get(project, 'data.attributes.license_key') },
      })
      .then((res) => {
        setPreview(JSON.stringify(res.data, null, 4))
      })
      .catch((e) => {
        const data = get(e, 'response.data')
        setPreview(JSON.stringify(data, null, 4))
      })
  }

  const onSubmitGet = (e) => {
    e.preventDefault()

    axios
      .get(`${window.gon.MODERATION_URL}?query=${form.query}`, {
        headers: {
          Authorization: get(project, 'data.attributes.license_key'),
        },
      })
      .then((res) => {
        setPreview(JSON.stringify(res.data, null, 4))

        const type = getTypeFromTemplate(
          get(project, 'data.attributes.template')
        )

        if (type === 'text') {
          setAnswer(get(res, 'data.data.attributes.answer'))
        }
      })
      .catch((e) => {
        const data = get(e, 'response.data')
        setPreview(JSON.stringify(data, null, 4))
      })
  }

  return (
    <ProjectContent css={{ display: 'flex', flexDirection: 'column' }}>
      <ProjectHeader>
        <h3>{t('ApiExplorer.apiExplorer')}</h3>
      </ProjectHeader>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr minmax(0, 1fr)',
          columnGap: '20px',
          alignItems: 'stretch',
          flex: '1',
        }}
      >
        <ApiList
          form={form}
          onChange={onChange}
          onSubmitPost={onSubmitPost}
          onSubmitGet={onSubmitGet}
          project={project}
        />
        <PreviewResponse
          preview={preview}
          answer={answer}
          type={get(project, 'data.attributes.template')}
          user={user}
        />
      </div>
    </ProjectContent>
  )
}

const mapState = (state) => ({
  project: getProject(state),
  user: getUser(state),
})

export default connect(mapState)(ApiExplorer)
