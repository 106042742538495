import React from 'react'
import { get, omitBy, flowRight as compose } from 'lodash'
import className from 'classnames'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import {
  getProjects,
  loadProjects,
  clearProjects,
  updateProject,
  deleteProject,
  addNotification,
} from '@redux-action'

import withHeader from '@features/withHeader'

import CentralHeader from '@layouts/CentralHeader'
import Select from '@shared/forms/Select'
import Search from '@shared/forms/Search'
import ProjectsGridView from '@shared/projects/ProjectsGridView'
import ProjectsListView from '@shared/projects/ProjectsListView'
import Notification from '@shared/Notification'
import Modal from '@shared/Modal'

const sortDirectionSeparator = '-'

class Projects extends React.Component {
  searchTO = null

  state = {
    layout: 'grid',
    isModalOpen: false,
    modal: {
      deleteModal: false,
      updateModal: false,
      creditModal: false,
    },
    selectedProject: null,
    projectName: '',
  }

  _getMeta = () => {
    let page = get(this.props, 'projects.meta.page')

    if (!page || page < 0) {
      page = null
    }

    const params = omitBy(
      {
        page,
        sort_by: get(this.props, 'projects.meta.sort_by'),
        sort_direction: get(this.props, 'projects.meta.sort_direction'),
        template: get(this.props, 'projects.meta.template'),
        search_query: get(this.props, 'projects.meta.search_query'),
      },
      (x) => !x
    )

    return params
  }

  componentDidMount = () => {
    this.props.loadProjects(this._getMeta())
  }

  onLoadMore = () => {
    this.props.loadProjects({
      ...this._getMeta(),
      per_page: (get(this.props, 'projects.meta.per_page') || 10) + 10,
    })
  }

  onFilterByType = (e) => {
    this.props.clearProjects()
    this.props.loadProjects(
      omitBy(
        {
          ...this._getMeta(),
          page: 1,
          template: e.target.value,
          per_page: get(this.props, 'projects.meta.per_page'),
        },
        (x) => !x
      )
    )
  }

  onSortProjects = (e) => {
    const [sort_by, direction] = e.target.value.split(sortDirectionSeparator)
    this.props.clearProjects()
    this.props.loadProjects(
      omitBy(
        {
          ...this._getMeta(),
          page: 1,
          sort_by: sort_by,
          sort_direction: direction,
          per_page: get(this.props, 'projects.meta.per_page'),
        },
        (x) => !x
      )
    )
  }

  onLayoutChange = (layout) => () => {
    this.setState({
      layout,
    })
  }

  onDeleteProject = (e) => {
    e.preventDefault()

    const { t } = this.props
    const id = this.state.selectedProject

    if (id) {
      this.onToggleModal('deleteModal')()
      this.props.deleteProject({ id }).then((res) => {
        if (get(res, 'type', '').endsWith('_SUCCESS')) {
          this.props.addNotification({
            message: t('Projects.projectDeleteNoti', {
              name: get(res, 'payload.data.data.name'),
            }),
            type: Notification.NOTIFICATION_TYPES.success,
          })

          this.props.loadProjects({...this._getMeta()})
        }
      })
    }
  }

  onInputChange = ({ target: { value } }) => {
    this.setState({ projectName: value })
  }

  onSaveProjectName = (e) => {
    e.preventDefault()

    const { t } = this.props
    const { selectedProject: id, projectName: name } = this.state

    if (id && name) {
      this.onToggleModal('updateModal')()
      this.props.updateProject({ id, name }).then((res) => {
        if (get(res, 'type', '').endsWith('_SUCCESS')) {
          this.props.loadProjects({...this._getMeta()})
          this.props.addNotification({
            message: t('Projects.projectUpdateNoti', {
              name: get(res, 'payload.data.data.name'),
            }),
            type: Notification.NOTIFICATION_TYPES.success,
          })
        }
      })
    }
  }

  onSearch = (e) => {
    const query = e.target.value

    window.clearTimeout(this.searchTO)
    this.searchTO = window.setTimeout(() => {
      this.props.clearProjects()
      this.props.loadProjects(
        omitBy(
          {
            ...this._getMeta(),
            page: null,
            search_query: query,
          },
          (x) => !x
        )
      )
    }, 1000)
  }

  onToggleModal = (modal) => (id = null, value = '') => {
    this.setState((prevState) => ({
      selectedProject: id,
      projectName: value,
      modal: { ...prevState.modal, [modal]: !prevState.modal[modal] },
    }))
  }

  render() {
    const {
      modal: { deleteModal, updateModal, creditModal },
      projectName,
    } = this.state
    const { t } = this.props

    return (
      <React.Fragment>
        <CentralHeader
          header={
            <React.Fragment>
              {t('Projects.title')}
              <Link to="/app/projects/new" className="Central__header__h2__a">
                {t('Projects.new')}
              </Link>
            </React.Fragment>
          }
          breadcrumbs={[{ label: t('Projects.title') }]}
        />

        <div className="Central__sub-header">
          <div className="Central__sub-header__filter">
            <Select
              label={t('Misc.template')}
              value={get(this.props, 'projects.meta.template', '')}
              isLoading={get(this.props, 'projects.isLoading')}
              onChange={this.onFilterByType}
              options={[
                { label: t('selectOptions.all'), value: '' },
                { label: 'Image AI', value: 'image_ai' },
                { label: 'Image Human Check', value: 'image_bulk_check' },
                { label: 'AI Human', value: 'ai_human' },
                { label: 'AI Human Consensus', value: 'ai_human_consensus' },
                { label: 'ID Card Check', value: 'id_card_check' },
                { label: 'Text AI', value: 'text_ai' },
                { label: 'Text AI NG', value: 'text_ai_ng' },
                { label: 'Text Human Check', value: 'text_bulk_check' },
                { label: 'Video Snapshot', value: 'video_snapshot' },
              ]}
            />

            <Select
              label={t('Misc.sort')}
              value={`${get(
                this.props,
                'projects.meta.sort_by'
              )}${sortDirectionSeparator}${get(
                this.props,
                'projects.meta.sort_direction'
              )}`}
              isLoading={get(this.props, 'projects.isLoading')}
              onChange={this.onSortProjects}
              options={[
                {
                  label: `${t('selectOptions.createdDate')} (${t(
                    'selectOptions.oldToNew'
                  )})`,
                  value: `created_at${sortDirectionSeparator}asc`,
                },
                {
                  label: `${t('selectOptions.createdDate')} (${t(
                    'selectOptions.newToOld'
                  )})`,
                  value: `created_at${sortDirectionSeparator}desc`,
                },
                {
                  label: `${t('ProjectItems.creditUsage')} (${t('selectOptions.hiToLow')})`,
                  value: `credit_usage${sortDirectionSeparator}desc`,
                },
                {
                  label: `${t('ProjectItems.creditUsage')} (${t('selectOptions.lowToHi')})`,
                  value: `credit_usage${sortDirectionSeparator}asc`,
                },
                {
                  label: `${t('Header.remainingTasks')} (${t('selectOptions.hiToLow')})`,
                  value: `remaining_tasks${sortDirectionSeparator}desc`,
                },
                {
                  label: `${t('Header.remainingTasks')} (${t('selectOptions.lowToHi')})`,
                  value: `remaining_tasks${sortDirectionSeparator}asc`,
                },
                {
                  label: `${t('ProjectItems.tasksDoneToday')} (${t('selectOptions.hiToLow')})`,
                  value: `today_tasks${sortDirectionSeparator}desc`,
                },
                {
                  label: `${t('ProjectItems.tasksDoneToday')} (${t('selectOptions.lowToHi')})`,
                  value: `today_tasks${sortDirectionSeparator}asc`,
                },
                {
                  label: `${t('ProjectItems.lastUpdated')} (${t('selectOptions.oldToNew')})`,
                  value: `updated_at${sortDirectionSeparator}asc`,
                },
                {
                  label: `${t('ProjectItems.lastUpdated')} (${t('selectOptions.newToOld')})`,
                  value: `updated_at${sortDirectionSeparator}desc`,
                },
              ]}
            />

            <div className="Central__sub-header__filter__right">
              <a
                onClick={this.onLayoutChange('grid')}
                className={className('Central__sub-header__filter__right__a', {
                  'Central__sub-header__filter__right__a--grid': true,
                  'Central__sub-header__filter__right__a--grid--active':
                    this.state.layout === 'grid',
                })}
              />

              <a
                onClick={this.onLayoutChange('list')}
                className={className('Central__sub-header__filter__right__a', {
                  'Central__sub-header__filter__right__a--list': true,
                  'Central__sub-header__filter__right__a--list--active':
                    this.state.layout === 'list',
                })}
              />

              <Search
                placeholder={t('Dashboard.searchProject')}
                onChange={this.onSearch}
              />
            </div>
          </div>
        </div>

        {this.state.layout === 'grid' ? (
          <ProjectsGridView
            projects={get(this.props, 'projects', [])}
            onDeleteProject={this.onToggleModal('deleteModal')}
            onUpdateProject={this.onToggleModal('updateModal')}
            onConfirmDelete={this.onToggleModal('creditModal')}
            onLoadMore={this.onLoadMore}
          />
        ) : (
          <ProjectsListView
            projects={get(this.props, 'projects', [])}
            onDeleteProject={this.onToggleModal('deleteModal')}
            onUpdateProject={this.onToggleModal('updateModal')}
            onConfirmDelete={this.onToggleModal('creditModal')}
            onLoadMore={this.onLoadMore}
          />
        )}

        <Modal
          isActive={deleteModal}
          toggleFunction={this.onToggleModal('deleteModal')}
        >
          <form onSubmit={this.onDeleteProject}>
            <h3 className="Modal__h3">{t('Projects.deleteProject')}</h3>
            <p className="Modal__message">{t('Projects.deleteConfirm')}</p>
            <div className="Modal__button Modal__flex">
              <button
                className="Button Button--gray"
                onClick={this.onToggleModal('deleteModal')}
              >
                {t('Misc.cancel')}
              </button>
              <button className="Button Button--green" type="submit">
                {t('Misc.submit')}
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          isActive={updateModal}
          toggleFunction={this.onToggleModal('updateModal')}
        >
          <h3 className="Modal__h3">{t('Projects.editProject')}</h3>
          <form onSubmit={this.onSaveProjectName}>
            <input
              type="text"
              value={projectName}
              maxLength="25"
              className="Input"
              onChange={this.onInputChange}
              required
            />
            <div className="Modal__button Modal__button--evenly">
              <button
                className="Button Button--gray"
                onClick={this.onToggleModal('updateModal')}
                type="button"
              >
                {t('Misc.close')}
              </button>
              <button className="Button Button--green" type="submit">
                &nbsp;&nbsp;&nbsp;{t('Misc.save')}&nbsp;&nbsp;&nbsp;
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          isActive={creditModal}
          toggleFunction={this.onToggleModal('creditModal')}
        >
          <h3 className="Modal__h3">Project Cannot be Deleted</h3>
          <p className="Modal__message">
            Please withdraw your credits from Project:&nbsp;
            <span className="Bold">{this.state.projectName}</span> before
            deleting project.
          </p>
          <div className="Modal__button">
            <Link
              className="Button Button--green"
              to={`/app/projects/${this.state.selectedProject}/budget`}
              onClick={this.onToggleModal('confirmDelete')}
            >
              Go to Budget
            </Link>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  projects: getProjects(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadProjects: (params) => dispatch(loadProjects(params)),
  clearProjects: (params) => dispatch(clearProjects(params)),
  deleteProject: (params) => dispatch(deleteProject(params)),
  updateProject: (params) => dispatch(updateProject(params)),
  addNotification: (params) => dispatch(addNotification(params)),
})

export default compose(
  withHeader(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(Projects)
