import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { useForm } from 'react-hook-form'

import {
  addNotification,
  getProject,
  updateProjectAwsCredential,
} from '@redux-action'

import { Input, Button } from '@shared/style'
import { PasswordInput } from '@shared/forms'
import { ProjectSection, ProjectSubHeader } from '@shared/style/Project.style'

import Notification from '@shared/Notification'

const ProjectAwsSetting = (props) => {
  const { project, addNotification, updateProjectAwsCredential } = props

  const { t } = useTranslation()

  const { register, handleSubmit } = useForm({
    defaultValues: {
      aws_bucket: get(project, 'data.attributes.aws_bucket'),
      aws_region: get(project, 'data.attributes.aws_region'),
      aws_access_key_id: get(project, 'data.attributes.aws_access_key_id'),
      aws_secret_access_key: get(
        project,
        'data.attributes.aws_secret_access_key'
      ),
    },
  })

  const onSubmit = (form) => {
    updateProjectAwsCredential({
      id: project.data.attributes.id,
      ...form,
    }).then((res) => {
      console.log(res)
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        addNotification({
          message: t('ProjectOutput.successUpdate'),
          type: Notification.NOTIFICATION_TYPES.success,
        })
      } else if (get(res, 'type', '').endsWith('_FAIL')) {
        addNotification({
          message: t('Misc.errorOccurred'),
          type: Notification.NOTIFICATION_TYPES.error,
        })
      }
    })
  }

  return (
    <ProjectSection>
      <ProjectSubHeader>3. AWS Credentials</ProjectSubHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          css={{
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: 'max-content 1fr',
            alignItems: 'center',
          }}
        >
          <div {...formControlProps}>
            <label htmlFor="aws_bucket">AWS Bucket</label>
            <Input {...register('aws_bucket')} id="aws_bucket" />
          </div>
          <div {...formControlProps}>
            <label htmlFor="aws_region">AWS Region</label>
            <Input {...register('aws_region')} id="aws_region" />
          </div>
          <div {...formControlProps}>
            <label htmlFor="aws_access_key_id">AWS Access Key Id</label>
            <PasswordInput
              {...register('aws_access_key_id')}
              id="aws_access_key_id"
            />
          </div>
          <div {...formControlProps}>
            <label htmlFor="aws_secret_access_key">AWS Secret Access Key</label>
            <PasswordInput
              {...register('aws_secret_access_key')}
              id="aws_secret_access_key"
            />
          </div>
          <Button type="submit" primary css={{ justifySelf: 'start' }}>
            {t('Misc.save')}
          </Button>
        </div>
      </form>
    </ProjectSection>
  )
}

const mapState = (state) => ({
  project: getProject(state),
})

export default connect(mapState, {
  addNotification,
  updateProjectAwsCredential,
})(ProjectAwsSetting)

const formControlProps = {
  role: 'group',
  css: {
    display: 'contents',
    alignItems: 'center',
  },
}
