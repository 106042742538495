import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'

let options = {}

if (process.env.NODE_ENV !== 'development') {
  options = {
    loadPath: '/public/locales/{{lng}}/{{ns}}.json',
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'ja',
    // fallbackLng: 'en', //for development
    // debug: true, //for development
    backend: options,
  })

export default i18n
