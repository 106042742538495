import React from 'react'
import styled from '@emotion/styled'
import { motion, AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import USAFlag from '../../../assets/images/icon-flag-usa.svg'
import JapanFlag from '../../../assets/images/icon-flag-japan.svg'

import { ClickOutside } from '@helpers'

const Flag = styled.img`
  display: inline-block;
  height: 22px;
  width: 36px;
  cursor: pointer;
`

const ChangeLanguage = (props) => {
  const { updateUserLanguage, loadUser } = props
  const [isShow, setIsShow] = React.useState(false)
  const { i18n } = useTranslation()

  const onOpen = () => setIsShow((isShow) => !isShow)

  const changeLang = (lang) => (e) => {
    e.stopPropagation()

    i18n.changeLanguage(lang)
    updateUserLanguage({ language: lang }).then(loadUser)
    setIsShow(false)
  }

  const renderFlag = (lang) => {
    switch (lang) {
      case 'en':
        return <Flag src={USAFlag} />
      case 'ja':
        return <Flag src={JapanFlag} />
      default:
        return <Flag src={USAFlag} />
    }
  }

  const renderOptions = () => {
    const options = [
      { lang: 'en', icon: USAFlag },
      { lang: 'ja', icon: JapanFlag },
    ]

    return options.map((flag) => (
      <img
        src={flag.icon}
        onClick={changeLang(flag.lang)}
        alt={`${flag.lang}-icon`}
        key={flag.lang}
        css={{ cursor: 'pointer', width: '100%' }}
      />
    ))
  }

  return (
    <div
      css={{
        alignSelf: 'end',
        position: 'relative',
        border: '1px solid var(--c-border)',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 80,
        cursor: 'pointer',

        '&::after': {
          content: `''`,
          border: '6px solid #000',
          borderRightColor: 'transparent',
          borderLeftColor: 'transparent',
          borderBottomColor: 'transparent',
          width: 0,
          height: 0,
          display: 'inline-block',
          transform: 'translateY(3px)',
        },
      }}
      onClick={onOpen}
    >
      {renderFlag(i18n.language)}
      <AnimatePresence>
        {isShow && (
          <ClickOutside onClickOutside={() => setIsShow(false)}>
            <motion.div
              css={{
                padding: '8px',
                borderRadius: '4px',
                backgroundColor: '#fff',
                boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
                position: 'absolute',
                right: 0,
                top: '48px',
                display: 'grid',
                gridTemplateColumns: '48px 48px',
                gap: '8px',
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {renderOptions()}
            </motion.div>
          </ClickOutside>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ChangeLanguage
