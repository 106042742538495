import React from 'react'
import { useForm } from 'react-hook-form'
import { withTranslation } from 'react-i18next'
import { capitalize } from 'lodash'

import ButtonSubmit from '@shared/forms/ButtonSubmit'
import Select from '@shared/forms/Select'

import { NG_WORD_CATEGORIES } from '../constants'

const AddNgWordsForm = (props) => {
  const { onSubmit, t, isLoading } = props

  const { register, handleSubmit, formState, watch, setValue, reset } = useForm(
    {
      defaultValues: {
        word: '',
        category: NG_WORD_CATEGORIES[0],
      },
    }
  )

  const category = watch('category')

  return (
    <form
      onSubmit={handleSubmit((data) => {
        if (formState.isSubmitting) return

        onSubmit(data)
        reset()
      })}
    >
      <div css={{ display: 'grid', gap: '16px' }}>
        <div>
          <label htmlFor="word">{t('ProjectNgWords.title')}</label>
          <input
            id="word"
            className="Input"
            css={{ marginTop: '12px' }}
            required
            {...register('word')}
          />
        </div>

        <CategorySelector
          isLoading={isLoading}
          value={category}
          onChange={(val) => setValue('category', val)}
          t={t}
        />

        <ButtonSubmit label={t('Misc.submit')} isLoading={isLoading} />
      </div>
    </form>
  )
}

export default withTranslation()(AddNgWordsForm)

const CategorySelector = (props) => {
  const { isLoading, onChange, value, t } = props

  const options = React.useMemo(
    () =>
      NG_WORD_CATEGORIES.map((word) => ({
        label: capitalize(word),
        value: word,
      })),
    []
  )

  return (
    <Select
      label={t('ProjectNgWords.category')}
      value={value}
      isLoading={isLoading}
      onChange={(e) => onChange(e.target.value)}
      options={options}
    />
  )
}
