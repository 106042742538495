import React from 'react'

import RoleSelector from '../RoleSelector'
import ButtonSubmit from '@shared/forms/ButtonSubmit'
import { ProjectSection, ProjectHeader } from '@shared/style/Project.style'

const InviteForm = (props) => {
  const { form, onChange, onSubmit, t, isLoading } = props
  const { email, role } = form

  return (
    <React.Fragment>
      <ProjectHeader>
        <h3>{t('UserManagement.addNewUser')}</h3>
      </ProjectHeader>
      <ProjectSection
        css={{
          label: {
            display: 'block',
            marginBottom: 'calc(var(--global-space))',
          },
          input: {
            marginBottom: 'var(--global-space)',
          },
          button: {
            display: 'block',
            marginTop: 'calc(var(--global-space))',
          },
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit()
          }}
          autoComplete={'off'}
        >
          <label htmlFor="email">{t('Signup.email')}</label>
          <input
            type="email"
            name="email"
            id="email"
            className="Input"
            value={email}
            onChange={onChange}
            required
          />
          <RoleSelector
            value={role}
            isLoading={isLoading}
            onChange={onChange}
            t={t}
          />
          <ButtonSubmit label={t('Misc.submit')} isLoading={isLoading} />
        </form>
      </ProjectSection>
    </React.Fragment>
  )
}

export default InviteForm
