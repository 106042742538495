import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import className from 'classnames'
import { withTranslation } from 'react-i18next'

const ProjectMenu = (props) => {
  const { id, t, template } = props

  const history = useHistory()
  const location = useLocation()
  const menus = [
    {
      name: t('Header.summary'),
      link: `/app/projects/${id}/summary`,
      value: 'summary',
    },
    {
      name: t('Header.setting'),
      link: `/app/projects/${id}/setting`,
      value: 'setting',
    },
    {
      name: t('Header.apiExplorer'),
      link: `/app/projects/${id}/api`,
      value: 'api',
    },
  ]

  const onChangeLink = (value) => () => {
    history.push(value)
  }

  return (
    <ul className="Project__menu">
      {menus.map((menu) => {
        const exp = new RegExp(menu.value, 'g')

        return (
          <li
            key={menu.value}
            onClick={onChangeLink(menu.value)}
            className={className({
              'Project__menu--active': exp.test(location.pathname),
            })}
          >
            {menu.name}
          </li>
        )
      })}

      {template === 'text_ai_ng' && (
        <li
          onClick={onChangeLink('ng-words')}
          className={className({
            'Project__menu--active': new RegExp('ng-words', 'g').test(
              location.pathname
            ),
          })}
        >
          {t('Header.ngWords')}
        </li>
      )}
    </ul>
  )
}

export default withTranslation()(ProjectMenu)
