import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

import {
  getProject,
  loadProject,
  clearProject,
  updateProject,
  addNotification,
} from '@redux-action'

import CentralHeader from '@layouts/CentralHeader'
import ProjectMenu from '@shared/ProjectMenu'
import Modal from '@shared/Modal'
import Notification from '@shared/Notification'
import EditIcon from '../../../assets/images/edit-dnd-item.svg'

const ProjectLayout = (props) => {
  const {
    match: {
      params: { id },
    },
    clearProject,
    loadProject,
    updateProject,
    addNotification,
  } = props

  const [isActive, setIsActive] = React.useState(false)
  const [editName, setEditName] = React.useState('')

  React.useEffect(() => {
    clearProject()
    loadProject({ id })
  }, [])

  React.useEffect(() => {
    if (get(props, 'project.data.attributes.name')) {
      document.title = `${get(props, 'project.data.attributes.name')} - Posmoni`
    } else {
      document.title = `Project - Posmoni`
    }

    setEditName(get(props, 'project.data.attributes.name', ''))
  }, [get(props, 'project.data.attributes.name')])

  const { t } = useTranslation()

  const renderBreadcrumb = () => {
    const path = window.location.pathname

    if (path.includes('summary')) return t('Header.summary')
    if (path.includes('setting')) return t('Header.setting')
    if (path.includes('api')) return t('Header.apiExplorer')
    if (path.includes('ng-words')) return t('Header.ngWords')
  }

  const onChange = ({ target: { value } }) => setEditName(value)

  const onSubmit = (e) => {
    e.preventDefault()

    if (id && editName) {
      setIsActive(false)
      updateProject({ id, name: editName }).then((res) => {
        if (get(res, 'type', '').endsWith('_SUCCESS')) {
          loadProject({ id })
          addNotification({
            message: t('Projects.projectUpdateNoti', {
              name: get(res, 'payload.data.data.name'),
            }),
            type: Notification.NOTIFICATION_TYPES.success,
          })
        }
      })
    }
  }

  return (
    <React.Fragment>
      <CentralHeader
        header={
          <React.Fragment>
            <span className="Central__header__h2__span">
              {get(props, 'project.data.attributes.name')}
            </span>
            <button
              css={{
                border: 'none',
                width: '20px',
                height: '20px',
                background: `no-repeat center/contain url(${EditIcon})`,
                cursor: 'pointer',
                outline: 'none',
                marginLeft: '10px',
              }}
              onClick={() => setIsActive(true)}
            />
            {/* Hide it for now (PM order) */}
            {false && (
              <a
                className="Central__header__h2__a Central__header__h2__a--chrome"
                onClick={this.onToggleModal('downloadReport')}
              >
                {t('Project.downloadReport')}
              </a>
            )}
          </React.Fragment>
        }
        breadcrumbs={[
          { label: t('Main.projects'), to: '/app/projects' },
          { label: get(props, 'project.data.attributes.name', '') },
          { label: renderBreadcrumb(), last: true },
        ]}
      />
      <ProjectMenu
        id={id}
        template={get(props, 'project.data.attributes.template', '')}
      />
      <Switch>
        {props.routes &&
          props.routes.map(({ path, exact, component: Component }) => (
            <Route key={path} exact={exact} path={path}>
              {(props) => (
                <CSSTransition
                  in={props.match !== null}
                  timeout={600}
                  className="Page"
                  classNames="Page"
                  appear
                  unmountOnExit
                >
                  <div>
                    <Component {...props} />
                  </div>
                </CSSTransition>
              )}
            </Route>
          ))}
      </Switch>
      <Modal isActive={isActive} toggleFunction={() => setIsActive(false)}>
        <h3 className="Modal__h3">{t('Projects.editProject')}</h3>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            value={editName}
            maxLength="25"
            className="Input"
            onChange={onChange}
            required
          />
          <div className="Modal__button Modal__button--evenly">
            <button
              className="Button Button--gray"
              onClick={() => setIsActive(false)}
              type="button"
            >
              {t('Misc.close')}
            </button>
            <button className="Button Button--green" type="submit">
              &nbsp;&nbsp;&nbsp;{t('Misc.save')}&nbsp;&nbsp;&nbsp;
            </button>
          </div>
        </form>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  project: getProject(state),
})

export default connect(mapStateToProps, {
  clearProject,
  loadProject,
  updateProject,
  addNotification,
})(ProjectLayout)
