import React from 'react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import { FormatDateWithTime } from '@helpers'

import { ProjectSubHeader } from '@shared/style/Project.style'

const TaskLog = (props) => {
  const { taskLog } = props
  const { t } = useTranslation()

  const headers = [t('ModerationLog.timeStamp'), t('ModerationLog.actionLog')]

  return (
    <div css={{ marginBottom: '16px', minWidth: '600px' }}>
      <ProjectSubHeader css={{ marginBottom: '16px' }}>
        {t('ModerationLog.moderationLog')}{' '}
        <span
          css={{
            textTransform: 'none',
            fontSize: '12px',
            color: '#b0c1d2',
            letterSpacing: 'normal',
          }}
        >
          ({get(props, 'moderationId', '')})
        </span>
      </ProjectSubHeader>
      <div className="Table">
        <div className="Table__tr">
          {headers.map((header) => (
            <div className="Table__td Table__td--th" key={header}>
              {header}
            </div>
          ))}
        </div>
        {get(taskLog, 'data', []).map((log) => (
          <div className="Table__tr" key={get(log, 'id')}>
            <div className="Table__td">
              {FormatDateWithTime(get(log, 'attributes.updated_at'))}
            </div>
            <div className="Table__td">
              {get(log, 'attributes.description')}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TaskLog
