import React from 'react'
import { get, pickBy, flowRight as compose } from 'lodash'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  getActionLogs,
  getDatePicker,
  loadActionLogs,
  clearActionLogs,
} from '@redux-action'
import { FormatDateWithTime } from '@helpers'

import withHeader from '@features/withHeader'

import CentralHeader from '@layouts/CentralHeader'
import {
  ProjectSection,
  ProjectContent,
  ProjectHeader,
} from '@shared/style/Project.style'
import SectionLoader from '@shared/SectionLoader'
import Select from '@shared/forms/Select'
import Pagination from '@shared/Pagination'
import DateSelectorRange from '@shared/forms/DateSelectorRange'

const ActionLog = (props) => {
  const { actionLogs, loadActionLogs, clearActionLogs, datePicker } = props
  const { isLoading, isLoaded, isError, data, meta } = actionLogs

  const { t } = useTranslation()

  const headers = ['time', 'user', 'action', 'description']
  const options = [
    { label: t('ActionLog.allAction'), value: '' },
    { label: t('ActionLog.createProject'), value: 'create_project' },
    { label: t('ActionLog.updateProject'), value: 'update_project' },
    { label: t('ActionLog.deleteProject'), value: 'delete_project' },
    { label: t('ActionLog.createCard'), value: 'create_card' },
    { label: t('ActionLog.setDefaultCard'), value: 'set_default_card' },
    { label: t('ActionLog.deleteCard'), value: 'delete_card' },
    { label: t('ActionLog.buyCredit'), value: 'buy_credit' },
    { label: t('ActionLog.inviteUser'), value: 'invite_user' },
    { label: t('ActionLog.resendInvitation'), value: 'resend_invitation' },
    { label: t('ActionLog.changeRole'), value: 'change_role' },
    { label: t('ActionLog.removeUser'), value: 'remove_user' },
    { label: t('ActionLog.changeOwner'), value: 'change_owner' },
    { label: t('ActionLog.signIn'), value: 'sign_in' },
    { label: t('ActionLog.signUp'), value: 'sign_up' },
    {
      label: t('ActionLog.signUpViaInvitation'),
      value: 'sign_up_via_invitation',
    },
  ]

  React.useEffect(() => {
    loadActionLogs({
      ..._getMeta(),
      start_time: datePicker.startDate,
      end_time: datePicker.endDate,
    })
  }, [datePicker.startDate, datePicker.endDate])

  const _getMeta = () => {
    let page = get(actionLogs, 'meta.page')

    if (!page || page < 0) {
      page = null
    }

    return pickBy({
      page,
      per_page: get(actionLogs, 'meta.per_page'),
      action: get(actionLogs, 'meta.action'),
    })
  }

  const onFilter = ({ target: { value } }) => {
    clearActionLogs()
    loadActionLogs({ ..._getMeta(), action: value })
  }

  const onChangePage = (page = meta.per_page) => {
    clearActionLogs()
    loadActionLogs({ ..._getMeta(), page })
  }

  const onChangePerPage = ({ target: { value } }) => {
    clearActionLogs()
    loadActionLogs({ ..._getMeta(), per_page: value })
  }

  return (
    <React.Fragment>
      <CentralHeader
        header={t('ActionLog.actionLog')}
        breadcrumbs={[{ label: t('ActionLog.actionLog') }]}
      />
      <div className="Central__sub-header"></div>
      <ProjectContent>
        <div className="ActionLog">
          <ProjectHeader>
            <h3>{t('ActionLog.actionLog')}</h3>
          </ProjectHeader>
          <div>
            <ProjectSection>
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns:
                    'repeat(auto-fit, minmax(1px, max-content))',
                  gap: 'var(--global-space)',
                  alignItems: 'center',
                  marginBottom: 'var(--global-space)',
                  justifyContent: 'space-between',
                }}
              >
                <Select
                  value={get(actionLogs, 'meta.role', '')}
                  isLoading={isLoading}
                  onChange={onFilter}
                  options={options}
                  label={t('ActionLog.action')}
                />
                <DateSelectorRange right withIcon isLoading={isLoading} />
              </div>
              <SectionLoader
                isLoading={isLoading}
                isLoaded={isLoaded}
                isError={isError}
                isEmpty={data.length === 0}
                alwaysDisplayChildren
              >
                <div className="Table">
                  <div className="Table__tr">
                    {headers.map((header, i) => (
                      <div key={i} className="Table__td Table__td--th">
                        {header}
                      </div>
                    ))}
                  </div>
                  {data.map((log, i) => {
                    const date = get(log, 'attributes.created_at')
                    const email = get(log, 'attributes.email')
                    const action = get(log, 'attributes.action')
                    const description = get(log, 'attributes.description')

                    return (
                      <div className="Table__tr" key={i}>
                        <div className="Table__td">
                          {FormatDateWithTime(date)}
                        </div>
                        <div className="Table__td">{email}</div>
                        <div className="Table__td">{action}</div>
                        <div className="Table__td">{description}</div>
                      </div>
                    )
                  })}
                </div>
              </SectionLoader>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 'var(--global-space)',
                }}
              >
                <Select
                  value={get(meta, 'per_page', '')}
                  onChange={onChangePerPage}
                  label={t('Misc.display')}
                  name="perPage"
                  id="perPage"
                  options={[
                    { label: '10', value: 10 },
                    { label: '15', value: 15 },
                    { label: '25', value: 25 },
                    { label: '50', value: 50 },
                  ]}
                />
                <Pagination
                  total={meta.total}
                  currentPage={meta.page}
                  perPage={meta.per_page}
                  onChangePage={onChangePage}
                />
              </div>
            </ProjectSection>
          </div>
        </div>
      </ProjectContent>
    </React.Fragment>
  )
}

const mapState = (state) => ({
  actionLogs: getActionLogs(state),
  datePicker: getDatePicker(state),
})

export default compose(
  withHeader(),
  connect(
    mapState,
    { loadActionLogs, clearActionLogs }
  )
)(ActionLog)
