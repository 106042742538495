import React from 'react'
import { connect } from 'react-redux'
import hljs from 'highlight.js/lib/highlight'
import json from 'highlight.js/lib/languages/json'

import {
  getRequestInspection,
  loadRequestInspection,
  clearRequestInspection,
  getTaskLog,
  loadTaskLog,
  clearTaskLog,
} from '@redux-action'

import Modal from '@shared/Modal'
import TaskLog from '../TaskLog'
import RequestInspection from '../RequestInspection'

const ModerationLog = ({
  isActive,
  toggleFunction,
  moderationId,
  requestInspection,
  loadRequestInspection,
  clearRequestInspection,
  taskLog,
  loadTaskLog,
  clearTaskLog,
}) => {
  React.useEffect(() => {
    hljs.registerLanguage('json', json)
  }, [])

  React.useEffect(() => {
    if (moderationId) {
      clearRequestInspection()
      clearTaskLog()
      loadTaskLog({ moderation_id: moderationId })
      loadRequestInspection({ moderation_id: moderationId })
    }
  }, [moderationId])

  React.useEffect(() => {
    document.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightBlock(block)
    })
  }, [requestInspection])

  return (
    <Modal isActive={isActive} toggleFunction={toggleFunction}>
      <TaskLog taskLog={taskLog} moderationId={moderationId} />
      <RequestInspection requestInspection={requestInspection} />
    </Modal>
  )
}

const mapState = (state) => ({
  requestInspection: getRequestInspection(state),
  taskLog: getTaskLog(state),
})

export default connect(
  mapState,
  { loadRequestInspection, clearRequestInspection, loadTaskLog, clearTaskLog }
)(ModerationLog)
