import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import moment from 'moment'
import { min, max } from 'd3-array'
import { scaleTime, scaleLinear } from 'd3-scale'
import { timeDays } from 'd3-time'
import { format } from 'd3-format'
import { get, first, last } from 'lodash'
import { randomBates } from 'd3-random'
import { connect } from 'react-redux'

import { getDatePicker } from '@redux-action'

export const _margin = {
  top: 20, // Consider circle radius
  right: 20, // Consider x-labels width, and circle radius.
  bottom: 35, // Consider the space between circle and x-labels.
  left: 50, // Consider y-labels width.
}

class GraphLine extends React.Component {
  static propTypes = {}

  static defaultProps = {
    noAxes: false,
    autoColor: false,
    enableArea: true,
    keys: ['all_interactive_count', 'like_count', 'comment_count'].map(
      (x, i) => ({
        value: x,
        color: i,
      })
    ),
    dataPack: Array.from(Array(30)).map((_, i) => {
      return {
        key: moment()
          .subtract(i, 'd')
          .format('YYYY-MM-DD'),
        data: {
          count: ~~(randomBates(500)() * 10000),
        },
      }
    }),
  }

  _boundOnResize = this.onResize.bind(this) // https://gist.github.com/Restuta/e400a555ba24daa396cc
  _isResizingTO = null

  _WIDTH_THRESHOLD = -1 // We don't need responsive just yet, so disable this for now.
  _height = 350
  _radius = 18

  state = {
    width: -1,
    height: -1,
    margin: _margin,
  }

  componentDidMount() {
    setTimeout(this.onResize.bind(this), 50)
    window.addEventListener('resize', this._boundOnResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._boundOnResize)
  }

  onResize() {
    clearTimeout(this._isResizingTO)
    this._isResizingTO = setTimeout(() => {
      if (!this.$el) return

      const margin = this.props.noAxes
        ? { top: 0, right: 0, bottom: 0, left: 0 }
        : Object.assign({}, _margin)
      const clientWidth = this.$el.clientWidth

      if (clientWidth < this._WIDTH_THRESHOLD) {
        margin.right = 20
        margin.bottom = 60
      }

      this.setState({
        width: clientWidth,
        height: this.$el.clientHeight,
        margin,
      })
    }, 50)
  }

  render() {
    if (this.props.dataPack.length == 0 || this.state.width == -1)
      return (
        <div
          className="GraphBase__wrapper"
          ref={(el) => (this.$el = el)}
          style={{
            height: `${
              this.props.manualHeight ? this.props.manualHeight : this._height
            }px`,
          }}
        />
      )

    const startDay = moment(get(this.props, 'datePicker.startDate'))
    const endDay = moment(get(this.props, 'datePicker.endDate'))
    const dateRange = timeDays(startDay, moment(endDay).add(1, 'day'))

    const width =
      this.state.width - this.state.margin.left - this.state.margin.right
    const height =
      this.state.height - this.state.margin.top - this.state.margin.bottom
    const keys = Object.keys(first(this.props.dataPack).data)

    const actualMaxY = max(
      keys.map((oKey) => {
        return max(this.props.dataPack, (d) => d.data[oKey])
      })
    )

    const actualMinY = min(
      keys.map((oKey) => {
        return min(this.props.dataPack, (d) => d.data[oKey])
      })
    )

    let lowerBound = actualMinY
    let upperBound = actualMaxY

    if (lowerBound > 0) lowerBound = Math.floor(lowerBound * 0.99)

    if (Math.abs(lowerBound - upperBound) < 10) {
      if (lowerBound > 0) {
        upperBound = lowerBound + 9
      } else {
        upperBound = 9
      }
    }

    const x = scaleTime()
      .domain([startDay, endDay])
      .rangeRound([0, width])
    const y = scaleLinear()
      .domain([lowerBound, upperBound])
      .rangeRound([height, 0])
      .clamp(true)
    const yBound = scaleLinear()
      .domain([0, 9])
      .range([lowerBound, upperBound])

    const days = moment.duration(+endDay - +startDay).as('days')
    const maxNumLabels = Math.floor(
      width / (this.state.width < this._WIDTH_THRESHOLD ? 15 : 40)
    )
    const daysMod = Math.ceil(days / maxNumLabels)

    const paths = keys.map((oKey) => {
      return {
        key: oKey,
        color: get(this.props.keys.find((_x) => _x.value === oKey), 'color'),
        path: dateRange.map((day, i) => {
          const key = moment(day).format('YYYY-MM-DD')
          const d = this.props.dataPack.find((_x) => _x.key === key)
          const value = d ? d.data[oKey] : 0
          return `${i == 0 ? '' : 'L'}${x(moment(key))},${y(value)}`
        }),
      }
    })

    return (
      <div className="GraphBase__wrapper">
        {this.props.graphToggler && (
          <div className="GraphBase__header">
            <p className="GraphBase__axis-y-label">{this.props.axisYLabel}</p>
            {this.props.graphToggler}
          </div>
        )}

        <div
          className={className('GraphLine GraphBase', {})}
          ref={(el) => (this.$el = el)}
        >
          <svg
            height={
              this.props.manualHeight ? this.props.manualHeight : this._height
            }
          >
            <defs>
              <linearGradient
                id="gradient0"
                x1="0"
                x2="0"
                y1="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor="#46D7CE"
                />
                <stop
                  offset="100%"
                  stopColor="#46D7CE"
                  stopOpacity="0"
                />
              </linearGradient>

              <linearGradient
                id="gradient1"
                x1="0"
                x2="0"
                y1="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor="#87A0FA"
                />
                <stop
                  offset="100%"
                  stopColor="#87A0FA"
                  stopOpacity="0"
                />
              </linearGradient>
            </defs>

            {!this.props.noAxes && (
              <g transform={`translate(0, ${this.state.margin.top})`}>
                {Array.from(Array(10)).map((_, i) => {
                  if (keys.length === 0) return null

                  const yBounded = yBound(i)

                  return (
                    <g key={yBounded}>
                      <line
                        className={className('GraphBase__axis-y', {
                          'GraphBase__axis-y--solid': i == 0,
                        })}
                        x1={this.state.margin.left}
                        y1={y(yBounded)}
                        x2={width + this.state.margin.left}
                        y2={y(yBounded)}
                      />
                      <text
                        className="GraphBase__label-y"
                        x={0}
                        y={y(yBounded)}
                      >
                        {yBounded > 99999
                          ? format('.4s')(yBounded)
                          : (~~yBounded).toLocaleString()}
                      </text>
                    </g>
                  )
                })}
              </g>
            )}
            <g
              transform={`translate(${this.state.margin.left}, ${this.state.margin.top})`}
              className="GraphBase__main-g"
            >
              <g>
                {paths.map((p, i) => {
                  return (
                    <g
                      key={p.key}
                      className="GraphLine__path-wrapper"
                    >
                      <path
                        d={`M${p.path.join(' ')}`}
                        className={className('GraphLine__path', {
                          [`GraphLine__path--${p.color}`]: p.color,
                        })}
                      />
                      {this.props.enableArea && (
                        <path
                          fill={`url(#gradient${p.color || 0})`}
                          d={`M0,${height} L${p.path.join(' ')} L${x(
                            last(dateRange)
                          )},${height} Z`}
                          className={className('GraphLine__area', {
                            [`GraphLine__area--${p.key}`]: p.key,
                          })}
                        />
                      )}
                    </g>
                  )
                })}
              </g>
              {!this.props.noAxes && (
                <g>
                  {dateRange.map((d, i) => {
                    const date = moment(d)
                    const dx = x(date)

                    return (
                      <g
                        className="GraphLine__data-container"
                        key={i}
                      >
                        <line
                          className={className('GraphLine__axis-x', {
                            'GraphLine__axis-x--solid': i == 0,
                            'GraphLine__axis-x--show':
                              moment.duration(+endDay - +date).as('days') %
                                daysMod ==
                                0 || daysMod == 0,
                          })}
                          x1={dx}
                          y1={0}
                          x2={dx}
                          y2={height}
                        />
                        {(() => {
                          const xLabelX = dx - 28
                          const xLabelY = y(0) + this.state.margin.bottom * 0.85

                          return (
                            <g transform={`translate(0, 0)`}>
                              <text
                                className={className('GraphLine__label-x', {
                                  'GraphLine__label-x--show':
                                    moment
                                      .duration(+endDay - +date)
                                      .as('days') %
                                      daysMod ==
                                      0 || daysMod == 0,
                                })}
                                x={xLabelX}
                                y={xLabelY}
                                transform={`rotate(${
                                  this.state.width < this._WIDTH_THRESHOLD
                                    ? 90
                                    : -30
                                }, ${xLabelX}, ${xLabelY})`}
                              >
                                {date.format('MMM DD')}
                              </text>
                            </g>
                          )
                        })()}
                        {/* This is to sort `like_count` and `comment_count` after generic `count`. */}
                        {[{ value: 'count', color: 0 }, ...this.props.keys].map(
                          (oKey, j) => {
                            const value = get(
                              this.props.dataPack.find(
                                (_x) => _x.key == date.format('YYYY-MM-DD')
                              ),
                              'data',
                              {}
                            )[oKey.value]
                            if (value == null) return null

                            return (
                              <g
                                key={j}
                                className={className('GraphLine__data', {})}
                              >
                                <line
                                  className={className('GraphLine__trunk', {
                                    [`GraphLine__trunk--${oKey.color}`]: true,
                                    [`GraphLine__trunk--hidden`]: !this.props
                                      .enableArea,
                                  })}
                                  x1={dx}
                                  y1={y(value)}
                                  x2={dx}
                                  y2={height}
                                />
                                <circle
                                  cx={dx}
                                  cy={y(value)}
                                  data-value={value}
                                  r={this._radius}
                                  className={className('GraphLine__circle', {
                                    [`GraphLine__circle--${oKey.color}`]: true,
                                  })}
                                />
                                <text
                                  className="GraphLine__label"
                                  x={dx}
                                  y={y(value)}
                                >
                                  {value > 99999
                                    ? format('.4s')(value)
                                    : value.toLocaleString()}
                                </text>
                              </g>
                            )
                          }
                        )}
                      </g>
                    )
                  })}
                </g>
              )}
            </g>
          </svg>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  datePicker: getDatePicker(state),
})

export default connect(mapStateToProps)(GraphLine)
