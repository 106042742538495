import React from 'react'
import { Link, Prompt } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import ProjectNewStep from './ProjectNewStep'

const ProjectNewHeader = ({ currentStep = 0, totalStep = 2, isLoading }) => {
  const { t } = useTranslation()

  return (
    <div
      css={{
        height: '180px',
        backgroundColor: '#fff',
      }}
    >
      <div
        css={{
          width: '780px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          padding: '40px 0 20px',
          textAlign: 'center',
        }}
      >
        <div css={{ marginBottom: '10px', position: 'relative',}}>
          <Prompt
            message={t('ProjectNew.leaveConfirm')}
            when={isLoading}
          />
          <Link
            css={{
              position: 'absolute',
              fontFamily: 'Chivo',
              fontSize: '12px',
              color: '#000000',
              top: '50%',
              left: '0',
            }}
            to="/app/projects"
          >
            {t('ProjectNew.backToProjects')}
          </Link>
          <h2
            css={{
              fontFamily: 'Montserrat',
              fontSize: '32px',
              letterSpacing: '0.48px',

            }}
          >
            {t('ProjectNew.createNewProject')}
          </h2>
        </div>
        <div>
          <Trans i18nKey="ProjectNew.steps">
            Step {{ current: currentStep + 1 }} of {{ totalStep }}
          </Trans>
          <ProjectNewStep currentStep={currentStep} totalStep={totalStep} />
        </div>
      </div>
    </div>
  )
}

export default ProjectNewHeader
