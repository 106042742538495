import styled from '@emotion/styled'

export const ModalH3 = styled.h3`
  font-family: Montserrat;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: calc(var(--global-space) * 0.8);
  line-height: 1.5;
`

export const ModalMessage = styled.div`
  max-width: 350px;
  text-align: center;
  line-height: 1.618;
`

export const ModalButtons = styled.div`
  margin-top: calc(var(--global-space) * 1.2);
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--global-space);
`
