import styled from '@emotion/styled'

export const ProjectContent = styled.div`
  height: 100%;
  background-color: #f7f8f9;
  padding: var(--global-space);

  > *:not(:last-child):not(:empty) {
    margin-bottom: var(--global-space);
  }
`

export const ProjectHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: var(--global-space);
  align-items: end;
  margin-bottom: var(--global-space);

  h3 {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }
`

export const ProjectSubHeader = styled.div`
  font-family: Montserrat;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  margin-bottom: calc(var(--global-space) * 1.2);
  font-weight: bold;
`

export const ProjectSection = styled.div`
  background-color: #fff;
  border: 1px solid var(--c-border);
  padding: calc(var(--global-space) * 1.6) calc(var(--global-space) * 1.2);
  border-radius: 4px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  > *:first-of-type {
    margin-right: 16px;
  }
`
