import React from 'react'
import { get, omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'

import { hasMore } from '@helpers'

import ProjectListItem from './ProjectListItem'
import SectionLoader from '../SectionLoader'

class ProjectsListView extends React.Component {
  render() {
    const {
      t,
      onDeleteProject,
      onUpdateProject,
      onConfirmDelete,
      onLoadMore,
    } = this.props
    const headers = [
      t('Header.project'),
      t('Misc.template'),
      t('Header.remainingTasks'),
      t('Header.taskProcessed'),
      '',
    ]

    return (
      <div className="Central__content">
        <div className="Central__content__section">
          <SectionLoader
            isLoading={get(this.props, 'projects.isLoading')}
            isLoaded={get(this.props, 'projects.isLoaded')}
            isError={get(this.props, 'projects.isError')}
            isEmpty={
              Object.keys(get(this.props, 'projects.data', [])).length === 0
            }
            alwaysDisplayChildren
          >
            <div className="Table">
              <div className="Table__tr">
                {headers.map((header, i) => (
                  <div
                    key={i}
                    className="Table__td Table__td--th"
                  >
                    {header}
                  </div>
                ))}
              </div>

              {get(this.props, 'projects.data', []).map((data, i) => (
                <ProjectListItem
                  key={i}
                  data={data}
                  onDeleteProject={
                    get(data, 'credit', 0) > 0
                      ? onConfirmDelete
                      : onDeleteProject
                  }
                  onUpdateProject={onUpdateProject}
                />
              ))}
            </div>
            {hasMore(get(this.props, 'projects')) && (
              <a
                className="LoadMore"
                onClick={onLoadMore}
              >
                {t('Misc.loadmore')}
              </a>
            )}
          </SectionLoader>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ProjectsListView)
