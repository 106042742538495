import React from 'react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import { hasMore } from '@helpers'

import SectionLoader from '@shared/SectionLoader'
import { FormatDateWithTime } from '../../../lib/helpers'

const DashboardTasks = ({ dashboardTasks, onLoadMore }) => {
  const { t } = useTranslation()

  const headers = [
    t('Header.project'),
    t('Header.date'),
    t('Header.taskId'),
    t('Header.status'),
    t('Header.type'),
  ]

  return (
    <div className="Central__content__section">
      <SectionLoader
        isLoading={dashboardTasks.isLoading}
        isLoaded={dashboardTasks.isLoaded}
        isError={dashboardTasks.isError}
        isEmpty={get(dashboardTasks, 'data', []).length === 0}
        alwaysDisplayChildren
      >
        <div className="Table">
          <div className="Table__tr">
            {headers.map((header, i) => (
              <div key={i} className="Table__td Table__td--th">
                {header}
              </div>
            ))}
          </div>
          {get(dashboardTasks, 'data', []).map((task, i) => (
            <div className="Table__tr" key={i} data-x={task}>
              <div className="Table__td">
                {get(task, 'attributes.project_name')}
              </div>
              <div className="Table__td">
                {FormatDateWithTime(get(task, 'attributes.processed_at'))}
              </div>
              <div className="Table__td">{get(task, 'id')}</div>
              <div className="Table__td">{get(task, 'attributes.status')}</div>
              <div className="Table__td">{get(task, 'type')}</div>
            </div>
          ))}
        </div>

        {hasMore(dashboardTasks) && (
          <a className="LoadMore" onClick={onLoadMore}>
            {t('Misc.loadmore')}
          </a>
        )}
      </SectionLoader>
    </div>
  )
}

export default DashboardTasks
