import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import { get } from 'lodash'
import hljs from 'highlight.js/lib/highlight'
import json from 'highlight.js/lib/languages/json'
import 'highlight.js/styles/atom-one-dark.css'

class SwitchGroups extends React.Component {
  state = {
    currentTab: this.props.items[0].value,
  }

  $el = React.createRef()

  componentDidMount = () => {
    hljs.registerLanguage('json', json)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.items.length !== this.props.items.length) {
      this.setState({ currentTab: this.props.items[0].value })
    }
  }

  highlight = () => {
    this.$el.current.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightBlock(block)
    })
  }

  onTabClick = ({
    target: {
      dataset: { value },
    },
  }) => {
    if (this.props.onTabChange) this.props.onTabChange(value)
    this.setState({ currentTab: value }, () => {
      if (this.props.highlight) this.highlight()
    })
  }

  getMenu = () =>
    this.props.items.map((item, i) => (
      <li
        key={i}
        className={className({ 'SwitchGroup--full-width': this.props.full })}
      >
        <label
          data-value={item.value}
          onClick={this.onTabClick}
          className={className('SwitchGroup__label', {
            'SwitchGroup__label--active': item.value === this.state.currentTab,
          })}
        >
          {item.label}
        </label>
      </li>
    ))

  render() {
    const { children = [], withContent } = this.props
    const tabs = children // Transform array to obj
      .filter((x) => get(x, 'props.data-name') === 'tab')
      .reduce((sum, cur) => ({ ...sum, [cur.props['data-label']]: cur }), {})
    const elms = children.filter((x) => get(x, 'props.data-name') !== 'tab')

    return (
      <div className="SwitchGroup" ref={this.$el}>
        <ul
          className={className('SwitchGroup__ul', {
            'SwitchGroup--right': this.props.right,
            'SwitchGroup--center': this.props.center,
          })}
        >
          {this.getMenu()}
        </ul>
        {withContent && elms}
        {withContent && tabs && tabs[this.state.currentTab]}
      </div>
    )
  }
}

SwitchGroups.defaultProps = {
  items: [
    { value: 'simple', label: 'Simple' },
    { value: 'menu', label: 'Menu' },
  ],
  withContent: false,
  highlight: false,
}

SwitchGroups.propTypes = {
  items: PropTypes.array.isRequired,
  withContent: PropTypes.bool,
  highlight: PropTypes.bool,
  onTabChange: PropTypes.func,
  right: PropTypes.bool,
  center: PropTypes.bool,
  full: PropTypes.bool,
}

export default SwitchGroups
