import React from 'react'
import PropTypes from 'prop-types'
import { get, capitalize } from 'lodash'
import className from 'classnames'
import { withTranslation } from 'react-i18next'

class StatusColor extends React.Component {
  mapLanguage = (status) => {
    const { t } = this.props

    if (status === 'processed') return t('selectOptions.processed')
    if (status === 'unprocessed') return t('selectOptions.unprocessed')
    if (status === 'processing') return t('selectOptions.processing')
    if (status === 'error') return t('Misc.error')
  }

  render = () => {
    const status = get(this.props, 'status')

    return (
      <span
        className={className('StatusColor', {
          'StatusColor--yellow': ['unprocessed', 'processing'].includes(status),
          'StatusColor--green': ['processed'].includes(status),
          'StatusColor--red': ['error'].includes(status),
        })}
      >
        {capitalize(this.mapLanguage(status))}
      </span>
    )
  }
}

export default withTranslation()(StatusColor)
