import React, { useState, forwardRef } from 'react'

import { Input } from '@shared/style'
import visibilityIcon from '@images/icon-visibility.svg'

const PasswordInput = forwardRef((props, ref) => {
  const { type = 'text', containerCss, ...rest } = props

  const [show, setShow] = useState(false)

  return (
    <div css={{ position: 'relative' }} className={containerCss}>
      <Input {...rest} type={show ? type : 'password'} ref={ref} />
      <img
        css={{
          cursor: 'pointer',
          position: 'absolute',
          right: '16px',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '20px',
          height: '20px',
        }}
        onClick={() => setShow((state) => !state)}
        src={visibilityIcon}
      />
    </div>
  )
})

export default PasswordInput
