import React from 'react'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { Lower } from './Footer.style'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <div css={{ fontSize: '12px' }}>
      <Lower>
        &copy; {moment().format('YYYY')} Posmoni
        <div css={{ marginLeft: 'auto' }}>
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            href="https://posmoni.com/terms"
          >
            {t('Footer.termsOfUse')}
          </a>
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            href="https://posmoni.com/privacy"
          >
            {t('Main.privacyPolicy')}
          </a>
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            href="https://posmoni.com/scta"
          >
            {t('Footer.longOne')}
          </a>
        </div>
      </Lower>
    </div>
  )
}

export default Footer
