export const getLoginAuth = (state) => state.loginAuth

export const createLoginAuth = (data) => {
  return {
    type: 'LOGIN_AUTH_CREATE',
    payload: {
      client: 'defaultV1',
      request: {
        url: `/v1/users/auth/sign_in`,
        method: 'POST',
        data,
      },
    },
  }
}

export const createLoginVerifyFactor = (data) => {
  return {
    type: 'LOGIN_VERIFY_FACTOR_CREATE',
    payload: {
      client: 'defaultV3',
      request: {
        url: `/v3/login/verify_factor`,
        method: 'POST',
        data,
      },
    },
  }
}

export const createLoginSignup = (data) => {
  return {
    type: 'LOGIN_SIGNUP_CREATE',
    payload: {
      client: 'defaultV1',
      request: {
        url: `/v1/users/auth/sign_up`,
        method: 'POST',
        data,
      },
    },
  }
}

export const getLoginPasswordResetRequest = (state) =>
  state.loginPasswordResetRequest

export const createLoginPasswordResetRequest = (data) => {
  return {
    type: 'LOGIN_PASSWORD_RESET_REQUEST_CREATE',
    payload: {
      client: 'defaultV3',
      request: {
        url: `/v3/login/password_reset_request`,
        method: 'POST',
        data,
      },
    },
  }
}

export const getLoginPasswordResetSent = (state) => state.loginPasswordResetSent

export const loadLoginPasswordResetSent = (params = {}) => {
  return {
    type: 'LOGIN_PASSWORD_RESET_SENT_LOAD',
    payload: {
      client: 'defaultV3',
      request: {
        url: `/v3/login/password_reset_sent`,
        params,
      },
    },
  }
}

export const getLoginPasswordResetVerify = (state) =>
  state.loginPasswordResetVerify

export const loadLoginPasswordResetVerify = (params = {}) => {
  return {
    type: 'LOGIN_PASSWORD_RESET_VERIFY_LOAD',
    payload: {
      client: 'defaultV3',
      request: {
        url: `/v3/login/password_reset_verify`,
        params,
      },
    },
  }
}

export const getLoginPasswordNew = (state) => state.loginPasswordNew

export const createLoginPasswordNew = (data) => {
  return {
    type: 'LOGIN_PASSWORD_NEW_CREATE',
    payload: {
      client: 'defaultV3',
      request: {
        url: `/v3/login/password_new`,
        method: 'PUT',
        data,
      },
    },
  }
}

export const getDashboardChart = (state) => state.dashboardChart

export const loadDashboardChart = (params = {}) => ({
  type: 'DASHBOARD_CHART_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: '/v1/dashboards/graph',
      params,
    },
  },
})

export const getDashboardTasks = (state) => state.dashboardTasks

export const loadDashboardTasks = (params = {}) => ({
  type: 'DASHBOARD_TASKS_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: '/v1/dashboards/tasks',
      params,
    },
  },
})

export const clearDashboardTasks = (params = {}) => ({
  type: 'DASHBOARD_TASKS_CLEAR',
})

export const getProjects = (state) => state.projects

export const loadProjects = (params = {}) => ({
  type: 'PROJECTS_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: '/v1/projects',
      params,
    },
  },
})

export const clearProjects = (params = {}) => ({
  type: 'PROJECTS_CLEAR',
})

export const getProjectTemplates = (state) => state.projectTemplates

export const loadProjectTemplates = (params = {}) => ({
  type: 'PROJECT_TEMPLATES_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/projects/templates`,
      params,
    },
  },
})

export const getProject = (state) => state.project

export const loadProject = (params = {}) => ({
  type: 'PROJECT_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/projects/${params.id}`,
    },
  },
})

export const resetProjectKey = (data) => ({
  type: 'PROJECT_KEY_RESET',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/projects/${data.id}/renew_key`,
      method: 'PUT',
      data,
    },
  },
})

export const getProjectNew = (state) => state.projectNew

export const createProject = (data) => {
  return {
    type: 'PROJECT_CREATE',
    payload: {
      client: 'defaultV1',
      request: {
        url: `/v1/projects`,
        method: 'POST',
        data,
      },
    },
  }
}

export const updateProject = (data) => {
  return {
    type: 'PROJECT_UPDATE',
    payload: {
      client: 'defaultV1',
      request: {
        url: `/v1/projects/${data.id}`,
        method: 'PUT',
        data,
      },
    },
  }
}

export const updateProjectAwsCredential = (data) => {
  return {
    type: 'PROJECT_AWS_UPDATE',
    payload: {
      client: 'defaultV1',
      request: {
        url: `/v1/projects/${data.id}/aws_credentials`,
        method: 'PUT',
        data,
      },
    },
  }
}

export const updateProjectAutoRefillCredit = (data) => {
  return {
    type: 'PROJECT_AUTO_REFILL_CREDIT_UPDATE',
    payload: {
      client: 'defaultV4',
      request: {
        url: `/v4/projects/${data.id}/budgets`,
        method: 'PUT',
        data,
      },
    },
  }
}

export const deleteProject = (data) => {
  return {
    type: 'PROJECT_DELETE',
    payload: {
      client: 'defaultV1',
      request: {
        url: `/v1/projects/${data.id}`,
        method: 'DELETE',
        data,
      },
    },
  }
}

export const clearProject = (params = {}) => ({
  type: 'PROJECT_CLEAR',
})

export const getProjectChart = (state) => state.projectChart

export const loadProjectChart = (params = {}) => ({
  type: 'PROJECT_CHART_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/projects/${params.id}/graph`,
      params,
    },
  },
})

export const clearProjectChart = (params = {}) => ({
  type: 'PROJECT_CHART_CLEAR',
})

export const getUser = (state) => state.user

export const loadUser = (params = {}) => ({
  type: 'USER_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: '/v1/users/settings',
      params,
    },
  },
})

export const updateUser = (data) => ({
  type: 'USER_UPDATE',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/users/settings`,
      method: 'PUT',
      data,
    },
  },
})

export const updateUserLanguage = (data) => ({
  type: 'USER_LANG_UPDATE',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/users/languages`,
      method: 'PUT',
      data,
    },
  },
})

export const updateUserPassword = (data) => ({
  type: 'USER_UPDATE',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/users/change_password`,
      method: 'PUT',
      data,
    },
  },
})

export const getUserCards = (state) => state.userCards

export const loadUserCards = (params = {}) => ({
  type: 'USERS_CARDS_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: '/v1/cards',
      params,
    },
  },
})

export const createUserCards = (data) => {
  return {
    type: 'USERS_CARDS_CREATE',
    payload: {
      client: 'defaultV1',
      request: {
        url: `/v1/cards`,
        method: 'POST',
        data,
      },
    },
  }
}

export const updateUserCards = (data) => {
  return {
    type: 'USERS_CARDS_UPDATE',
    payload: {
      client: 'defaultV1',
      request: {
        url: `/v1/cards/${data.id}`,
        method: 'PUT',
        data,
      },
    },
  }
}

export const deleteUserCards = (data) => {
  return {
    type: 'USERS_CARDS_DELETE',
    payload: {
      client: 'defaultV1',
      request: {
        url: `/v1/cards/${data.id}`,
        method: 'DELETE',
        data,
      },
    },
  }
}

export const getUserDefaultCard = (state) => state.userDefaultCard

export const loadUserDefaultCard = (params = {}) => ({
  type: 'USERS_DEFAULT_CARDS_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: '/v1/cards/default',
      params,
    },
  },
})

export const clearUserCards = (params = {}) => ({
  type: 'USERS_CARDS_CLEAR',
})

export const getUsers2FA = (state) => state.users2FA

export const loadUsers2FA = (params = {}) => ({
  type: 'USERS_2FA_LOAD',
  payload: {
    client: 'defaultV3',
    request: {
      url: '/v3/users/two_factor_authentications',
      params,
    },
  },
})

export const createUsers2FA = (data) => {
  return {
    type: 'USERS_2FA_CREATE',
    payload: {
      client: 'defaultV3',
      request: {
        url: `/v3/users/two_factor_authentications`,
        method: 'POST',
        data,
      },
    },
  }
}

export const updateUsers2FA = (data) => {
  return {
    type: 'USERS_2FA_UPDATE',
    payload: {
      client: 'defaultV3',
      request: {
        url: `/v3/users/two_factor_authentications`,
        method: 'PUT',
        data,
      },
    },
  }
}

export const deleteUsers2FA = (data) => {
  return {
    type: 'USERS_2FA_DELETE',
    payload: {
      client: 'defaultV3',
      request: {
        url: `/v3/users/two_factor_authentications`,
        method: 'DELETE',
        data,
      },
    },
  }
}

export const deleteUsers2FAByAdmin = (data) => {
  return {
    type: 'USERS_2FA_ADMIN_DELETE',
    payload: {
      client: 'defaultV3',
      request: {
        url: `/v3/users/users_management/two_factor_authentications`,
        method: 'DELETE',
        data,
      },
    },
  }
}

export const clearPayments = (params = {}) => ({
  type: 'PAYMENTS_CLEAR',
})

export const getPaymentHistories = (state) => state.paymentHistories

export const loadPaymentHistories = (params = {}) => ({
  type: 'USER_PAYMENT_HISTORIES_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: '/v1/payments',
      params,
    },
  },
})

export const getPaymentsPurchaseCredit = (state) => state.paymentsPurchaseCredit

export const createPaymentsPurchaseCredit = (data) => {
  return {
    type: 'PAYMENTS_PURCHASE_CREDIT_CREATE',
    payload: {
      client: 'defaultV1',
      request: {
        url: `/v1/payments/credit`,
        method: 'POST',
        data,
      },
    },
  }
}

export const createValidatePurchase = ({ chargeId }) => {
  return {
    type: 'PAYMENTS_VALIDATE_CREATE',
    payload: {
      client: 'defaultV1',
      request: {
        url: '/v1/payments/validate',
        method: 'POST',
        data: { charge_id: chargeId },
      },
    },
  }
}

export const getNotifications = (state) => state.notifications

export const addNotification = (params) => ({
  type: 'NOTIFICATION_ADD',
  params,
})

export const removeNotification = (params) => ({
  type: 'NOTIFICATION_REMOVE',
  params,
})

export const getDatePicker = (state) => state.datePicker

export const updateDatePicker = (params) => ({
  type: 'DATEPICKER_UPDATE',
  params,
})

export const loadProjectReport = (params) => ({
  type: 'PROJECT_REPORT_LOAD',
  payload: {
    client: 'defaultV3',
    request: {
      url: `/v3/projects/reports`,
      params,
    },
  },
})

export const getOutputTasks = (state) => state.outputTasks

export const loadOutputTasks = (params = {}) => ({
  type: 'OUTPUT_TASK_LOAD',
  payload: {
    client: 'defaultV4',
    request: {
      url: `/v4/projects/${params.project_id}/outputs`,
      params,
    },
  },
})

export const clearOutputTasks = (params = {}) => ({
  type: 'OUTPUT_TASK_CLEAR',
})

export const getOutputTasksApi = (state) => state.outputTasksApi

export const loadOutputTasksApi = (params = {}) => ({
  type: 'OUTPUT_TASK_API_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/projects/${params.project_id}/tasks`,
      params,
    },
  },
})

export const clearOutputTasksApi = (params = {}) => ({
  type: 'OUTPUT_TASK_API_CLEAR',
})

export const getRequestInspection = (state) => state.requestInspection

export const loadRequestInspection = (params = {}) => ({
  type: 'REQUEST_INSPECTION_LOAD',
  payload: {
    client: 'defaultV1',
    request: { url: `/v1/postback_log`, params },
  },
})

export const clearRequestInspection = (params = {}) => ({
  type: 'REQUEST_INSPECTION_CLEAR',
})

export const getCreditUsage = (state) => state.creditUsage

export const loadCreditUsage = (params = {}) => ({
  type: 'CREDIT_USAGE_LOAD',
  payload: {
    client: 'defaultV1',
    request: { url: '/v1/credit_usage', params },
  },
})

export const clearCreditUsage = (params = {}) => ({
  type: 'CREDIT_USAGE_CLEAR',
})

export const getTaskLog = (state) => state.taskLog

export const loadTaskLog = (params = {}) => ({
  type: 'TASK_LOG_LOAD',
  payload: {
    client: 'defaultV1',
    request: { url: '/v1/logs', params },
  },
})

export const clearTaskLog = (params = {}) => ({
  type: 'TASK_LOG_CLEAR',
})

export const loadInvoice = (params = {}) => ({
  type: 'INVOICE_LOAD',
  payload: {
    client: 'blobV1',
    request: {
      url: `/v1/payments/download`,
      params,
    },
  },
})

export const getMembers = (state) => state.members

export const loadMembers = (params = {}) => ({
  type: 'MEMBERS_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: '/v1/users/members',
      params,
    },
  },
})

export const clearMembers = (params = {}) => ({
  type: 'MEMBERS_CLEAR',
})

export const inviteMember = (data) => ({
  type: 'MEMBER_INVITE',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/users/invitations`,
      method: 'POST',
      data,
    },
  },
})

export const updateMember = (data) => ({
  type: 'MEMBER_UPDATE',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/users/members/${data.id}`,
      method: 'PUT',
      data,
    },
  },
})

export const deleteMember = (data) => ({
  type: 'MEMBER_DELETE',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/users/members/${data.id}`,
      method: 'DELETE',
      data,
    },
  },
})

export const changeOwner = (data) => ({
  type: 'CHANGE_OWNER',
  payload: {
    client: 'defaultV1',
    request: {
      url: '/v1/users/ownership',
      method: 'PUT',
      data,
    },
  },
})

export const resentInvitation = (data) => ({
  type: 'MEMBER_RESENT_INVITE',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/users/invitations/${data.id}`,
      method: 'POST',
      data,
    },
  },
})

export const getActionLogs = (state) => state.actionLogs

export const loadActionLogs = (params = {}) => ({
  type: 'ACTION_LOGS_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/users/action_logs`,
      params,
    },
  },
})

export const clearActionLogs = (params = {}) => ({
  type: 'ACTION_LOGS_CLEAR',
})

export const getProjectNgWords = (state) => state.projectNgWords

export const clearProjectNgWords = () => ({
  type: 'PROJECT_NG_WORDS_CLEAR',
})

export const loadProjectNgWords = (params = {}) => ({
  type: 'PROJECT_NG_WORDS_LOAD',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/projects/${params.project_id}/ng_words`,
      params,
    },
  },
})

export const createProjectNgWord = (data) => ({
  type: 'PROJECT_NG_WORDS_CREATE',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/projects/${data.project_id}/ng_words`,
      method: 'POST',
      data,
    },
  },
})

export const deleteProjectNgWord = (data) => ({
  type: 'PROJECT_NG_WORDS_DELETE',
  payload: {
    client: 'defaultV1',
    request: {
      url: `/v1/projects/${data.project_id}/ng_words/${data.ng_word_id}`,
      method: 'DELETE',
      data,
    },
  },
})

export const exportProjectNgWords = (data) => ({
  type: 'PROJECT_NG_WORDS_EXPORT',
  payload: {
    client: 'blobV1',
    request: {
      url: `/v1/projects/${data.project_id}/ng_words/export_csv`,
      method: 'GET',
      data,
    },
  },
})
