import React, { useEffect, useState } from 'react'
import { get, flowRight as compose } from 'lodash'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { injectStripe } from 'react-stripe-elements'

import {
  getUserCards,
  loadUserCards,
  createUserCards,
  addNotification,
} from '@redux-action'

import withHeader from '@features/withHeader'
import withAuthorization from '@features/withAuthorization'

import CentralHeader from '../layouts/CentralHeader'
import Switch from '../shared/forms/Switch'
import Notification from '../shared/Notification'
import ButtonSubmit from '../shared/forms/ButtonSubmit'
import StripeCardForm from '../shared/cards/StripeCardForm'

const PaymentMethodsNewStripe = (props) => {
  const { stripe } = props
  let data = get(props, 'userCards.data') || []

  const [form, setForm] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    props.loadUserCards()
  }, [])

  const onInputChange = ({ target: { name, value, checked, type } }) => {
    setForm({
      ...form,
      [name]: type.toLowerCase() === 'checkbox' ? checked : value,
    })
  }

  const onSubmit = (ev) => {
    ev.preventDefault()

    setIsLoading(true)
    stripe.createToken({ name: get(form, 'name') }).then(({ token, error }) => {
      if (error) {
        props.addNotification({
          message: get(error, 'message') || t('PaymentMethodNew.cannotUseCard'),
          type: Notification.NOTIFICATION_TYPES.error,
        })
      } else {
        props
          .createUserCards({
            token: token.id,
            default_card: get(form, 'default_card', false),
          })
          .then((res) => {
            if (get(res, 'type', '').endsWith('_SUCCESS')) {
              props.addNotification({
                message: t('PaymentMethodNew.successAddCard'),
                type: Notification.NOTIFICATION_TYPES.success,
              })

              history.push('/app/billings/payment-methods')
            }
          })
      }
      setIsLoading(false)
    })
  }

  return (
    <React.Fragment>
      <CentralHeader
        header={t('PaymentMethodNew.newCreditCard')}
        breadcrumbs={[
          {
            label: t('PaymentMethod.paymentMethod'),
            to: '/app/billings/payment-methods',
          },
          { label: t('PaymentMethodNew.newCreditCard') },
        ]}
      />

      <div className="Central__sub-header">
        <p className="Central__sub-header__paragraph">
          <Trans i18nKey="PaymentMehtod.youHaveCredit" count={data.length}>
            You have <strong>{{ amount: data.length }}</strong> credit card.
          </Trans>
        </p>
      </div>

      <form className="Central__content" onSubmit={onSubmit}>
        <div className="Central__content__header">
          <h3 className="Central__content__h3">
            {t('PaymentMethodNew.cardInfo')}
          </h3>
        </div>

        <div className="Central__content__section">
          <div className="PaymentMethodsNew">
            <div className="PaymentMethodsNew__form">
              <StripeCardForm onInputChange={onInputChange} />

              <div className="PaymentMethodsNew__form__default">
                {t('PaymentMethodNew.setDefault')}{' '}
                <Switch
                  name="default_card"
                  checked={get(form, 'default_card')}
                  onInputChange={onInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="PaymentMethodsNew__buttons">
          <ButtonSubmit
            label={t('Dataset.create')}
            isLoading={isLoading || get(props, 'userCards.isLoading')}
          />
          <Link
            to="/app/billings/payment-methods"
            className="Button Button--gray"
          >
            {t('Misc.cancel')}
          </Link>
        </div>
      </form>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  userCards: getUserCards(state),
})

export default compose(
  withAuthorization(['owner']),
  withHeader(),
  connect(
    mapStateToProps,
    { addNotification, createUserCards, loadUserCards }
  ),
  injectStripe
)(PaymentMethodsNewStripe)
