import React from 'react'
import { get } from 'lodash'
import { withTranslation } from 'react-i18next'

import { hasMore } from '@helpers'

import ProjectGridItem from './ProjectGridItem'
import SectionLoader from '../SectionLoader'

class ProjectsGridView extends React.Component {
  render() {
    const {
      t,
      onDeleteProject,
      onUpdateProject,
      onConfirmDelete,
      onLoadMore,
    } = this.props

    return (
      <div className="Central__content">
        <SectionLoader
          isLoading={get(this.props, 'projects.isLoading')}
          isLoaded={get(this.props, 'projects.isLoaded')}
          isError={get(this.props, 'projects.isError')}
          isEmpty={
            Object.keys(get(this.props, 'projects.data', [])).length === 0
          }
          alwaysDisplayChildren
          customPlaceholder={
            <dl className="Projects__dl">
              <ProjectGridItem isPlaceholder />
            </dl>
          }
        >
          <dl className="Projects__dl">
            {get(this.props, 'projects.data', []).map((data, i) => (
              <ProjectGridItem
                key={i}
                data={data}
                onDeleteProject={
                  get(data, 'credit', 0) > 0 ? onConfirmDelete : onDeleteProject
                }
                onUpdateProject={onUpdateProject}
                sortBy={get(this.props, 'projects.meta.sort_by', '')}
              />
            ))}

            {Object.keys(get(this.props, 'projects.data', [])).length > 0 && (
              <ProjectGridItem isPlaceholder />
            )}
          </dl>

          {hasMore(get(this.props, 'projects')) && (
            <a
              className="LoadMore"
              onClick={onLoadMore}
            >
              {t('Misc.loadmore')}
            </a>
          )}
        </SectionLoader>
      </div>
    )
  }
}

export default withTranslation()(ProjectsGridView)
