import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import { addNotification } from '@redux-action'

import visibilityIcon from '../../../../../assets/images/icon-visibility.svg'
import Notification from '@shared/Notification'

const STYLE = {
  defaultStyle: {
    backgroundColor: 'rgba(70, 215, 206, 0.2)',
    color: '#40bfb7',
  },
  altStyle: {
    backgroundColor: 'rgba(59, 59, 59, 0.5)',
    color: '#fff',
  }
}

const ProjectKey = (props) => {
  const { licenseKey, addNotification, style = 'defaultStyle' } = props

  const [isShow, setIsShow] = React.useState(false)
  const { t } = useTranslation()
  const inputRef = React.useRef(null)

  const onClick = () => {
    setIsShow((isShow) => !isShow)
  }

  const onCopy = () => {
    inputRef.current.select()

    if (isShow && document.execCommand('copy')) {
      addNotification({
        message: t('Project.projectKeyCopied', {
          key: get(props, 'licenseKey'),
        }),
        type: Notification.NOTIFICATION_TYPES.success,
      })

      inputRef.current.blur()
    }
  }

  return (
    <div
      css={{
        position: 'relative',
        width: '230px',
        borderRadius: '50px',
        display: 'inline-block',
        ...STYLE[style],
      }}
      className={props.className}
    >
      <input
        css={{
          lineHeight: '1.4',
          padding: '5px 15px',
          fontSize: '12px',
          width: '100%',
          border: 'none',
          outline: 'none',
          userSelect: 'none',
          cursor: isShow ? 'pointer' : 'auto',
          background: 'transparent',
          color: 'inherit',
        }}
        type="text"
        value={isShow ? licenseKey : '●●●●●●●●●●●●●'}
        readOnly
        onClick={onCopy}
        ref={inputRef}
      />
      <img
        css={{
          cursor: 'pointer',
          position: 'absolute',
          right: '8px',
          top: '3px',
          width: '20px',
          height: '20px'
        }}
        onClick={onClick}
        src={visibilityIcon}
      />
    </div>
  )
}

const mapDispatch = (dispatch) => ({
  addNotification: (params) => dispatch(addNotification(params)),
})

export default connect(
  null,
  mapDispatch
)(ProjectKey)
