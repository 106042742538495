import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import className from 'classnames'
import { withTranslation } from 'react-i18next'

class ButtonSubmit extends React.Component {
  static propTypes = {}

  static defaultProps = {
    label: 'Update',
    isLoading: false,
  }

  state = {}

  render = () => {
    const { disabled, t } = this.props

    return (
      <button
        disabled={this.props.isLoading || disabled}
        className={className('Button', {
          'Button--green': !this.props.klassName,
          [`${this.props.klassName}`]: this.props.klassName,
        })}
      >
        {this.props.isLoading ? t('Misc.loading') : this.props.label}
      </button>
    )
  }
}

export default withTranslation()(ButtonSubmit)
