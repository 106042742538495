import React from 'react'
import { get, startCase, isEmpty } from 'lodash'
import { withTranslation } from 'react-i18next'

import Modal from '@shared/Modal'
import { ModalH3 } from '@shared/Modal/Modal.style'

const ProjectTextNgPreview = (props) => {
  const { t, task, project } = props
  const { word_occurrence: wordOccurrence } = task

  const [isOpen, setOpen] = React.useState(false)

  const onToggleModal = (e) => {
    e.stopPropagation()

    setOpen((s) => !s)
  }

  const value = task?.data

  return (
    <div className="ProjectTextPreview">
      <div className="ProjectTextPreview__info">
        <a
          className="ProjectTextPreview__info__handle"
          onClick={onToggleModal}
        />
      </div>

      <Modal isActive={isOpen} toggleFunction={onToggleModal}>
        <ModalH3>{startCase(get(project, 'data.attributes.template'))}</ModalH3>

        <p className="Strike" css={{ marginBottom: '16px' }}>
          {t('ProjectApiTable.fullSentence')}
        </p>

        <div className="ProjectTextPreview__text">
          <p>{value}</p>
        </div>

        {!isEmpty(wordOccurrence) && (
          <div className="ProjectTextPreview__word-occurrence">
            <div className="ProjectTextPreview__word-occurrence__title Strike">
              {t('ProjectApiTable.wordOccurrence')}
            </div>

            <div className="ProjectTextPreview__word-occurrence__grid">
              {Object.keys(wordOccurrence).map((key) => {
                return (
                  <p key={`${key}_${wordOccurrence[key]}`}>
                    {key}: {wordOccurrence[key]}
                  </p>
                )
              })}
            </div>
          </div>
        )}

        <div className="Modal__button">
          <a className="Button Button--green" onClick={onToggleModal}>
            {t('Misc.close')}
          </a>
        </div>
      </Modal>
    </div>
  )
}

export default withTranslation()(ProjectTextNgPreview)
