import React from 'react'

const Icon = (props, ref) => {
  const {
    name,
    className,
    viewBox = `0 0 24 24`,
    iconPath,
    iconStyle = {},
    iconFill = 'currentcolor',
    width,
    height,
    ...rest
  } = props

  return (
    <div
      className={className}
      css={{ display: 'inline-block' }}
      {...rest}
      ref={ref}
    >
      <svg
        css={{
          fill: iconFill,
          ...iconStyle,
          overflow: 'unset',
          maxWidth: 'none',
        }}
        width={width}
        height={height}
        viewBox={viewBox}
        preserveAspectRatio="xMidYMid meet"
        x="0"
        y="0"
        id={name}
      >
        <path d={iconPath} />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>
  )
}

export default React.forwardRef(Icon)
