import React from 'react'
import { get, flowRight as compose } from 'lodash'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { addNotification, updateUserPassword, getUser } from '@redux-action'

import withHeader from '@features/withHeader'

import CentralHeader from '@layouts/CentralHeader'
import ButtonSubmit from '@shared/forms/ButtonSubmit'
import Notification from '@shared/Notification'

const defaultState = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
}

const Settings = (props) => {
  const [form, setForm] = React.useState(defaultState)
  const { t } = useTranslation()

  const onChange = ({ target: { name, value } }) =>
    setForm((form) => ({ ...form, [name]: value }))

  const onSubmit = (e) => {
    e.preventDefault()

    const { oldPassword, newPassword, confirmNewPassword } = form

    if (newPassword !== confirmNewPassword) {
      return props.addNotification({
        message: t('Signup.passwordMismatch'),
        type: Notification.NOTIFICATION_TYPES.warning,
      })
    }

    props
      .updateUserPassword({
        old_password: oldPassword,
        new_password: newPassword,
      })
      .then((res) => {
        if (get(res, 'type', '').endsWith('_SUCCESS')) {
          props.addNotification({
            message: t('Settings.accountUpdated'),
            type: Notification.NOTIFICATION_TYPES.success,
          })

          setForm(defaultState)
        }
      })
  }

  return (
    <React.Fragment>
      <CentralHeader
        header={t('Settings.setting')}
        breadcrumbs={[{ label: t('Settings.setting') }]}
      />

      <div className="Central__sub-header"></div>

      <div className="Central__content">
        <div className="Settings">
          <form className="Settings__col" onSubmit={onSubmit}>
            <h3 className="Central__content__h3">
              {t('Settings.changePassword')}
            </h3>

            <div className="Central__content__section">
              <div className="Settings__form">
                <div className="Settings__form__col">
                  <label className="Label">{t('Settings.password')}</label>
                  <input
                    type="password"
                    className="Input"
                    onChange={onChange}
                    value={form.oldPassword}
                    name="oldPassword"
                    placeholder={t('Settings.currentPassword')}
                    required
                  />
                </div>

                <div className="Settings__form__col"></div>

                <div className="Settings__form__col">
                  <label className="Label">{t('Settings.newPassword')}</label>
                  <input
                    type="password"
                    className="Input"
                    onChange={onChange}
                    value={form.newPassword}
                    name="newPassword"
                    placeholder={t('Settings.newPassword')}
                    required
                  />
                </div>

                <div className="Settings__form__col">
                  <label className="Label">
                    {t('Settings.confirmNewPass')}
                  </label>
                  <input
                    type="password"
                    className="Input"
                    onChange={onChange}
                    value={form.confirmNewPassword}
                    name="confirmNewPassword"
                    placeholder={t('Settings.confirmNewPass')}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="Settings__buttons" data-test-id="settings-submit">
              <ButtonSubmit isLoading={get(props, 'user.isLoading')} />
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  user: getUser(state),
})

export default compose(
  withHeader(),
  connect(
    mapStateToProps,
    { addNotification, updateUserPassword }
  )
)(Settings)
