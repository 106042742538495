import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import ProjectNewCard from './ProjectNewCard'
import Select from '@shared/forms/Select'
import { Input, Button } from '@shared/style'
import { ProjectSection, ButtonWrapper } from '@shared/style/Project.style'

const H3 = styled.h3`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.77px;
  margin-bottom: 24px;
`

const ProjectNewForm = ({
  form,
  onChange,
  onSubmit,
  currentStep,
  setCurrentStep,
  transformValue,
  templates = [],
  isLoading,
}) => {
  const { t } = useTranslation()

  return (
    <form
      css={{
        width: '780px',
        margin: '0 auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 780px)',
        columnGap: '78px',
        alignItems: 'start',

        '> div': {
          padding: '65px 24px 32px',
          transition: 'transform 0.2s, opacity 0.2s',
        },
      }}
      onSubmit={onSubmit}
    >
      <ProjectSection
        css={{
          transform: `translateX(${transformValue}%)`,
          opacity: currentStep === 0 ? 1 : 0,
        }}
      >
        <H3>{t('Projects.step1SelectTemplate')}</H3>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 170px)',
            gap: '16px',
            marginBottom: '24px',
          }}
        >
          {templates.map((template, idx) => (
            <React.Fragment key={idx}>
              <input
                css={{
                  display: 'none',
                  '&:checked': {
                    '+ label': {
                      backgroundColor: '#f2fffe',
                      boxShadow: 'inset 0 0 0 4px var(--c-green)',
                    },
                  },
                }}
                type="radio"
                name="template"
                onChange={onChange}
                checked={template.value === form.template}
                value={template.value}
                id={template.value}
              />
              <ProjectNewCard {...template} />
            </React.Fragment>
          ))}
        </div>
        <ButtonWrapper>
          <Link to="/app/projects">
            <Button type="button">{t('ProjectNew.backToProjects')}</Button>
          </Link>
          <Button
            primary
            onClick={() => setCurrentStep(1)}
            type="button"
            disabled={!form.template}
          >
            {t('steps.next')}
          </Button>
        </ButtonWrapper>
      </ProjectSection>
      <ProjectSection
        css={{
          transform: `translateX(${transformValue}%)`,
          opacity: currentStep === 1 ? 1 : 0,
        }}
        style={{ padding: '65px 150px 50px' }}
      >
        <H3 css={{ marginBottom: '56px' }}>
          {t('Projects.step2SetupWebhook')}
        </H3>
        <div css={{ display: 'flex', marginBottom: '56px' }}>
          <Select
            withOutLabel
            options={[
              { label: 'GET', value: 'GET' },
              { label: 'POST', value: 'POST' },
            ]}
            name="postbackMethod"
            onChange={onChange}
            value={form.postbackMethod}
            css={{ marginRight: '24px' }}
          />
          <Input
            type="text"
            name="postbackUrl"
            onChange={onChange}
            placeholder="Webhook URL"
            value={form.postbackUrl}
            required
          />
        </div>
        <ButtonWrapper>
          <Button type="button" onClick={() => setCurrentStep(0)}>
            {t('steps.back')}
          </Button>
          <Button
            primary
            type="submit"
            disabled={!form.template || !form.postbackUrl || isLoading}
          >
            {isLoading ? t('Misc.loading') : t('ProjectNew.createProject')}
          </Button>
        </ButtonWrapper>
      </ProjectSection>
    </form>
  )
}

export default ProjectNewForm
