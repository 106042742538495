import React from 'react'
import { get, replace, chunk, flowRight as compose } from 'lodash'
import className from 'classnames'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslation, useTranslation } from 'react-i18next'

import Modal from '../Modal'

import {
  loadUserCards,
  updateUserCards,
  deleteUserCards,
  loadUserDefaultCard,
  addNotification,
} from '@redux-action'

import DotsMenu from '../DotsMenu'
import Switch from '../forms/Switch'
import Notification from '../Notification'

class CreditCard extends React.Component {
  static propTypes = {}

  static defaultProps = {
    isCreating: false,
  }

  state = {
    modals: {
      confirmDelete: false,
    },
  }

  onToggleDeleteCard = () => {
    this.setState((prevState, props) => ({
      modals: {
        ...prevState.modals,
        confirmDelete: !prevState.modals.confirmDelete,
      },
    }))
  }

  onConfirmToDeleteCard = () => {
    const { t } = this.props

    this.props
      .deleteUserCards({
        id: get(this.props, 'x.id'),
      })
      .then((res) => {
        if (get(res, 'type', '').endsWith('_SUCCESS')) {
          this.props.addNotification({
            message: t('CreditCard.successDeleteCard'),
            type: Notification.NOTIFICATION_TYPES.success,
          })

          this.props.loadUserCards()
        }
      })
  }

  onUpdateCard = () => {
    const { t } = this.props

    this.props
      .updateUserCards({
        id: get(this.props, 'x.id'),
        default_card: !get(this.props, 'x.default_card'),
      })
      .then((res) => {
        if (get(res, 'type', '').endsWith('_SUCCESS')) {
          this.props.addNotification({
            message: t('CreditCard.successChangeDefaultCard'),
            type: Notification.NOTIFICATION_TYPES.success,
          })

          this.props.loadUserDefaultCard() // In case there is a new default card.
          this.props.loadUserCards() // Just reload to get all updated cards.
        }
      })
  }

  render() {
    const { t } = this.props
    const lastDigits = replace(get(this.props, 'x.last_digits'), /X/g, '•')

    return (
      <div
        className={className('CreditCard', {
          'CreditCard--active': get(this.props, 'x.default_card'),
        })}
      >
        <div
          className={className('CreditCard__header', {
            [`CreditCard__header--${get(
              this.props,
              'x.brand',
              ''
            ).toLowerCase()}`]: true,
          })}
        >
          {!this.props.isCreating && (
            <DotsMenu
              data={[
                { label: t('Misc.delete'), onClick: this.onToggleDeleteCard },
              ]}
            />
          )}
        </div>

        <div className="CreditCard__central">
          <dt>{get(this.props, 'x.name')}</dt>
          <dd>{lastDigits}</dd>
        </div>

        <div className="CreditCard__footer">
          <time dateTime="">
            {get(this.props, 'x.expiration_month')}/
            {get(this.props, 'x.expiration_year', '').substr(-2)}
          </time>
          {!this.props.isCreating && (
            <div className="CreditCard__footer__p">
              {t('CreditCard.defaultCard')}{' '}
              <Switch
                small
                checked={get(this.props, 'x.default_card')}
                onInputChange={this.onUpdateCard}
              />
            </div>
          )}
        </div>
        <Modal
          isActive={this.state.modals.confirmDelete}
          toggleFunction={this.onToggleDeleteCard}
        >
          <h3 className="Modal__h3">{t('Misc.delete')}</h3>
          <div className="ConfirmDialog">
            <p>
              {get(this.props, 'x.default_card')
                ? t('CreditCard.confirmDeleteCard1')
                : t('CreditCard.confirmDeleteCard2')}
            </p>
            <div className="Modal__button">
              <button
                className="Button Button--gray"
                onClick={this.onToggleDeleteCard}
              >
                {t('Misc.cancel')}
              </button>
              <button
                className="Button Button--green"
                onClick={this.onConfirmToDeleteCard}
              >
                {t('Misc.ok')}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default compose(
  connect(
    mapStateToProps,
    {
      addNotification,
      loadUserCards,
      updateUserCards,
      deleteUserCards,
      loadUserDefaultCard,
    }
  ),
  withTranslation()
)(CreditCard)

export const CreditCardPlaceholder = () => {
  const { t } = useTranslation()

  return (
    <div
      className={className('CreditCard', {
        'CreditCard--placeholder': true,
      })}
    >
      <Link
        to="/app/billings/payment-methods/new"
        className="CreditCard__placeholder"
      >
        <span className="CreditCard__placeholder__title">
          {t('CreditCard.addNew')}
        </span>
        <span className="CreditCard__placeholder__subtitle">
          {t('CreditCard.clickToAddCard')}
        </span>
      </Link>
    </div>
  )
}
