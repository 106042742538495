import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { get } from 'lodash'
import moment from 'moment'
import XLSX from 'xlsx'
import { useTranslation } from 'react-i18next'

export const RouteWithSubRoutes = (route) => (
  <Route
    path={route.path}
    exact={route.exact}
    render={(props) =>
      route.redirectTo ? (
        <Redirect to={route.redirectTo} />
      ) : (
        <route.component {...props} {...route} />
      )
    }
  />
)

export const l10n = (key) => {
  return get(gon.locales, `${gon.currentUser.language}.${key}`, key)
}

export const COOKIES_KEY = '_datawow_client_dashboard2018_access_token'
export const COOKIES_KEY_RESET_PASSWORD =
  '_datawow_client_dashboard2018_reset_password_token'
export const COOKIES_KEY_NOTIFICATION =
  '_datawow_client_dashboard2018_notification'
export const OMISE_CHARD_ID_KEY = '_datawow_client_dashboard2018_omise_chard_id'

export const FormatDateWithTime = (date) => {
  return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
}

export const csvReader = (text) => {
  text = text.split('\n')
  text.shift()

  return text.join('\n')
}

export const jsonReader = (text) => {
  let content = ''

  if (typeof text === 'object') {
    content = text.data[0].map((x) => `${x.id},${x.data}`).join('\n')
  } else {
    content = JSON.parse(text)
      .data.map((x) => `${x.id},${x.data}`)
      .join('\n')
  }

  return content
}

export const excelReader = (text) => {
  let json = { data: [] }
  let workbook = XLSX.read(text, {
    type: 'binary',
  })

  json.data = workbook.SheetNames.map((sheetName) =>
    XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])
  )

  return jsonReader(json)
}

export const whichFile = (e, type) => {
  let content = ''

  switch (type) {
    case 'application/json':
      content = jsonReader(e.target.result)
      break
    case 'text/csv':
      content = csvReader(e.target.result)
      break
    default:
      content = excelReader(e.target.result)
  }

  return content
}

export const setNewRefObj = (data) => JSON.parse(JSON.stringify(data))

export const isEmail = (email) =>
  /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email)

export const replaceItemArray = (sourceArr, itemToAdd, index) =>
  Object.assign([...sourceArr], { [index]: itemToAdd })

export const hasMore = (obj) =>
  obj && obj.isLoaded && obj.data.length < obj.meta.total

export const getFile = (res, type, fileName) => {
  const url = window.URL.createObjectURL(new Blob([res.payload.data]))
  const link = document.createElement('a')

  let filename = fileName ? `${fileName}.${type}` : `file.${type}`
  const disposition = res.payload.headers['content-disposition']

  if (disposition && disposition.includes('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)
    if (matches !== null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }
  link.href = url
  link.setAttribute('download', filename)

  document.body.appendChild(link)

  link.click()
  link.remove()
}

export const removeFileExtension = (str) =>
  str.substr(0, str.lastIndexOf('.')) || str

export class ClickOutside extends React.Component {
  static propTypes = {
    onClickOutside: PropTypes.func.isRequired,
  }

  container = createRef()

  componentDidMount() {
    document.addEventListener('touchend', this.handle)
    document.addEventListener('click', this.handle)
  }

  componentWillUnmount() {
    document.removeEventListener('touchend', this.handle)
    document.removeEventListener('click', this.handle)
  }

  handle = (e) => {
    if (e.type === 'touchend') this.isTouch = true
    if (e.type === 'click' && this.isTouch) return

    const { onClickOutside } = this.props
    const el = this.container.current

    if (el && !el.contains(e.target)) onClickOutside(e)
  }

  render() {
    const { children, onClickOutside, ...props } = this.props

    return (
      <div {...props} ref={this.container}>
        {children}
      </div>
    )
  }
}

export const getLastNYears = (lastNYear) => {
  const year = new Date().getFullYear()
  return Array.from({ length: lastNYear }, (_, i) => year - lastNYear + i + 1)
}

export const getMonths = () =>
  Array(12)
    .fill()
    .map((e, i) => i + 1)

export function useDateDiff() {
  const { t } = useTranslation()

  const getDateDiff = (d1, d2) => {
    if (!d1 || !d2) return 'N/A'

    d1 = moment(d1)
    d2 = moment(d2)
    const diffS = Math.floor(d2.diff(d1) / 1000)
    const sec = diffS % 60
    const diffM = Math.floor(diffS / 60)
    const min = diffM % 60
    const diffH = Math.floor(diffM / 60)
    const hour = diffH % 60

    if (hour)
      return `${hour}${t('Misc.hour', { count: hour })} ${min}${t(
        'Misc.minute',
        { count: min }
      )}`
    if (min)
      return `${min}${t('Misc.minute', { count: min })} ${sec}${t(
        'Misc.second',
        { count: sec }
      )}`
    return `${sec}${t('Misc.second', { count: sec })}`
  }

  return getDateDiff
}

export const fireConversion = () =>
  window.gtag &&
  window.gtag('event', 'conversion', {
    send_to: 'AW-980067607/QZ9QCJLZ6LgBEJfKqtMD',
  })

export const isOwner = (role) => role === 'owner'

export const isAdmin = (role) => role === 'admin'

export const isUser = (role) => role === 'user'

export const isExecutiveUser = (role) => role === 'admin' || role === 'owner'

export const summarizeAnswer = (answer, type) => {
  if (type !== 'text') return answer
  if (answer === null) return null

  return answer.declined >= 0.8 ? 'declined' : 'approved'
}

export const getTypeFromTemplate = (template) => {
  if (IMAGE_TEMPLATES.includes(template)) return 'image'
  if (VIDEO_TEMPLATES.includes(template)) return 'video'
  if (TEXT_TEMPLATES.includes(template)) return 'text'

  return null
}

export const IMAGE_TEMPLATES = [
  'image_ai',
  'ai_human',
  'ai_human_consensus',
  'id_card_check',
  'image_bulk_check',
]

export const VIDEO_TEMPLATES = ['video_snapshot']

export const TEXT_TEMPLATES = [
  'text_ai',
  'text_ai_ng',
  'text_check',
  'text_bulk_check',
]
