import React from 'react'
import { get, replace, omitBy, flowRight as compose } from 'lodash'
import className from 'classnames'
import { connect } from 'react-redux'
import moment from 'moment'
import { useTranslation, Trans } from 'react-i18next'

import {
  getUserCards,
  loadUserCards,
  getPaymentHistories,
  loadPaymentHistories,
  loadInvoice,
} from '@redux-action'

import { hasMore, getLastNYears, getFile, getMonths } from '@helpers'

import withAuthorization from '@features/withAuthorization'
import withHeader from '@features/withHeader'

import CentralHeader from '@layouts/CentralHeader'
import Select from '@shared/forms/Select'
import CreditCard, { CreditCardPlaceholder } from '@shared/cards/CreditCard'
import SectionLoader from '@shared/SectionLoader'
import { FormatDateWithTime } from '../../lib/helpers'

const PaymentMethods = (props) => {
  const {
    loadUserCards,
    loadPaymentHistories,
    paymentHistories,
    userCards,
    loadInvoice,
  } = props

  const [selectedMonth, setSelectedMonth] = React.useState(moment().format('M'))
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )

  React.useEffect(() => {
    loadUserCards()
    loadPaymentHistories({ ..._getMeta() })
  }, [])

  React.useEffect(() => {
    loadPaymentHistories({
      month: selectedMonth,
      year: selectedYear,
    })
  }, [selectedMonth, selectedYear])

  const { t } = useTranslation()

  const _formatCurrency = (num, currency, locale = 'en-US') => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency === null ? 'thb' : currency,
      minimumFractionDigits: 0,
    }).format(num)
  }

  const _getMeta = () => {
    let page = get(props, 'paymentHistories.meta.page')

    if (!page || page < 0) {
      page = null
    }

    const params = omitBy(
      {
        page,
        month: selectedMonth,
        year: selectedYear,
      },
      (x) => !x
    )

    return params
  }

  const onLoadMore = (e) => {
    loadPaymentHistories({
      ..._getMeta(),
      page: (get(props, 'paymentHistories.meta.page') || 1) + 1,
    })
  }

  const onChangeMonth = ({ target: { value } }) => setSelectedMonth(value)
  const onChangeYear = ({ target: { value } }) => setSelectedYear(value)

  const onClickDownload = () => {
    loadInvoice({
      month: selectedMonth,
      year: selectedYear,
    }).then((res) => {
      getFile(res, 'pdf')
    })
  }

  const headers = [
    t('PaymentMethod.creditCard'),
    t('PaymentMethod.credits'),
    t('PaymentMethod.total'),
    t('PaymentMethod.date'),
    t('Misc.status'),
    t('PaymentMethod.remark'),
  ]

  return (
    <React.Fragment>
      <CentralHeader
        header={t('PaymentMethod.paymentMethod')}
        breadcrumbs={[{ label: t('PaymentMethod.paymentMethod') }]}
      />

      <div className="Central__sub-header">
        <p className="Central__sub-header__paragraph">
          <Trans
            i18nKey="PaymentMethod.youHaveCredit"
            count={get(props, 'userCards.data', []).length}
          >
            You have <strong>{{ amount: userCards.length }}</strong> credit
            card.
          </Trans>
          {false && (
            <Trans i18nKey="PaymentMethod.requestQuotation">
              You can also request for a quotation <a>here</a>
            </Trans>
          )}
        </p>
      </div>

      <div className="Central__content">
        <div className="PaymentMethods">
          <SectionLoader
            isLoading={get(props, 'userCards.isLoading')}
            isLoaded={get(props, 'userCards.isLoaded')}
            isError={get(props, 'userCards.isError')}
            isEmpty={get(props, 'userCards.data', []).length === 0}
            customPlaceholder={
              <dl className="PaymentMethods__dl">
                <CreditCardPlaceholder />
              </dl>
            }
          >
            <dl className="PaymentMethods__dl">
              {get(props, 'userCards.data', []).map((card, i) => (
                <CreditCard key={i} x={card.attributes} />
              ))}

              <CreditCardPlaceholder />
            </dl>
          </SectionLoader>
        </div>

        <div>
          <span css={{ fontSize: '20px' }}>※</span>{' '}
          VISA、MasterCard、AmericanExpressのみ利用可能です
        </div>

        <div className="Central__content__select-search">
          <Select
            label={t('Misc.year')}
            value={selectedYear}
            isLoading={paymentHistories.isLoading}
            onChange={onChangeYear}
            options={getLastNYears(10).map((year) => ({
              label: year,
              value: year,
            }))}
            backLabel
          />
          <Select
            label={t('Misc.month')}
            value={selectedMonth}
            isLoading={paymentHistories.isLoading}
            onChange={onChangeMonth}
            options={getMonths().map((month) => ({
              label: month,
              value: month,
            }))}
            backLabel
          />
          <span className="Table__td__download" onClick={onClickDownload} />
        </div>

        <div className="Central__content__section">
          <SectionLoader
            isLoading={get(props, 'paymentHistories.isLoading')}
            isLoaded={get(props, 'paymentHistories.isLoaded')}
            isError={get(props, 'paymentHistories.isError')}
            isEmpty={get(props, 'paymentHistories.data', []).length === 0}
            alwaysDisplayChildren
          >
            <div className="Table">
              <div className="Table__tr">
                {headers.map((header, i) => (
                  <div key={i} className="Table__td Table__td--th">
                    {header}
                  </div>
                ))}
              </div>

              {get(props, 'paymentHistories.data', []).map((x, i) => (
                <div className="Table__tr" key={i} data-x={x}>
                  <div className="Table__td">
                    <div
                      className={className('Table__td__cc', {
                        [`Table__td__cc--${get(
                          x,
                          'attributes.credit_card_brand',
                          ''
                        ).toLowerCase()}`]: true,
                      })}
                    >
                      {replace(
                        get(x, 'attributes.credit_card_last_digits'),
                        /X/g,
                        '•'
                      )}
                    </div>
                  </div>
                  <div className="Table__td">{get(x, 'attributes.amount')}</div>
                  <div className="Table__td">
                    {_formatCurrency(
                      get(x, 'attributes.price'),
                      get(x, 'attributes.currency')
                    )}
                  </div>
                  <div className="Table__td">
                    {FormatDateWithTime(get(x, 'attributes.created_at'))}
                  </div>
                  <div className="Table__td">{get(x, 'attributes.status')}</div>
                  <div className="Table__td">
                    {get(x, 'attributes.description') ||
                      t('PaymentMethod.buyCredit')}
                  </div>
                </div>
              ))}
            </div>

            {hasMore(get(props, 'paymentHistories')) && (
              <a className="LoadMore" onClick={onLoadMore}>
                {t('Misc.loadmore')}
              </a>
            )}
          </SectionLoader>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  userCards: getUserCards(state),
  paymentHistories: getPaymentHistories(state),
})

export default compose(
  withAuthorization(['owner']),
  withHeader(),
  connect(
    mapStateToProps,
    { loadUserCards, loadPaymentHistories, loadInvoice }
  )
)(PaymentMethods)
