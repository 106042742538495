import React from 'react'
import PropTypes from 'prop-types'
import { get, startCase } from 'lodash'
import { withTranslation } from 'react-i18next'

import Modal from '@shared/Modal'
import ImageLoader from '@shared/ImageLoader'

class ProjectImagePreview extends React.Component {
  state = {
    isModalOpened: false,
  }

  onToggleModal = (e) => {
    e.stopPropagation()

    this.setState((prevState) => ({
      isModalOpened: !prevState.isModalOpened
    }));
  };

  render() {
    const { t } = this.props

    return (
      <div className="ProjectImagePreview">
        <a className="ProjectImagePreview__a" onClick={this.onToggleModal}>
          <ImageLoader
            onClick={this.onToggleModal}
            url={get(this.props, 'item.data')}
          />
        </a>

        <Modal
          isActive={this.state.isModalOpened}
          toggleFunction={this.onToggleModal}
        >
          <h3 className="Modal__h3">
            {startCase(get(this.props, 'project.data.attributes.template'))}
          </h3>
          <p className="Strike" css={{ marginBottom: '16px' }}>
            {get(this.props, 'project.data.attributes.name')}
          </p>

          <div className="ProjectImagePreview__image">
            <ImageLoader url={get(this.props, 'item.data')} renderAsImg />
          </div>

          <div className="Modal__button">
            <a className="Button Button--green" onClick={this.onToggleModal}>
              {t('Misc.close')}
            </a>
          </div>
        </Modal>
      </div>
    )
  }
}

export default withTranslation()(ProjectImagePreview)
