import React from 'react'
import { get, startCase } from 'lodash'
import { withTranslation } from 'react-i18next'

import Modal from '@shared/Modal'

class ProjectVideoPreview extends React.Component {
  $video = React.createRef()

  state = {
    isModalOpened: false,
  }

  onToggleModal = (e) => {
    e.stopPropagation()

    this.setState((prevState, props) => ({
      isModalOpened: !prevState.isModalOpened
    }), () => {
      if (this.$video.current) {
        if (this.state.isModalOpened) {
          this.$video.current.load()
        }
        else {
          this.$video.current.pause()
        }
      }
    })
  }

  render() {
    const { t } = this.props

    return (
      <div className="ProjectImagePreview">
        <a className="ProjectImagePreview__a" onClick={this.onToggleModal}>
          {t('ProjectVideoPreview.watch')}
        </a>

        <Modal
          isActive={this.state.isModalOpened}
          toggleFunction={this.onToggleModal}
        >
          <h3 className="Modal__h3">
            {startCase(get(this.props, 'project.data.attributes.template'))}
          </h3>
          <p className="Strike" css={{ marginBottom: '16px' }}>
            {get(this.props, 'project.data.attributes.name')}
          </p>

          <div className="ProjectImagePreview__image">
            <video controls width="500" preload="none" ref={this.$video}>
              <source src={get(this.props, 'item.data')} type="video/mp4" />
            </video>
          </div>

          <div className="Modal__button">
            <a className="Button Button--green" onClick={this.onToggleModal}>
              {t('Misc.close')}
            </a>
          </div>
        </Modal>
      </div>
    )
  }
}

export default withTranslation()(ProjectVideoPreview)
