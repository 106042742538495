import React from 'react'
import { get } from 'lodash'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getUser, updateUserLanguage, loadUser } from '@redux-action'

import ChangeLanguage from '@shared/ChangeLanguage'

const CentralHeader = (props) => {
  const { breadcrumbs = [], user, header, updateUserLanguage, loadUser } = props
  const { t } = useTranslation()

  const renderBreadcrumb = (breadcrumb, index) => {
    if (breadcrumb.to)
      return (
        <li key={index} className="Central__header__breadcrumb__li">
          <Link to={breadcrumb.to}>{breadcrumb.label}</Link>
        </li>
      )
    if (breadcrumb.last)
      return (
        <li
          key={index}
          className="Central__header__breadcrumb__li Central__header__breadcrumb__li--last"
        >
          {breadcrumb.label}
        </li>
      )
    return (
      <li key={index} className="Central__header__breadcrumb__li">
        {breadcrumb.label}
      </li>
    )
  }

  return (
    <div className="Central__header">
      <h2 className="Central__header__h2">{header}</h2>
      <div className="Central__header__top-right">
        <ChangeLanguage
          updateUserLanguage={updateUserLanguage}
          loadUser={loadUser}
        />
      </div>
      <ul className="Central__header__breadcrumb">
        {breadcrumbs.map((breadcrumb, i) => renderBreadcrumb(breadcrumb, i))}
      </ul>
      <div className="Central__header__right">
        {t('CentralHeader.currentCredits')}:
        <span className="Badge">
          {get(user, 'data.attributes.credit', 0).toLocaleString()}
        </span>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: getUser(state),
})

export default connect(
  mapStateToProps,
  { updateUserLanguage, loadUser }
)(CentralHeader)
