import React from 'react'
import { get, capitalize } from 'lodash'
import { Trans } from 'react-i18next'

import SectionLoader from '@shared/SectionLoader'
import Pagination from '@shared/Pagination'
import ConfirmModal from '@shared/Modal/ConfirmModal'
import { Button } from '@shared/style'

import CloseIcon from '../../../../../assets/images/icon-bin.svg'

// import Select from '@shared/forms/Select'

export const NgWordTable = (props) => {
  const {
    isError,
    isLoaded,
    isLoading,
    meta,
    ngWords,
    onChangePage,
    onDeleteNgWord,
    t,
  } = props

  const [selectedWord, setSelectedWord] = React.useState('')
  const [cfDeleteNgWord, setCfDeleteNgWord] = React.useState(false)

  const headers = React.useMemo(
    () => [t('ProjectNgWords.word'), t('ProjectNgWords.category'), ''],
    []
  )

  return (
    <React.Fragment>
      <SectionLoader
        isLoading={isLoading}
        isLoaded={isLoaded}
        isError={isError}
        isEmpty={ngWords.length === 0}
        alwaysDisplayChildren
      >
        <div className="Table">
          <div className="Table__tr">
            {headers.map((header, i) => (
              <div key={i} className="Table__td Table__td--th">
                {header}
              </div>
            ))}
          </div>
          {ngWords.map((word, idx) => {
            const _word = get(word, 'attributes.word')
            const category = get(word, 'attributes.category')

            return (
              <div key={idx} className="Table__tr">
                <div className="Table__td">{_word}</div>
                <div className="Table__td">{capitalize(category)}</div>
                <div className="Table__td">
                  <button
                    onClick={() => {
                      setSelectedWord(word)
                      setCfDeleteNgWord(true)
                    }}
                    css={CloseIconStyle}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </SectionLoader>
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 'var(--global-space)',
        }}
      >
        {/* <Select
          value={get(meta, 'per_page', '')}
          onChange={onChangePerPage}
          label={t('Misc.display')}
          name="perPage"
          id="perPage"
          options={[
            { label: '10', value: 10 },
            { label: '15', value: 15 },
            { label: '25', value: 25 },
            { label: '50', value: 50 },
          ]}
        /> */}
        <Pagination
          total={meta.total}
          currentPage={meta.page}
          perPage={meta.per_page}
          onChangePage={onChangePage}
        />
      </div>

      <ConfirmModal
        isActive={cfDeleteNgWord}
        toggleFunction={() => setCfDeleteNgWord(false)}
        title={
          <Trans i18nKey="ProjectNgWords.confirmDeleteDesc">
            Are you sure you want to remove
            <br />
            {{ word: get(selectedWord, 'attributes.word') }}
          </Trans>
        }
        buttons={
          <React.Fragment>
            <Button onClick={() => setCfDeleteNgWord(false)}>
              {t('Misc.cancel')}
            </Button>
            <Button
              primary
              onClick={() => {
                onDeleteNgWord(selectedWord.id)
                setCfDeleteNgWord(false)
              }}
              disabled={isLoading}
            >
              {isLoading ? t('Misc.loading') : t('Misc.ok')}
            </Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}

const CloseIconStyle = {
  border: 'none',
  outline: 'none',
  width: '20px',
  height: '20px',
  backgroundColor: 'transparent',
  backgroundImage: `url(${CloseIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  cursor: 'pointer',
}
