import React from 'react';
import PropTypes from 'prop-types';
import {get, reverse} from 'lodash';
import className from 'classnames';
import moment from 'moment';
import {randomBates, randomUniform} from 'd3-random'
import { withTranslation } from 'react-i18next'

import GraphLine from './GraphLine';
import CheckBox from '../forms/CheckBox';

class GraphToggler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.keys.reduce((result, oKey) => {
        result[oKey.value] = this.props.defaultChecked.find((x) => x == oKey.value) != null;
        return result;
      }, {})
    };
  }

  onClick(key) {
    const keyStatus = this.props.keys.map((x) => this.state.checked[x.value])

    // Not allow a user to uncheck all checkboxes.
    if (keyStatus.filter((x) => x).length == 1 && this.state.checked[key.value]) {
      return;
    }

    this.setState((prevState, props) => ({
      checked: {
        ...prevState.checked,
        [key.value]: !prevState.checked[key.value],
      },
    }));
  }

  render() {
    const { t } = this.props
    const keys = [
      {
        value: 'created',
        label: t('GraphToggler.tasksCreated'),
        color: 0,
      },
      {
        value: 'completed',
        label: t('GraphToggler.tasksCompleted'),
        color: 1,
      },
    ]
    const filteredDataPack = this.props.dataPack.map((d, i) => {
      // Gather only data of active states.
      const filteredData = {
        data: keys.reduce((result, oKey) => {
          if (this.state.checked[oKey.value]) {
            result[oKey.value] = d.data[oKey.value]
          }
          return result;
        }, {}),
      };

      return Object.assign({}, d, filteredData);  // Replace only `data`, not `key`.
    })

    return (
      <React.Fragment>
        <GraphLine
          axisYLabel={this.props.axisYLabel}
          dataPack={filteredDataPack}
          enableArea={this.props.enableArea}
          autoColor={this.props.autoColor}
          keys={keys}
          graphToggler={(
            <div className={className('GraphToggler', {
            })}>
              <ul className='GraphToggler__checkboxes'>
                {keys.map((oKey, i) => (
                  <li
                    key={i}
                    onClick={(() => this.onClick(oKey)).bind(this)}
                    className='GraphToggler__checkbox'
                  >
                    <CheckBox
                      color={i}
                      isActive={this.state.checked[oKey.value]}
                    />
                    <span className={className('GraphToggler__label', {
                      [`GraphToggler__label--${i}`]: true,
                    })}>
                      {oKey.label}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        />
      </React.Fragment>
    );
  }
}

GraphToggler.propTypes = {
};

const keys = [
  {
    value: 'created',
    label: 'Tasks created',
    color: 0,
  },
  {
    value: 'completed',
    label: 'Tasks completed',
    color: 1,
  },
]

GraphToggler.defaultProps = {
  autoColor: false,
  enableArea: true,
  enableUserToggler: false,
  keys,
  defaultChecked: keys.map((x) => x.value),
  dataPack: reverse(Array.from(Array(7)).map((_, i) => ({
    key: moment().subtract(i, 'days').format('YYYY-MM-DD'),
    data: keys.map((x) => x.value).reduce((obj, x) => {
      return Object.defineProperty(obj, x, {
        value: ~~(randomBates(5)() * 10)
      })
    }, {}),
  }))),
};

export default withTranslation()(GraphToggler);
