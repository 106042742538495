import React from 'react'
import ImageIcon from '../../../../../assets/images/project-new-input-types-image.svg'
import TextIcon from '../../../../../assets/images/project-new-input-types-text.svg'

const ProjectNewCard = ({ name, desc, icon, value }) => {
  const renderIcon = (icon) => {
    if (icon.includes('text')) {
      return TextIcon
    }

    return ImageIcon
  }

  return (
    <label
      css={{
        width: '170px',
        borderRadius: '4px',
        border: '1px solid #eaebec',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '32px 20px 15px',
        cursor: 'pointer',
        transition: 'box-shadow 0.2s, background-color 0.2s'
      }}
      htmlFor={value}
    >
      <img
        src={renderIcon(icon)}
        css={{ display: 'block', marginBottom: '16px' }}
      />
      <p
        css={{
          textTransform: 'uppercase',
          textAlign: 'center',
          marginBottom: '8px',
          fontFamily: 'Montserrat',
          fontWeight: 'bold',
          fontSize: '12px',
        }}
      >
        {name}
      </p>
      <p
        css={{
          fontSize: '12px',
          textAlign: 'center',
        }}
      >
        {desc}
      </p>
    </label>
  )
}

export default ProjectNewCard
