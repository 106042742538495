import React from 'react'
import { get, startCase } from 'lodash'
import { withTranslation } from 'react-i18next'

import Modal from '@shared/Modal'
import { ModalH3 } from '@shared/Modal/Modal.style'

class ProjectTextPreview extends React.Component {
  state = {
    isModalOpened: false,
  }

  onToggleModal = (e) => {
    e.stopPropagation()

    this.setState((prevState) => ({
      isModalOpened: !prevState.isModalOpened,
    }))
  }

  render() {
    const { t, item } = this.props
    const { word_occurrence: wordOccurrence } = item

    return (
      <div className="ProjectTextPreview">
        <div className="ProjectTextPreview__info">
          <span className="ProjectTextPreview__info__text">
            {get(this.props, 'item.data')}
          </span>

          <a
            className="ProjectTextPreview__info__handle"
            onClick={this.onToggleModal}
          />
        </div>

        <Modal
          isActive={this.state.isModalOpened}
          toggleFunction={this.onToggleModal}
        >
          <ModalH3>
            {startCase(get(this.props, 'project.data.attributes.template'))}
          </ModalH3>
          <p className="Strike" css={{ marginBottom: '16px' }}>
            {get(this.props, 'project.data.attributes.name')}
          </p>

          <div className="ProjectTextPreview__text">
            <p>{get(this.props, 'item.data')}</p>
          </div>

          {wordOccurrence && (
            <div className="ProjectTextPreview__word-occurrence">
              <div className="ProjectTextPreview__word-occurrence__title Strike">
                {t('Dashboard.wordOccurrence')}
              </div>

              <div className="ProjectTextPreview__word-occurrence__grid">
                {Object.keys(wordOccurrence).map((key) => {
                  return (
                    <p key={`${key}_${wordOccurrence[key]}`}>
                      {key}: {wordOccurrence[key]}
                    </p>
                  )
                })}
              </div>
            </div>
          )}

          <div className="Modal__button">
            <a className="Button Button--green" onClick={this.onToggleModal}>
              {t('Misc.close')}
            </a>
          </div>
        </Modal>
      </div>
    )
  }
}

export default withTranslation()(ProjectTextPreview)
