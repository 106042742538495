import React from 'react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import Select from '@shared/forms/Select'
import GraphLine from '@shared/graphs/GraphLine'
import SectionLoader from '@shared/SectionLoader'

const TaskOverview = ({ dashboardChart, onChangeChartStatus, status }) => {
  const { t } = useTranslation()

  const dataPack = get(dashboardChart, 'data', []).map((x) => ({
    key: get(x, 'time'),
    data: {
      count: get(x, status, 0),
    },
  }))

  return (
    <div className="Central__content__section">
      <div className="GraphBase__header">
        <Select
          withBullet
          value={status}
          isLoading={dashboardChart.isLoading}
          onChange={onChangeChartStatus}
          label={t('Project.tasks')}
          options={[
            { label: t('Dashboard.createdTasks'), value: 'created' },
            {
              label: t('Dashboard.processingTasks'),
              value: 'processing',
            },
            { label: t('Dashboard.processedTasks'), value: 'processed' },
          ]}
        />
      </div>

      <SectionLoader
        isLoading={dashboardChart.isLoading}
        isLoaded={dashboardChart.isLoaded}
        isError={dashboardChart.isError}
        isEmpty={get(dashboardChart, 'data', []).length === 0}
      >
        <GraphLine dataPack={dataPack} />
      </SectionLoader>
    </div>
  )
}

export default TaskOverview
