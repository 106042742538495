import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import className from 'classnames';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div
        className={className('Modal', {
          'Modal--active': this.props.isActive,
        })}
      >
        <a
          className='Modal__overlay'
          onClick={this.props.toggleFunction}
        />

        <div className='Modal__panel'>
          <div
            className={className('Modal__panel__wrapper', {
              [`${this.props.klassName}`]: this.props.klassName,
            })}
          >

            <a
              className='Modal__panel__close'
              onClick={this.props.toggleFunction}
            />

            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
};

Modal.defaultProps = {
};

export default Modal;
