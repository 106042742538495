import React from 'react'
import { get } from 'lodash'
import { Trans } from 'react-i18next'
import styled from '@emotion/styled'

import { isOwner } from '@lib/helpers'

import SectionLoader from '@shared/SectionLoader'
import Pagination from '@shared/Pagination'
import Select from '@shared/forms/Select'
import ConfirmModal from '@shared/Modal/ConfirmModal'
import { Button } from '@shared/style'

import CloseIcon from '../../../../../assets/images/icon-bin.svg'
import PlaneIcon from '../../../../../assets/images/icon-plane.svg'

const UsersTable = (props) => {
  const {
    user,
    users = [],
    meta,
    onRemoveUser,
    onSelectUser,
    onChangeRole,
    onChangeOwner,
    onChangePage,
    onChangePerPage,
    onResentInvite,
    isLoaded,
    isLoading,
    isError,
    selectedUser,
    t,
  } = props

  const headers = [
    t('Settings.email'),
    t('UserManagement.role'),
    t('Misc.status'),
    'Send Invite',
    '',
    '',
  ]

  const [cfRemoveUser, setCfRemoveUser] = React.useState(false)
  const [cfChangeOwner, setCfChangeOwner] = React.useState(false)
  const [cfChangeRole, setCfChangeRole] = React.useState(false)
  const [cfResentInvite, setCfResentInvite] = React.useState(false)

  return (
    <React.Fragment>
      <SectionLoader
        isLoading={isLoading}
        isLoaded={isLoaded}
        isError={isError}
        isEmpty={users.length === 0}
        alwaysDisplayChildren
      >
        <div className="Table">
          <div className="Table__tr">
            {headers.map((header, i) => (
              <div key={i} className="Table__td Table__td--th">
                {header}
              </div>
            ))}
          </div>
          {users.map((u, i) => {
            const email = get(u, 'attributes.email')
            const role = get(u, 'attributes.role')
            const status = get(u, 'attributes.status')

            return (
              <div key={i} className="Table__tr">
                <div className="Table__td">{email}</div>
                {/* Role */}
                <div className="Table__td">
                  {email === get(user, 'data.attributes.email') ? (
                    <React.Fragment>
                      {t(`UserManagement.${role}`)}
                      <span css={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                        &nbsp;({t('Misc.me')})
                      </span>
                    </React.Fragment>
                  ) : (
                    <Select
                      withOutLabel
                      value={role}
                      options={[
                        { label: t('UserManagement.admin'), value: 'admin' },
                        {
                          label: t('UserManagement.user'),
                          value: 'user',
                        },
                      ]}
                      onChange={({ target: { value } }) => {
                        onSelectUser({ ...u, newRole: value })
                        setCfChangeRole(true)
                      }}
                    />
                  )}
                </div>
                {/* Status */}
                <div className="Table__td">
                  <P isPending={status === 'pending'}>
                    {t(`UserManagement.${status}`)}
                  </P>
                </div>
                {/* Resend Invite */}
                <div className="Table__td">
                  {status === 'pending' && (
                    <button
                      css={SendInviteButton}
                      onClick={() => {
                        onSelectUser(u)
                        setCfResentInvite(true)
                      }}
                    >
                      <img src={PlaneIcon} />
                      Resend
                    </button>
                  )}
                </div>
                {/* Make Owner */}
                <div className="Table__td">
                  {status !== 'pending' &&
                    role === 'admin' &&
                    isOwner(get(user, 'data.attributes.role')) && (
                      <p
                        css={{ textDecoration: 'underline' }}
                        onClick={() => {
                          onSelectUser(u)
                          setCfChangeOwner(true)
                        }}
                      >
                        Make Owner
                      </p>
                    )}
                </div>
                <div className="Table__td">
                  {!isOwner(role) &&
                    (get(user, 'data.attributes.email') !== email && (
                      <button
                        onClick={() => {
                          onSelectUser(u)
                          setCfRemoveUser(true)
                        }}
                        css={CloseIconStyle}
                      />
                    ))}
                </div>
              </div>
            )
          })}
        </div>
      </SectionLoader>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 'var(--global-space)',
        }}
      >
        <Select
          value={get(meta, 'per_page', '')}
          onChange={onChangePerPage}
          label={t('Misc.display')}
          name="perPage"
          id="perPage"
          options={[
            { label: '10', value: 10 },
            { label: '15', value: 15 },
            { label: '25', value: 25 },
            { label: '50', value: 50 },
          ]}
        />
        <Pagination
          total={meta.total}
          currentPage={meta.page}
          perPage={meta.per_page}
          onChangePage={onChangePage}
        />
      </div>
      <ConfirmModal
        isActive={cfRemoveUser}
        toggleFunction={() => setCfRemoveUser(false)}
        title={
          <Trans i18nKey="UserManagement.confirmRemoveDesc">
            Are you sure you want to remove
            <br />
            {{ name: get(selectedUser, 'attributes.email') }}
            <br />
            account?
          </Trans>
        }
        buttons={
          <React.Fragment>
            <Button onClick={() => setCfRemoveUser(false)}>
              {t('Misc.cancel')}
            </Button>
            <Button
              primary
              onClick={() => {
                onRemoveUser(selectedUser.id)
                setCfRemoveUser(false)
              }}
              disabled={isLoading}
            >
              {isLoading ? t('Misc.loading') : t('Misc.ok')}
            </Button>
          </React.Fragment>
        }
      />
      <ConfirmModal
        isActive={cfChangeOwner}
        toggleFunction={() => setCfChangeOwner(false)}
        title={
          <Trans i18nKey="UserManagement.confirmChangeOwnerDesc">
            Are you sure you want to change owner from
            <br />
            {{ old: get(user, 'data.attributes.email') }} to
            {{ new: get(selectedUser, 'attributes.email') }}?
          </Trans>
        }
        buttons={
          <React.Fragment>
            <Button onClick={() => setCfChangeOwner(false)}>
              {t('Misc.cancel')}
            </Button>
            <Button
              primary
              onClick={() => {
                onChangeOwner(selectedUser.id)
                setCfChangeOwner(false)
              }}
              disabled={isLoading}
            >
              {isLoading ? t('Misc.loading') : t('Misc.ok')}
            </Button>
          </React.Fragment>
        }
      />
      <ConfirmModal
        isActive={cfResentInvite}
        toggleFunction={() => setCfResentInvite(false)}
        title={
          <Trans i18nKey="UserManagement.confirmResentInviteDesc">
            Are you sure you want to reinvite
            <br />
            {{ name: get(selectedUser, 'attributes.email') }}?
          </Trans>
        }
        buttons={
          <React.Fragment>
            <Button onClick={() => setCfResentInvite(false)}>
              {t('Misc.cancel')}
            </Button>
            <Button
              primary
              onClick={() => {
                onResentInvite(selectedUser.id)
                setCfResentInvite(false)
              }}
              disabled={isLoading}
            >
              {isLoading ? t('Misc.loading') : t('Misc.ok')}
            </Button>
          </React.Fragment>
        }
      />
      <ConfirmModal
        isActive={cfChangeRole}
        toggleFunction={() => setCfChangeRole(false)}
        title={
          <Trans i18nKey="UserManagement.confirmChangeRoleDesc">
            Are you sure you want to change role
            <br />
            {{ name: get(selectedUser, 'attributes.email') }} from{' '}
            {{ old: get(selectedUser, 'attributes.role') }} to{' '}
            {{ new: get(selectedUser, 'newRole') }}?
          </Trans>
        }
        buttons={
          <React.Fragment>
            <Button onClick={() => setCfChangeRole(false)}>
              {t('Misc.cancel')}
            </Button>
            <Button
              primary
              onClick={() => {
                onChangeRole(selectedUser.id, selectedUser.newRole)
                setCfChangeRole(false)
              }}
              disabled={isLoading}
            >
              {isLoading ? t('Misc.loading') : t('Misc.ok')}
            </Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}

const CloseIconStyle = {
  border: 'none',
  outline: 'none',
  width: '20px',
  height: '20px',
  backgroundImage: `url(${CloseIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  cursor: 'pointer',
}

const SendInviteButton = {
  width: '88px',
  display: 'flex',
  padding: '7px 10px',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#428dfc',
  borderRadius: '4px',
  color: 'var(--c-white)',
  cursor: 'pointer',
}

const P = styled.p`
  color: ${({ isPending }) => (isPending ? 'var(--c-yellow)' : '')};
`

export default UsersTable
