import React from 'react'

import Modal from '@shared/Modal'
import { ModalH3, ModalMessage, ModalButtons } from './Modal.style'

const ConfirmModal = ({ toggleFunction, isActive, title, message, buttons}) => (
  <Modal toggleFunction={toggleFunction} isActive={isActive}>
    <ModalH3>{title}</ModalH3>
    <ModalMessage>{message}</ModalMessage>
    <ModalButtons>
      {buttons}
    </ModalButtons>
  </Modal>
)

export default ConfirmModal
