import React from 'react'
import PropTypes from 'prop-types'

import Modal from '@shared/Modal'

const SuccessModal = ({
  toggleFunction,
  icon,
  title,
  subtitle,
  isActive,
}) => (
  <Modal
    toggleFunction={toggleFunction}
    isActive={isActive}
  >
    {icon && (
      <div className="Modal__icon">
        {icon}
      </div>
    )}
    <h3 className="Modal__h3">{title}</h3>
    <div className="Modal__message">{subtitle}</div>
  </Modal>
)

SuccessModal.propsType = {
  toggleFunction: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

export default SuccessModal
