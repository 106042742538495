import React from 'react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import Endpoint from '@layouts/projects/ApiExplorer/Endpoint'
import { ProjectSection, ProjectSubHeader } from '@shared/style/Project.style'
import { Input } from '@shared/style'

const ApiList = ({ onChange, onSubmitPost, onSubmitGet, form, project }) => {
  const postApiArray = [
    {
      field: 'Authorization',
      input: (
        <Input
          value={get(project, 'data.attributes.license_key', '')}
          readOnly
          disabled
        />
      ),
      desc: 'Project Authorization Token',
      dataType: 'string',
    },
    {
      field: 'data',
      input: (
        <Input
          name="data"
          id="data"
          onChange={onChange}
          value={form.data}
          required
          placeholder="Required"
        />
      ),
      desc: 'data',
      dataType: 'string',
    },
    {
      field: 'custom_id',
      input: (
        <Input
          name="custom_id"
          id="custom_id"
          onChange={onChange}
          value={form.custom_id}
        />
      ),
      desc: 'custom_id',
      dataType: 'string',
    },
    {
      field: 'postback_method',
      input: (
        <Input
          name="postback_method"
          id="postback_method"
          onChange={onChange}
          value={form.postback_method}
        />
      ),
      desc: 'postback_method',
      dataType: 'string',
    },
    {
      field: 'postback_url',
      input: (
        <Input
          name="postback_url"
          id="postback_url"
          onChange={onChange}
          value={form.postback_url}
        />
      ),
      desc: 'postback_url',
      dataType: 'string',
    },
  ]

  const getApiArray = [
    {
      field: 'Authorization',
      input: (
        <Input
          value={get(project, 'data.attributes.license_key', '')}
          readOnly
          disabled
        />
      ),
      desc: 'Project Authorization Token',
      dataType: 'string',
    },
    {
      field: 'query',
      input: (
        <Input
          name="query"
          id="query"
          onChange={onChange}
          value={form.query}
          required
          placeholder="Required"
        />
      ),
      desc: 'query',
      dataType: 'string',
    },
  ]

  const [expanded, setExpanded] = React.useState()
  const { t } = useTranslation()

  return (
    <ProjectSection>
      <ProjectSubHeader>{t('ApiExplorer.endpointList')}</ProjectSubHeader>
      <div
        css={{
          '> :not(:first-of-type)': {
            marginTop: '20px',
          },
        }}
      >
        <Endpoint
          arrayData={getApiArray}
          header="Get"
          headerDesc="" // Wait for Wording
          onSubmit={onSubmitGet}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <Endpoint
          arrayData={postApiArray}
          header="Post"
          headerDesc="" // Wait for Wording
          onSubmit={onSubmitPost}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </div>
    </ProjectSection>
  )
}

export default ApiList
