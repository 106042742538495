import React from 'react'
import { get } from 'lodash'
import { Helmet } from 'react-helmet'

const withHeader = (header) => (PageComponent) => {
  const EnhancedComponent = (props) => {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {get(header, 'title') || 'Customer Dashboard - Posmoni'}
          </title>
        </Helmet>
        <PageComponent {...props} />
      </React.Fragment>
    )
  }

  return EnhancedComponent
}

export default withHeader
