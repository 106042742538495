import React from 'react'
import { get, first, flowRight as compose } from 'lodash'
import className from 'classnames'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import {
  addNotification,
  getProject,
  loadProjectReport,
  updateProjectAutoRefillCredit,
} from '@redux-action'

import DateSelectorRange from '@shared/forms/DateSelectorRange'
import Modal from '@shared/Modal'
import Notification from '@shared/Notification'
import ButtonSubmit from '@shared/forms/ButtonSubmit'
import ProjectChart from './project/ProjectChart'
import ProjectApiTable from './project/ProjectApiTable'
import ProjectApiTableNg from './project/ProjectApiTableNg'
import ProjectApiTableImageBulkCheck from './project/ProjectApiTableImageBulkCheck'

const reportFormat = ['CSV', 'PDF']

class Project extends React.Component {
  state = {
    reportFormat: first(reportFormat),
    isTutorialActive: false,
    modals: {
      downloadReport: false,
      autoRefill: false,
    },
    form: {
      autoRefillCredit: 0,
      creditRefill: 0,
      autoRefill: false,
    },
  }

  $license = React.createRef()

  componentDidUpdate = (prevProps) => {
    if (
      get(prevProps, 'project.data.auto_refill_credit') !==
        get(this.props, 'project.data.auto_refill_credit') ||
      get(prevProps, 'project.data.credit_refill') !==
        get(this.props, 'project.data.credit_refill')
    ) {
      // When credit less than
      const autoRefillCredit = get(
        this.props,
        'project.data.auto_refill_credit'
      )
      // Auto refill this amount
      const creditRefill = get(this.props, 'project.data.credit_refill')
      const autoRefill = get(this.props, 'project.data.auto_refill')

      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          autoRefillCredit,
          creditRefill,
          autoRefill,
        },
      }))
    }
  }

  onToggleModal = (modal) => (e) => {
    this.setState((prevState, props) => ({
      modals: {
        ...prevState.modals,
        [modal]: !prevState.modals[modal],
      },
    }))
  }

  onChangeReportFormat = (reportFormat) => () => {
    this.setState({
      reportFormat,
    })
  }

  _autoRefillCallback = (res) => {
    const { t } = this.props

    if (get(res, 'type', '').endsWith('_SUCCESS')) {
      const autoRefill = get(res, 'payload.data.data.auto_refill')

      this.props.addNotification({
        message: autoRefill
          ? t('Project.autoRefillEnabled')
          : t('Project.autoRefillDisabled'),
        type: Notification.NOTIFICATION_TYPES.success,
      })

      if (get(this.state, 'modals.autoRefill')) {
        this.setState(
          {
            form: {
              autoRefillCredit: '',
              creditRefill: '',
            },
          },
          this.onToggleModal('autoRefill')
        )
      }
    }
  }

  onToggleAutoRefill = () => {
    if (get(this.props, 'project.data.auto_refill')) {
      this.props
        .updateProjectAutoRefillCredit({
          id: get(this.props, 'project.data.id'),
          auto_refill: false,
        })
        .then(this._autoRefillCallback)
    } else {
      this.onToggleModal('autoRefill')()
    }
  }

  onUpdateAutoRefillCredit = (e) => {
    e.preventDefault()

    this.props
      .updateProjectAutoRefillCredit({
        id: get(this.props, 'project.data.id'),
        auto_refill: true,
        auto_refill_credit: get(this.state, 'form.autoRefillCredit'),
        credit_refill: get(this.state, 'form.creditRefill'),
      })
      .then(this._autoRefillCallback)
  }

  onClickDownload = () => {
    this.props
      .loadProjectReport({
        id: get(this.props, 'project.data.id'),
        file_type: get(this.state, 'reportFormat').toLowerCase(),
        start_date: get(this.props, 'datePicker.startDate'),
        end_date: get(this.props, 'datePicker.endDate'),
      })
      .then((res) => {
        if (get(res, 'type').endsWith('_SUCCESS')) {
          const reportFileUrl = get(res, 'payload.data.data')

          if (reportFileUrl) {
            window.location = reportFileUrl
          }
        }
      })
  }

  render() {
    const { t, project } = this.props

    const template = get(project, 'data.attributes.template')

    return (
      <React.Fragment>
        <div className="Central__content">
          <div className="Central__content__header">
            <h3 className="Central__content__h3">{t('Project.detail')}</h3>
          </div>

          <div className="Project__detail Project__detail--summary-layout">
            <div className="Item">
              <div className="Item__wrapper">
                <label className="Item__title">
                  {t('Project.remainingTasks')}
                </label>
                <p className="Item__content">
                  <strong className="Item__content--bold">
                    {get(
                      this.props,
                      'project.data.attributes.remaining_tasks',
                      0
                    ).toLocaleString()}
                  </strong>
                  &nbsp;
                  {t('Project.tasks')}
                </p>
              </div>
            </div>
            <div className="Item">
              <div className="Item__wrapper">
                <label className="Item__title">
                  {t('Project.creditPerTask')}
                </label>
                <p className="Item__content">
                  <strong className="Item__content--bold">
                    {get(
                      this.props,
                      'project.data.attributes.credit_rate',
                      0
                    ).toLocaleString()}
                  </strong>
                  &nbsp;
                  {t('Project.credits')}
                </p>
              </div>
            </div>
          </div>
          <div className="Project">
            <div className="Central__content__header Project__header">
              <h3 className="Central__content__h3 Project__content-title">
                {t('Project.tasksProcessed')}
              </h3>
            </div>
            <ProjectChart match={get(this.props, 'match')} />

            {template === 'text_ai_ng' ? (
              <ProjectApiTableNg match={get(this.props, 'match')} />
            ) : template === 'image_bulk_check' ? (
              <ProjectApiTableImageBulkCheck match={get(this.props, 'match')} />
            ) : (
              <ProjectApiTable match={get(this.props, 'match')} />
            )}
          </div>
        </div>

        <Modal
          isActive={get(this.state, 'modals.downloadReport')}
          toggleFunction={this.onToggleModal('downloadReport')}
        >
          <h3 className="Modal__h3">{t('Project.downloadReport')}</h3>
          <ul className="Modal__choice">
            {reportFormat.map((format, i) => (
              <li key={i}>
                <a
                  onClick={this.onChangeReportFormat(format)}
                  className={className(
                    'Modal__choice__a',
                    `Modal__choice__a--${format.toLowerCase()}`,
                    {
                      'Modal__choice__a--active':
                        this.state.reportFormat === format,
                    }
                  )}
                >
                  {format}
                </a>
              </li>
            ))}
          </ul>

          <p className="Strike">{t('Misc.date')}</p>
          <DateSelectorRange withIcon />
          <div className="Modal__button">
            <a onClick={this.onClickDownload} className="Button Button--green">
              {t('Misc.download')}
            </a>
          </div>
        </Modal>

        <Modal
          isActive={get(this.state, 'modals.autoRefill')}
          toggleFunction={this.onToggleModal('autoRefill')}
        >
          <h3 className="Modal__h3">{t('Credits.autoRefillCredit')}</h3>
          <form onSubmit={this.onUpdateAutoRefillCredit}>
            <div className="AutoRefill">
              <div className="BuyCredits">
                <input
                  type="number"
                  className="Input"
                  placeholder={t('Credits.creditLessThan')}
                  required
                  min="1"
                  value={get(this.state, 'form.autoRefillCredit', 0)}
                  onChange={(e) => {
                    const autoRefillCredit = e.target.value

                    this.setState((prevState) => ({
                      form: {
                        ...prevState.form,
                        autoRefillCredit,
                      },
                    }))
                  }}
                />
                <p className="Strike Strike--padded">
                  {t('Credits.whenCreditLessThan')}
                </p>
                <p className="BuyCredits__p">
                  ¥
                  {(~~get(
                    this.state,
                    'form.autoRefillCredit',
                    0
                  )).toLocaleString()}
                </p>
              </div>
              <div className="BuyCredits">
                <input
                  type="number"
                  className="Input"
                  placeholder={t('Credits.autoRefill')}
                  required
                  min="1"
                  value={get(this.state, 'form.creditRefill', 0)}
                  onChange={(e) => {
                    const creditRefill = e.target.value

                    this.setState((prevState) => ({
                      form: {
                        ...prevState.form,
                        creditRefill,
                      },
                    }))
                  }}
                />
                <p className="Strike">{t('Credits.autoRefillFor')}</p>
                <p className="BuyCredits__p">
                  ¥
                  {(~~(
                    get(this.state, 'form.creditRefill') || 0
                  )).toLocaleString()}
                </p>
              </div>
            </div>
            <div className="Modal__button">
              <ButtonSubmit
                label={t('Misc.submit')}
                isLoading={get(this.props, 'project.isLoading')}
              />
            </div>
          </form>
        </Modal>
        <div
          className={className('TutorialPanel', {
            'TutorialPanel--active': this.state.isTutorialActive,
          })}
        >
          <h2 className="TutorialPanel__h2">{t('Project.tutorial1')}</h2>
          <p className="TutorialPanel__p">
            {t('Project.tutorial2')}
            <a
              className="Button Button--white"
              onClick={() => {
                this.setState({ isTutorialActive: false })
              }}
            >
              {t('Project.clickToSetup')}
            </a>
          </p>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  project: getProject(state),
})

const mapDispatchToProps = (dispatch) => ({
  addNotification: (params) => dispatch(addNotification(params)),
  loadProjectReport: (params) => dispatch(loadProjectReport(params)),
  updateProjectAutoRefillCredit: (params) =>
    dispatch(updateProjectAutoRefillCredit(params)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Project)
