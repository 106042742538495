import React from 'react'
import { get, flowRight as compose } from 'lodash'
import { connect } from 'react-redux'
import * as Cookies from 'js-cookie'
import { Trans, useTranslation } from 'react-i18next'
import qs from 'query-string'

import { getLoginAuth, createLoginSignup, addNotification } from '@redux-action'
import { COOKIES_KEY, COOKIES_KEY_NOTIFICATION, fireConversion } from '@helpers'
import { axiosClients } from '@lib/axiosClient'

import Landing from '@layouts/Landing'
import ButtonSubmit from '@shared/forms/ButtonSubmit'
import Notification from '@shared/Notification'
import withHeader from '@features/withHeader'

const SignUp = (props) => {
  const { addNotification, createLoginSignup, loginAuth, location } = props
  const instance = axiosClients.defaultV1.client

  const [form, setForm] = React.useState({
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [invitationToken, setInvitationToken] = React.useState('')

  React.useEffect(() => {
    if (!location.search) {
      return
    }

    const { token } = qs.parse(location.search)
    setInvitationToken(token)
    instance
      .get(`/v1/users/invitations?invitation_token=${token}`)
      .then((res) => {
        if (res.status === 200) {
          const email = get(res, 'data.data.attributes.email')
          if (email) {
            setForm((form) => ({ ...form, email }))
          }
        }
      })
      .catch(() => {
        addNotification({
          message: t('Signup.invalidToken'),
          type: Notification.NOTIFICATION_TYPES.error,
        })
        window.setTimeout(() => {
          window.location = '/'
        }, 1000)
      })
  }, [])

  const { t } = useTranslation()
  const confirmPassRef = React.useRef()

  const setCookies = (token) => {
    Cookies.set(COOKIES_KEY, token)
    Cookies.set(
      COOKIES_KEY_NOTIFICATION,
      JSON.stringify({
        message: t('Signup.registrationSuccess'),
        type: Notification.NOTIFICATION_TYPES.success,
      })
    )

    window.location = '/app' // Traditional route change to reload application, allowing an app to be aware of a new cookies.
  }

  const onChange = ({ target: { name, value } }) => {
    setForm((form) => ({ ...form, [name]: value }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const { email, password, confirmPassword } = form

    if (password !== confirmPassword) {
      confirmPassRef.current.select()
      return addNotification({
        message: t('Signup.passwordMismatch'),
        type: Notification.NOTIFICATION_TYPES.warning,
      })
    }

    if (location.search) {
      instance
        .post(`/v1/users/auth/sign_up/${invitationToken}`, { password })
        .then((res) => {
          if (res.status === 201) {
            const token = get(res, 'data.data.attributes.access_token')

            if (token) {
              setCookies(token)
            }
          }
        })
    } else {
      createLoginSignup({
        email,
        password,
      }).then((res) => {
        if (get(res, 'type', '').endsWith('_SUCCESS')) {
          const token = get(res, 'payload.data.data.attributes.access_token')

          if (token) {
            if (window.gon.ENVIRONMENT === 'production') {
              fireConversion()
            }
            setCookies(token)
          }
        }
      })
    }
  }

  return (
    <Landing>
      <form className="Landing__form Landing__form--narrow" onSubmit={onSubmit}>
        <h2 className="Landing__form__h2">{t('Signup.signup')}</h2>

        <label className="Label">{t('Signup.email')}</label>
        <input
          label="Email"
          type="text"
          className="Input"
          placeholder={t('Signup.enterYourEmail')}
          name="email"
          value={form.email}
          onChange={onChange}
          autoComplete="username"
          disabled={location.search}
        />

        <label className="Label">{t('Signup.password')}</label>
        <input
          type="password"
          className="Input"
          placeholder={t('Signup.min8Chars')}
          name="password"
          value={form.password}
          onChange={onChange}
          autoComplete="new-password"
        />

        <label className="Label">{t('Signup.confirmPassword')}</label>
        <input
          type="password"
          className="Input"
          placeholder={t('Signup.confirmPassword')}
          name="confirmPassword"
          value={form.confirmPassword}
          ref={confirmPassRef}
          onChange={onChange}
          autoComplete="new-password"
        />

        <p>
          <Trans i18nKey="Signup.acceptTerm">
            By clicking sign up, you are indicating that you agree to the&nbsp;
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href="https://posmoni.com/privacy"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href="https://posmoni.com/terms"
            >
              terms.
            </a>
          </Trans>
        </p>

        <ButtonSubmit
          label={`${t('Signup.signupButton')}`}
          isLoading={loginAuth.isLoading}
        />
      </form>
    </Landing>
  )
}

const mapState = (state) => ({
  loginAuth: getLoginAuth(state),
})

export default compose(
  withHeader({ title: 'Customer Login - Posmoni' }),
  connect(
    mapState,
    { addNotification, createLoginSignup }
  )
)(SignUp)
