import React, { Fragment, cloneElement } from 'react'
import { useLayer, useHover } from 'react-laag'
import { motion, AnimatePresence } from 'framer-motion'

export const Tooltip = (props) => {
  const { children, text } = props

  const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 300 })

  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement: 'top-center',
    triggerOffset: 8,
    auto: true,
  })

  let trigger
  if (isReactText(children)) {
    trigger = (
      <span className="tooltip-text-wrapper" {...triggerProps} {...hoverProps}>
        {children}
      </span>
    )
  } else {
    trigger = cloneElement(children, {
      ...triggerProps,
      ...hoverProps,
    })
  }

  return (
    <Fragment>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {isOver && (
            <motion.div
              css={{
                background: '#3d3d3d',
                borderRadius: '4px',
                color: 'white',
                padding: '8px',
              }}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1 }}
              {...layerProps}
            >
              {text}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Fragment>
  )
}

function isReactText(children) {
  return ['string', 'number'].includes(typeof children)
}
