import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const HeaderStyle = {
  padding: '12px 16px',
  backgroundColor: '#fafbfc',
  fontFamily: 'Montserrat',
  fontSize: '12px',
  fontWeight: 'bold',
  letterSpacing: '0.5px',
  display: 'grid',
  gridTemplateColumns: '40px 1fr',
  columnGap: '8px',
  position: 'relative',
  cursor: 'pointer',
}
export const SectionStyle = {
  display: 'grid',
  gridTemplateColumns: 'max-content 1fr',
  gap: '12px',
  alignItems: 'center',
  fontSize: '14px',
  input: {
    gridColumn: '1 / -1',
    marginTop: '12px',
  },
  '&:not(:last-of-type)': {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #e8e9ea',
  },
}
export const BadgeStyle = {
  padding: '5px 12px',
  borderRadius: '30px',
  backgroundColor: 'rgba(70, 215, 206, 0.2);',
  color: 'var(--c-green)',
  textTransform: 'uppercase',
  justifySelf: 'start',
  fontSize: '12px',
}

export const Triangle = styled.span`
  position: absolute;
  width: 0;
  height: 0;
  border: 5px solid black;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: 2.5px 5px;
  transition: transform 0.3s;

  ${props => {
    if (props.isOpen) {
      return css`
        transform: translateY(-50%) rotate(90deg);
      `
    }
  }}
`
