import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { multiClientMiddleware } from 'redux-axios-middleware'
import { Provider } from 'react-redux'
import { Elements, StripeProvider } from 'react-stripe-elements'

import reducers from '../lib/redux/reducers'
import { RouteWithSubRoutes } from '@helpers'
import { axiosClients } from '@lib/axiosClient'
import '../lib/i18n'

import Home from '../components/pages/Home'
import SignUp from '../components/pages/users/SignUp'
import Main from '../components/layouts/Main'
import Dashboard from '../components/pages/Dashboard'
import Projects from '../components/pages/projects/Projects'
import Project from '../components/pages/projects/Project'
import ProjectNew from '../components/pages/projects/ProjectNew'
import ProjectNgWords from '../components/pages/projects/ProjectNgWords'
import CreditsStripe from '../components/pages/CreditsStripe'
import PaymentMethods from '../components/pages/PaymentMethods'
import PaymentMethodsNewStripe from '../components/pages/PaymentMethodsNewStripe'
import Settings from '../components/pages/Settings'
import Api from '../components/pages/Api'
import ProjectLayout from '../components/layouts/ProjectLayout'
import ProjectSetting from '../components/pages/projects/ProjectSetting'
import ApiExplorer from '../components/pages/projects/ApiExplorer'
import UserManagement from '../components/pages/UserManagement'
import ActionLog from '../components/pages/ActionLog'

if (window.Omise) {
  window.Omise.setPublicKey(window.gon.OMISE_PKEY)
}

const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunk, multiClientMiddleware(axiosClients)),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
)

const Credits = () => (
  <StripeProvider apiKey={window.gon.STRIPE_PKEY}>
    <Elements>
      <CreditsStripe />
    </Elements>
  </StripeProvider>
)

const PaymentMethodsNew = () => (
  <StripeProvider apiKey={window.gon.STRIPE_PKEY}>
    <Elements>
      <PaymentMethodsNewStripe />
    </Elements>
  </StripeProvider>
)

const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/sign-up',
    component: SignUp,
    exact: true,
  },
  {
    path: '/register',
    component: SignUp,
    exact: true,
  },
  // {
  //   path: '/password-reset',
  //   component: PasswordReset,
  //   exact: true,
  // },
  // {
  //   path: '/password-reset-sent',
  //   component: PasswordResetSent,
  //   exact: true,
  // },
  // {
  //   path: '/password-new',
  //   redirectTo: '/',
  //   exact: true,
  // },
  // {
  //   path: '/password-new/:token',
  //   component: PasswordNew,
  //   exact: true,
  // },
  // {
  //   path: '/password-change',
  //   component: PasswordChange,
  //   exact: true,
  // },
  {
    path: '/app/projects/new',
    component: ProjectNew,
    exact: true,
  },
  {
    path: '/app',
    component: Main,
    routes: [
      {
        path: '/app',
        component: Dashboard,
        exact: true,
      },
      {
        path: '/app/projects',
        component: Projects,
        exact: true,
      },
      {
        path: '/app/projects/:id',
        component: ProjectLayout,
        routes: [
          {
            path: '/app/projects/:id/summary',
            component: Project,
            exact: true,
          },
          {
            path: '/app/projects/:id/setting',
            component: ProjectSetting,
            exact: true,
          },
          {
            path: '/app/projects/:id/api',
            component: ApiExplorer,
            exact: true,
          },
          {
            path: '/app/projects/:id/ng-words',
            component: ProjectNgWords,
            exact: true,
          },
        ],
      },
      {
        path: '/app/billings',
        redirectTo: '/app/billings/credits',
        exact: true,
      },
      {
        path: '/app/billings/credits',
        component: Credits,
        exact: true,
      },
      {
        path: '/app/billings/payment-methods',
        component: PaymentMethods,
        exact: true,
      },
      {
        path: '/app/billings/payment-methods/new',
        component: PaymentMethodsNew,
        exact: true,
      },
      {
        path: '/app/settings',
        component: Settings,
        exact: true,
      },
      {
        path: '/app/users',
        component: UserManagement,
        exact: true,
      },
      {
        path: '/app/logs',
        component: ActionLog,
        exact: true,
      },
      {
        path: '/app/api',
        component: Api,
        exact: true,
      },
    ],
  },
]

class App extends React.Component {
  render() {
    return (
      <React.Suspense fallback={<Loader />}>
        <Provider store={store}>
          <Router>
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </Router>
        </Provider>
      </React.Suspense>
    )
  }
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <App />,
    document.body.appendChild(document.createElement('root'))
  )
})

const Loader = () => <div>loading...</div>
