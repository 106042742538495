import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Button } from '@shared/style'

import dataIcon from '../../../../../../assets/images/project-input/data.svg'

const Container = styled.div`
  background-image: linear-gradient(81deg, #434a9d, #68b8c0);
  border-radius: 4px;
  padding: calc(var(--global-space) * 0.6) calc(var(--global-space) * 0.8);
  color: #fff;
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: calc(var(--global-space) * 0.75);
`

const ProjectSettingBanner = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <div css={{ display: 'flex', flexFlow: 'column' }}>
        <div
          css={{
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            fontSize: '18px',
            letterSpacing: '1.8px',
            textTransform: 'uppercase',
            margin: 'calc(var(--global-space) * 0.6) 0',
          }}
        >
          {t('ProjectSetting.howToImplementWebhook')}
        </div>
        <div>{t('ProjectSetting.howToDesc')}</div>
        <a
          rel="noopener noreferrer nofollow"
          target="_blank"
          href="https://help.posmoni.com/ja/"
          css={{ width: '136px' }}
        >
          <Button
            primary
            css={{
              marginTop: 'calc(var(--global-space) * 1)',
              alignSelf: 'flex-start',
            }}
          >
            {t('Misc.learnMore')}
          </Button>
        </a>
      </div>
      <img
        src={dataIcon}
        css={{
          width: '136px',
          height: '119px',
          display: 'block',
          alignSelf: 'end',
          margin: '0 var(--global-space)',
          transform: 'translateY(12px)',
        }}
      />
    </Container>
  )
}

export default ProjectSettingBanner
