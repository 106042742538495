import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import {
  getProjectNew,
  getProjectTemplates,
  loadProjectTemplates,
  createProject,
  addNotification,
} from '@redux-action'

import ProjectNewHeader from '@layouts/projects/ProjectNew/ProjectNewHeader'
import ProjectNewForm from '@layouts/projects/ProjectNew/ProjectNewForm'

const ProjectNew = ({
  projectNew,
  projectTemplates,
  loadProjectTemplates,
  createProject,
  history
}) => {
  const [form, setForm] = React.useState({
    template: '',
    postbackMethod: 'GET',
    postbackUrl: '',
  })
  const [currentStep, setCurrentStep] = React.useState(0)
  const [transValue, setTransValue] = React.useState(0)

  React.useEffect(() => {
    loadProjectTemplates()
  }, [])

  React.useEffect(() => {
    setTransValue(currentStep * -110)
  }, [currentStep])

  const onChange = ({ target: { name, value } }) =>
    setForm((form) => ({ ...form, [name]: value }))

  const onSubmit = (e) => {
    e.preventDefault()

    createProject({
      template: form.template,
      postback_method: form.postbackMethod,
      postback_url: form.postbackUrl,
    }).then((res) => {
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        const id = get(res, 'payload.data.data.id')
        return history.push(`/app/projects/${id}/setting`)
      }

      history.push('/app/projects')
    })
  }

  return (
    <div
      css={{
        display: 'grid',
        minHeight: '100vh',
        gridTemplateRows: 'min-content 1fr',
        rowGap: '60px',
        background: '#f7f8f9',
        overflowX: 'hidden',
      }}
    >
      <ProjectNewHeader currentStep={currentStep} isLoading={projectNew.isLoading} />
      <ProjectNewForm
        form={form}
        onChange={onChange}
        onSubmit={onSubmit}
        currentStep={currentStep}
        transformValue={transValue}
        setCurrentStep={setCurrentStep}
        templates={projectTemplates.data}
        isLoading={projectNew.isLoading}
      />
    </div>
  )
}


const mapState = (state) => ({
  projectTemplates: getProjectTemplates(state),
  projectNew: getProjectNew(state),
})

export default connect(
  mapState,
  { loadProjectTemplates, createProject, addNotification }
)(ProjectNew)
