import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import className from 'classnames';

class Search extends React.Component {
  static propTypes = {
  };

  static defaultProps = {
    onChange: () => {},
  };

  state = {
  };

  render() {
    return (
      <div className='Search'>
        <input
          type='text'
          className='Search__input'
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default Search;
