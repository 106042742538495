import React from 'react'
import className from 'classnames'
import { Link } from 'react-router-dom'

import Code from '../shared/Code'
import Notification from '../shared/Notification'
import withHeader from '@features/withHeader'

class Api extends React.Component {
  static propTypes = {}

  static defaultProps = {}

  state = {}

  render() {
    return (
      <div className="Api">
        <div className="Api__header">
          <div className="Api__header__top">
            <div className="Api__container">
              <h2 className="Api__h2">
                API setup
                <Link
                  className="Api__h2__a"
                  to="/app/projects"
                >
                  Back to projects
                </Link>
              </h2>
            </div>
          </div>

          <div className="Api__header__bottom">
            <div className="Api__container">
              <p className="Api__header__bottom__p">
                Project type:{' '}
                <span className="WithDot">
                  <strong>Image – Nudity human</strong>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div
          className="Api__content"
          ref={this.$content}
        >
          <div className="Api__container">
            <div className="Api__content__section">
              <h3 className="Api__content__h3">
                Select a use case that suits you
              </h3>

              {(() => {
                const data = [
                  'Sexy photos allow but not for nude image',
                  'Ban all sexy photos',
                  'Blah blah blah',
                ]

                return (
                  <ul className="Radios">
                    {data.map((x, i) => (
                      <li key={i}>
                        <label
                          className={className('Radios__label', {
                            'Radios__label--without-icon': true,
                          })}
                        >
                          <input
                            type="radio"
                            name="method"
                            defaultChecked={i == 0}
                          />
                          <i className="Radios__label__i" />
                          <span className="Radios__label__span">{x}</span>
                          <div className="Radios__label__bg" />
                        </label>
                      </li>
                    ))}
                  </ul>
                )
              })()}
            </div>

            <div className="Api__content__section">
              <h3 className="Api__content__h3">Select a language</h3>

              {(() => {
                const data = [
                  { label: 'Ruby', type: 'ruby' },
                  { label: 'PHP', type: 'php' },
                  { label: 'Python', type: 'python' },
                  { label: 'Node', type: 'node' },
                  { label: 'Custom URL', type: 'custom' },
                ]

                return (
                  <ul className="ProjectNew__project-types ProjectNew__project-types--5">
                    {data.map((x, i) => (
                      <li key={i}>
                        <label className="ProjectNew__project-types__label">
                          <input
                            type="radio"
                            name="project_type"
                            value={i}
                            defaultChecked={i == 0}
                          />
                          <div
                            className={className(
                              'ProjectNew__project-types__label__wrapper',
                              {
                                'ProjectNew__project-types__label__wrapper--compact': true,
                                [`ProjectNew__project-types__label__wrapper--lang-${x.type}`]: true,
                              }
                            )}
                          >
                            <p className="ProjectNew__project-types__label__title">
                              {x.label}
                            </p>
                          </div>
                        </label>
                      </li>
                    ))}
                  </ul>
                )
              })()}

              <P>
                KSequencing 0.1.25 works with Rails 4.1 onwards. You can add it
                to your Gemfile with:
              </P>

              <Code>gem 'k_sequencing', '~> 0.1.25'</Code>

              <P>Then run bundle install.</P>

              <P>Next, you need to run the generator:</P>

              <Code>$ rails generate k_sequencing:install</Code>

              <P>
                The generator will install an initializer which describes ALL of
                KSequencing's configuration options. It is imperative that you
                take a look at it. When you are done, you are ready to add
                KSqeuencing to any of your codes.
              </P>

              <P>Copy your project key, then paste in your library.</P>

              <Code>
                3456uhbvdr6789okjhgt567ujhvcder6ujhbvcd456yhbvcdrtyhbvcdthbvcd
              </Code>

              <P>To start project, run this script.</P>

              <Code>KSequencing.client.find_image(id)</Code>
            </div>

            <div className="Api__content__section">
              <h3 className="Api__content__h3">Postback config</h3>

              <div className="Api__content__postback">
                <p className="Api__content__p">Postback URL</p>
                <select className="SelectForm">
                  <option>Get</option>
                </select>
                <input
                  type="text"
                  className="Input"
                />
              </div>

              <P>Client can check whether request is successful by:</P>

              <Code withOutCopy>{code01}</Code>

              <P>For example.</P>

              <Code>{code02}</Code>

              <Code withOutCopy>{code03}</Code>
            </div>

            <div className="Api__content__buttons">
              <Link
                to="/app/projects"
                className="Button Button--gray"
              >
                Back to projects
              </Link>
            </div>
          </div>
        </div>

        <Notification />
      </div>
    )
  }
}

const code01 = `response = KSequencing.client.find_image("5a40be59fb9d7f27354c5efa")
if response.successful?
  # Do stuff
else
  log.error("Request was not successful, something went wrong.")
end`

const code02 = `KSequencing::Response @status=200, @message="success"
@meta={"code"=>200, "message"=>"success"}, @data={},`

const code03 = `{
  "data": {
    "image": {
      "id": "5a40be59fb9d7f27354c5efa",
      "answer": "approved",
      "credit_charged": 1,
      "custom_id": "custom_id",
      "data": "image_url",
      "postback_url": "postback_url",
      "processed_at": "2017-12-25T16:02:00.599+07:00",
      "project_id": "project_id",
      "status": "processed"
    }
  },
  "status": 200,
  "message": "success",
  "meta": {
    "code": 200,
    "message": "success"
  }
}`

class P extends React.Component {
  static propTypes = {}

  static defaultProps = {}

  state = {}

  render() {
    return <p className="Api__content__p">{this.props.children}</p>
  }
}

export default withHeader()(Api)
