import React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'

import { getProject, resetProjectKey, addNotification } from '@redux-action'

import ProjectKey from '@layouts/projects/ProjectSetting/ProjectKey'
import ProjectSettingBanner from '@layouts/projects/ProjectSetting/ProjectSettingBanner'
import ProjectOutputSetting from '@layouts/projects/ProjectSetting/ProjectOutputSetting'
import ProjectAwsSetting from '@layouts/projects/ProjectSetting/ProjectAwsSetting/ProjectAwsSetting'
import {
  ProjectContent,
  ProjectHeader,
  ProjectSection,
  ProjectSubHeader,
} from '@shared/style/Project.style'
import { Button } from '@shared/style'
import ConfirmModal from '@shared/Modal/ConfirmModal'
import Notification from '@shared/Notification'

import rotateIcon from '../../../../../assets/images/icon-rotate-cw.svg'

const ProjectSetting = ({
  project,
  resetProjectKey,
  addNotification,
  match: {
    params: { id },
  },
}) => {
  const [confirmModal, setConfirmModal] = React.useState(false)
  const { t } = useTranslation()

  const onResetKey = () => {
    resetProjectKey({ id }).then((res) => {
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        addNotification({
          message: t('ProjectSetting.updateSuccess'),
          type: Notification.NOTIFICATION_TYPES.success,
        })
      }
    })

    setConfirmModal(false)
  }

  if (!project.isLoaded) return null

  return (
    <React.Fragment>
      <ProjectContent>
        <ProjectHeader>
          <h3>{t('ProjectSetting.projectSetting')}</h3>
        </ProjectHeader>

        <ProjectSettingBanner />

        <div
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '20px',
          }}
        >
          <div css={{ display: 'grid', gap: '20px' }}>
            <ProjectSection css={{ alignSelf: 'start' }}>
              <ProjectSubHeader>
                1. {t('ProjectSetting.setupApi')}
              </ProjectSubHeader>
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: 'max-content 1fr',
                  gap: 'calc(var(--global-space) * 0.8)',
                  alignItems: 'center',
                }}
              >
                <p>{t('ProjectSetting.step1')}.</p>
                <p>
                  <Trans i18nKey="ProjectSetting.downloadLibrary">
                    Download Libraries&nbsp;
                    <a
                      css={{ textDecoration: 'underline' }}
                      rel="noopener noreferrer nofollow"
                      target="_blank"
                      href="https://github.com/datawowio/posmoni-ruby"
                    >
                      here
                    </a>
                    &nbsp; and Setup
                  </Trans>
                </p>
                <p>{t('ProjectSetting.step2')}.</p>
                <div>
                  {t('ProjectSetting.copyProjectKey')}{' '}
                  <ProjectKey
                    licenseKey={get(
                      project,
                      'data.attributes.license_key',
                      t('Misc.loading')
                    )}
                    css={{ marginLeft: 'calc(var(--global-space) * 0.4)' }}
                  />
                  <Button
                    css={{
                      padding: '5px 15px',
                      marginLeft: '8px',
                      verticalAlign: 'middle',
                    }}
                    onClick={() => setConfirmModal(true)}
                  >
                    <img
                      css={{
                        width: '15px',
                        height: '15px',
                        marginRight: '5px',
                      }}
                      src={rotateIcon}
                    />
                    {t('Misc.reset')}
                  </Button>
                </div>
                <p>{t('ProjectSetting.step3')}.</p>
                <div>
                  <Trans i18nKey="ProjectSetting.setProjectKey">
                    Set <strong>‘Project Key’</strong> and
                    <strong>‘Library’</strong> in your code
                  </Trans>
                </div>
              </div>
            </ProjectSection>

            {get(project, 'data.attributes.template') === 'id_card_check' && (
              <ProjectAwsSetting />
            )}
          </div>

          <ProjectOutputSetting />
        </div>
      </ProjectContent>

      <ConfirmModal
        isActive={confirmModal}
        toggleFunction={() => setConfirmModal(false)}
        title={t('ProjectSetting.resetProjectKey')}
        message={
          <div>
            <Trans i18nKey="ProjectSetting.warningMessage">
              <strong>WARNING:</strong> You will have to change your new license
              key in your code after this, all of the communication to our
              service with old license key&nbsp;
              <strong>will be invalid immediately</strong>.
            </Trans>
          </div>
        }
        buttons={
          <React.Fragment>
            <Button onClick={() => setConfirmModal(false)}>
              {t('Misc.cancel')}
            </Button>
            <Button danger onClick={onResetKey} disabled={project.isLoading}>
              {project.isLoading ? t('Misc.loading') : t('Misc.reset')}
            </Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}

const mapState = (state) => ({
  project: getProject(state),
})

export default connect(mapState, { resetProjectKey, addNotification })(
  ProjectSetting
)
