import React from 'react'
import PropTypes from 'prop-types'
import { get, startCase } from 'lodash'
import className from 'classnames'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import GraphLine from '../graphs/GraphLine'
import DotsMenu from '../DotsMenu'

class ProjectItem extends React.Component {
  static defaultProps = {
    isPlaceholder: false,
  }

  openDeleteModal = (id, value) => () => {
    if (id) {
      this.props.onDeleteProject(id, value)
    }
  }

  openUpdateModal = (id, value) => () => {
    if (id) {
      this.props.onUpdateProject(id, value)
    }
  }

  renderSortKey = () => {
    const { t } = this.props

    switch (get(this.props, 'sortBy')) {
      case 'credit_usage':
        return `${t('ProjectItems.creditUsage')}: ${get(
          this.props,
          'data.credit_usage'
        )}`
      case 'today_tasks':
        return `${t('ProjectItems.tasksDoneToday')}: ${get(
          this.props,
          'data.today_tasks'
        )}`
      default:
        return null
    }
  }

  render = () => {
    const { t } = this.props
    const tasks = get(this.props, 'data.tasks', [])
    const processedTasks = get(this.props, 'data.tasks', []).reduce(
      (sum, cur) => sum + cur.processed,
      0
    )

    return (
      <div
        className={className('ProjectItem', {
          'ProjectItem--placeholder': this.props.isPlaceholder,
        })}
      >
        {this.props.isPlaceholder ? (
          <Link className="ProjectItem__placeholder" to="/app/projects/new">
            <span className="ProjectItem__placeholder__title">
              {t('ProjectItems.addNew')}
            </span>
            <span className="ProjectItem__placeholder__subtitle">
              {t('ProjectItems.clickToAddProject')}
            </span>
          </Link>
        ) : (
          <div className="ProjectItem__bg">
            <GraphLine
              manualHeight={65}
              noAxes
              dataPack={tasks.map((task) => {
                return {
                  key: moment(
                    get(task, 'time'),
                    'YYYY-MM-DD HH:mm:ss ZZ'
                  ).format('YYYY-MM-DD'),
                  data: {
                    count: get(task, 'processed'),
                  },
                }
              })}
            />

            <div className="ProjectItem__wrapper">
              <div />
              <span
                className={className('ProjectItem__header-c', {
                  'ProjectItem__header-c--empty-task':
                    get(this.props, 'data.remaining_tasks') === 0,
                })}
              >
                {t('ProjectItems.taskLeft', {
                  count: get(this.props, 'data.remaining_tasks'),
                })}
              </span>

              <DotsMenu
                data={[
                  {
                    label: t('Misc.edit'),
                    onClick: this.openUpdateModal(
                      get(this.props, 'data.id'),
                      get(this.props, 'data.name')
                    ),
                    nowrap: true,
                  },
                  {
                    label: t('Misc.delete'),
                    onClick: this.openDeleteModal(
                      get(this.props, 'data.id'),
                      get(this.props, 'data.name')
                    ),
                    nowrap: true,
                  },
                ]}
              />

              <div className="ProjectItem__content">
                <h3 className="ProjectItem__h3">
                  <Link
                    to={`/app/projects/${get(this.props, 'data.id')}/summary`}
                  >
                    {get(this.props, 'data.name')}
                  </Link>
                </h3>
                <p className="ProjectItem__subtitle">
                  {startCase(get(this.props, 'data.template')).replace('Ai', 'AI')}
                </p>
              </div>

              <span className="ProjectItem__footer-l">
                {t('ProjectItems.taskProcessed', { amount: processedTasks })}
              </span>

              <span className="ProjectItem__footer-r">
                {this.renderSortKey()}
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation()(ProjectItem)
