import React from 'react'
import { get, isEmpty } from 'lodash'
import hljs from 'highlight.js/lib/highlight'
import json from 'highlight.js/lib/languages/json'
import { useTranslation } from 'react-i18next'

import { ProjectSection, ProjectSubHeader } from '@shared/style/Project.style'

import SwitchGroups from '@shared/SwitchGroups'
import {
  ApiExplorerProstitute,
  ApiExplorerDating,
  ApiExplorerSNS,
  ApiExplorerSexual,
  ApiExplorerBusiness,
  // ApiExplorerOverall,
} from '@shared/Icon'

const EMAIL_PTON = 'ton@nanameue.jp'

const data = {
  prostitute: {
    icon: <ApiExplorerProstitute />,
    title: 'Prostitute',
  },
  sns: {
    icon: <ApiExplorerSNS />,
    title: 'Social Network System (SNS)',
  },
  sexual: {
    icon: <ApiExplorerSexual />,
    title: 'Sexual',
  },
  dating: {
    icon: <ApiExplorerDating />,
    title: 'Dating',
  },
  biz: {
    icon: <ApiExplorerBusiness />,
    title: 'Business',
  },
  // shifu: {
  //   icon: <ApiExplorerOverall />,
  //   title: 'Warning by POSMONI',
  // },
}

const PreviewResponse = (props) => {
  const { preview, type, user } = props
  let { answer } = props

  const { t } = useTranslation()

  React.useEffect(() => {
    hljs.registerLanguage('json', json)
  }, [])

  React.useEffect(() => {
    document.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightBlock(block)
    })
  }, [preview])

  const generateTab = () => {
    const items = [{ value: 'json', label: 'JSON' }]

    if (type === 'text_ai' && answer) {
      items.unshift({ value: 'view', label: 'View' })
    }

    return items
  }

  const getLabel = (percent) => {
    if (!percent) return null

    if (percent >= 0 && percent <= 30) {
      return 'Not likely'
    } else if (percent > 30 && percent <= 60) {
      return 'Moderate'
    } else {
      return 'Mostly'
    }
  }

  // Especially for p'Ton (the old one)
  const formatDataPTon = () => {
    // remove unused key
    const { declined, qa_textai: qaTextAi, shifu, ...rest } = answer

    Object.keys(rest).map((key) => {
      const score = Number(get(answer, `${key}.scores.declined`, 0)) * 100

      data[key].score = score.toFixed(2)
      data[key].label = getLabel(score)
    })

    return data
  }

  // new one
  const formatData = () => {
    data.topScore = (get(answer, 'top_score', 0) * 100).toFixed(2)

    answer = answer.overall

    Object.keys(answer).map((key) => {
      const score = Number(get(answer, `${key}`, 0)) * 100

      data[key].score = score.toFixed(2)
      data[key].label = getLabel(score)
    })

    return data
  }

  const renderView = () => {
    let data

    if (get(user, 'data.attributes.email') === EMAIL_PTON) {
      data = formatDataPTon()
    } else {
      data = formatData()
    }

    const { topScore, ...rest } = data

    return (
      <div css={{ display: 'grid', gap: '20px' }}>
        {topScore && (
          <p css={{ fontSize: '20px', textAlign: 'end' }}>
            Top Score: {topScore}
          </p>
        )}
        {Object.values(rest).map((datum) => (
          <div
            key={datum.title}
            css={{
              display: 'grid',
              gridTemplateColumns: '32px 1fr max-content 100px',
              gridTemplateRows: 'repeat(2, 1fr)',
              columnGap: '10px',
              alignItems: 'center',
            }}
          >
            {datum.icon}
            <p
              css={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
                textTransform: 'uppercase',
                fontWeight: 600,
              }}
            >
              {datum.title}
            </p>
            <p
              css={{
                fontSize: '20px',
              }}
            >
              {datum.score}
            </p>
            <p
              css={{
                fontSize: '20px',
                justifySelf: 'end',
              }}
            >
              ({datum.label})
            </p>
            <ProgressBar
              css={{ gridColumn: '1 / -1' }}
              progress={datum.score}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <ProjectSection>
      <ProjectSubHeader>{t('ApiExplorer.previewResponse')}</ProjectSubHeader>

      {preview && (
        <SwitchGroups items={generateTab()} withContent>
          {answer && (
            <div data-name="tab" data-label="view">
              {renderView()}
            </div>
          )}

          <div data-name="tab" data-label="json">
            <pre css={{ whiteSpace: 'pre-wrap' }}>
              <code className="json">{preview}</code>
            </pre>
          </div>
        </SwitchGroups>
      )}
    </ProjectSection>
  )
}

export default PreviewResponse

const ProgressBar = (props) => {
  const { progress, className } = props

  return (
    <div
      className={className}
      css={{
        borderRadius: '6px',
        backgroundColor: '#ffffff',
        height: '12px',
        border: '1px solid #eaebec',
        position: 'relative',

        '&::before': {
          content: '""',
          height: '100%',
          backgroundColor: '#46d7ce',
          width: `${progress < 1 ? 1 : progress}%`,
          display: 'block',
          borderRadius: '5px',
        },
      }}
    />
  )
}
