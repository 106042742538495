import React from 'react'
import { get, flowRight as compose } from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

import {
  addNotification,
  getLoginAuth,
  createLoginAuth,
  createLoginVerifyFactor,
} from '@redux-action'

import {
  COOKIES_KEY,
  COOKIES_KEY_NOTIFICATION,
  COOKIES_KEY_USER_EMAIL,
} from '@lib/helpers'

import Landing from '@layouts/Landing'
import ButtonSubmit from '@shared/forms/ButtonSubmit'
import Notification from '@shared/Notification'
import withHeader from '@features/withHeader'

const Home = ({
  createLoginAuth,
  createLoginVerifyFactor,
  addNotification,
  loginAuth,
}) => {
  const [stateToken, setStateToken] = React.useState(null)
  const [staySignedIn, setStaySignedIn] = React.useState(true)

  const { t } = useTranslation()

  React.useEffect(() => {
    if (Cookies.get(COOKIES_KEY)) {
      window.location = '/app'
    }
  }, [])

  const _proceedToSetCookie = (token) => {
    if (token) {
      staySignedIn
        ? Cookies.set(COOKIES_KEY, token, { expires: 365 })
        : Cookies.set(COOKIES_KEY, token)

      Cookies.set(
        COOKIES_KEY_NOTIFICATION,
        JSON.stringify({
          message: t('Main.signInSuccess'),
          type: Notification.NOTIFICATION_TYPES.success,
        })
      )


      window.location = '/app' // Traditional route change to reload application, allowing an app to be aware of a new cookies.
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const $els = e.target.elements
    const staySignedIn = $els.staySignedIn.checked

    setStaySignedIn(staySignedIn)

    if (!stateToken) {
      createLoginAuth({
        email: $els.email.value,
        password: $els.password.value,
      }).then((res) => {
        if (get(res, 'type', '').endsWith('_SUCCESS')) {
          const stateToken = get(res, 'payload.data.meta.state_token')

          if (stateToken) {
            addNotification({
              message: get(res, 'payload.data.meta.message'),
              type: Notification.NOTIFICATION_TYPES.warning,
            })

            setStateToken(stateToken)
            $els.mfa_code.focus()
          } else {
            _proceedToSetCookie(
              get(res, 'payload.data.data.attributes.access_token'),
            )
          }
        }
      })
    } else {
      createLoginVerifyFactor({
        state_token: stateToken,
        mfa_code: $els.mfa_code.value,
      }).then((res) => {
        if (get(res, 'type', '').endsWith('_SUCCESS')) {
          this._proceedToSetCookie(res)
        }
      })
    }
  }

  return (
    <Landing>
      <form className="Landing__form Landing__form--narrow" onSubmit={onSubmit}>
        <h2 className="Landing__form__h2">{t('Home.signIn')}</h2>
        <label className="Label">{t('Home.email')}</label>
        <input
          label="Email"
          type="text"
          className="Input"
          placeholder={t('Home.enterEmail')}
          name="email"
          disabled={stateToken}
        />
        <label className="Label">{t('Home.password')}</label>
        <input
          type="password"
          className="Input"
          placeholder={t('Home.enterPassword')}
          name="password"
          disabled={stateToken}
        />
        {stateToken && (
          <input
            type="text"
            className="Input"
            placeholder={t('Home.enterCode')}
            name="mfa_code"
          />
        )}
        <div className="Landing__login-options">
          <label>
            <input type="checkbox" defaultChecked={true} name="staySignedIn" />
            {t('Home.staySignedIn')}
          </label>
          {false && <Link to="/password-reset">{t('Home.forgotPassword')}</Link>}
        </div>

        <ButtonSubmit label={t('Home.signIn')} isLoading={loginAuth.isLoading} />

        <div className="Landing__sign-up">
          {t('Home.dontHaveAccount')}{' '}
          <Link to="/sign-up">{t('Signup.signup')}</Link>
        </div>
      </form>
    </Landing>
  )
}

const mapStateToProps = (state) => ({
  loginAuth: getLoginAuth(state),
})

export default compose(
  withHeader({ title: 'Customer Login - Posmoni' }),
  connect(
    mapStateToProps,
    { createLoginAuth, createLoginVerifyFactor, addNotification }
  )
)(Home)
