import React, { useEffect, useState } from 'react'
import { get, flowRight as compose } from 'lodash'
import { injectStripe } from 'react-stripe-elements'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import className from 'classnames'

import AutoRefillPane from './AutoRefillPane'
import AutoRefillModal from './AutoRefillModal'

import withHeader from '@features/withHeader'
import withAuthorization from '@features/withAuthorization'

import CentralHeader from '@layouts/CentralHeader'
import CreditUsage from '@layouts/Credits/CreditUsage'
import { ProjectContent, ProjectHeader } from '@shared/style/Project.style'
import Modal from '@shared/Modal'
import SectionLoader from '@shared/SectionLoader'
import ButtonSubmit from '@shared/forms/ButtonSubmit'
import StripeCardForm from '@shared/cards/StripeCardForm'
import CheckBox from '@shared/forms/CheckBox'
import Notification from '@shared/Notification'

import {
  loadUser,
  updateUser,
  getUserCards,
  loadUserCards,
  createUserCards,
  loadUserDefaultCard,
  getPaymentsPurchaseCredit,
  createPaymentsPurchaseCredit,
  createValidatePurchase,
  loadPaymentHistories,
  addNotification,
} from '@redux-action'

const CreditStripe = (props) => {
  const { stripe } = props
  const [amount, setAmount] = useState(0)
  const [modals, setModals] = useState(null)
  const [form, setForm] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    props.loadUserCards()
  }, [])

  const onToggleModal = (modal) => () => {
    if (modals === modal) {
      setModals(null)
    } else {
      setModals(modal)
    }
  }

  const onBuyCreditFlex = (e) => {
    e.preventDefault()
    const $form = e.target

    const data = {
      amount: amount,
      card_id: $form.querySelector('input:checked').value,
    }

    props.createPaymentsPurchaseCredit(data).then((res) => {
      const clientSecret = get(res, 'payload.data')

      stripe.confirmCardPayment(clientSecret).then((res) => {
        if (res.error) {
          props.addNotification({
            message: t('Credits.purchaseUnsuccess'),
            type: Notification.NOTIFICATION_TYPES.error,
          })
          onToggleModal('buyCreditsChooseCard')()
        } else {
          const {
            paymentIntent: {
              id: chargeId
            }
          } = res

          props.createValidatePurchase({ chargeId }).then((res) => {
            _buyCreditSuccess(res)
          })
        }
      })
      onToggleModal('buyCreditsChooseCard')()
    })
  }

  const _buyCreditSuccess = (res) => {
    if (get(res, 'type', '').endsWith('_SUCCESS')) {
      props.addNotification({
        message: t('Credits.purchaseSuccess'),
        type: Notification.NOTIFICATION_TYPES.success,
      })

      props.loadUser()
      props.loadUserDefaultCard()
    }
  }

  const onSubmitCreateCard = (e) => {
    e.preventDefault()

    setIsLoading(true)
    stripe.createToken({ name: get(form, 'name') }).then(({ token, error }) => {
      if (error) {
        props.addNotification({
          message: get(error, 'message') || t('PaymentMethodNew.cannotUseCard'),
          type: Notification.NOTIFICATION_TYPES.error,
        })
      } else {
        props
          .createUserCards({
            token: token.id,
            default_card: get(form, 'default_card', false),
          })
          .then((res) => {
            if (get(res, 'type', '').endsWith('_SUCCESS')) {
              props.loadUserDefaultCard().then(() => {
                props.addNotification({
                  message: t('PaymentMethodNew.successAddCard'),
                  type: Notification.NOTIFICATION_TYPES.success,
                })
              })

              props.loadUserCards().then(() => {
                onToggleModal('buyCreditsChooseCard')()
              })
            }
          })
      }
      setIsLoading(false)
    })
  }

  const onFormInputChange = ({ target: { name, value, type, checked } }) => {
    setForm({
      ...form,
      [name]: type.toLowerCase() === 'checkbox' ? checked : value,
    })
  }

  const updateUser = (data) => {
    props.updateUser(data).then((res) => {
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        const autoRefill = get(res, 'payload.data.data.attributes.auto_refill')

        props.addNotification({
          message: autoRefill
            ? t('Credits.autoRefillEnabled')
            : t('Credits.autoRefillDisabled'),
          type: Notification.NOTIFICATION_TYPES.success,
        })
      }
    })
  }

  return (
    <React.Fragment>
      <CentralHeader
        header={t('Credits.credits')}
        breadcrumbs={[{ label: `${t('Credits.credits')}` }]}
      />

      <div className="Central__sub-header">{/* No Redeem Coupon */}</div>

      <ProjectContent
        css={{
          display: 'grid',
          gridTemplateColumns: '340px 1fr',
          columnGap: '20px',
        }}
      >
        <div>
          <ProjectHeader>
            <h3>{t('Credits.creditsWallet')}</h3>
          </ProjectHeader>
          <div className="Credits__L">
            <div className="Credits__L__balance">
              <h4 className="Credits__h4">{t('Credits.myCreditsBalance')}</h4>
              <a
                className="Credits__L__balance__a"
                onClick={onToggleModal('buyCredits')}
              />
              <span className="Credits__L__balance__span">
                {get(
                  props,
                  'user.data.attributes.credit',
                  '0'
                ).toLocaleString()}
              </span>
            </div>
            <AutoRefillPane
              user={get(props, 'user.data')}
              handleClick={onToggleModal('autoRefill')}
              onToggle={updateUser}
            />
          </div>
        </div>
        <div>
          <CreditUsage />
        </div>
      </ProjectContent>

      <Modal
        isActive={modals === 'buyCredits'}
        toggleFunction={onToggleModal('buyCredits')}
      >
        <h3 className="Modal__h3">{t('Credits.buyCredits')}</h3>

        <form
          className="BuyCredits"
          onSubmit={(e) => {
            e.preventDefault()

            if (modals === 'buyCredits') {
              onToggleModal('buyCreditsChooseCard')()
            }
          }}
        >
          <input
            type="number"
            min={20}
            className="Input"
            placeholder={t('Credits.buyCreditsPlaceholder')}
            required
            style={{ minWidth: '240px' }}
            value={amount}
            onChange={({ target: { value } }) => setAmount(value)}
          />
          <p className="Strike">{t('Credits.total')}</p>
          <p className="BuyCredits__ratio">{t('Credits.creditRatio')}</p>
          <p className="BuyCredits__p">¥{(~~amount).toLocaleString()}</p>

          <div className="Modal__button">
            <button className="Button Button--green">
              {t('Credits.next')}
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        isActive={modals === 'buyCreditsChooseCard'}
        toggleFunction={onToggleModal('buyCreditsChooseCard')}
      >
        <h3 className="Modal__h3">{t('Credits.chooseCard')}</h3>

        <form className="BuyCredits" onSubmit={onBuyCreditFlex}>
          <SectionLoader
            isLoading={get(props, 'userCards.isLoading')}
            isLoaded={get(props, 'userCards.isLoaded')}
            isError={get(props, 'userCards.isError')}
            isEmpty={Object.keys(get(props, 'userCards.data', [])).length === 0}
          >
            {(() => {
              let data = get(props, 'userCards.data') || []
              return (
                <ul className="Radios">
                  {data.map((x, i) => (
                    <li key={i}>
                      <label
                        className={className('Radios__label', {
                          [`Radios__label--${(
                            get(x, 'attributes.brand', '') || ''
                          ).toLowerCase()}`]: true,
                        })}
                      >
                        <input
                          type="radio"
                          name="method"
                          defaultChecked={get(x, 'attributes.default_card')}
                          value={get(x, 'id')}
                        />
                        <i className="Radios__label__i" />
                        <span className="Radios__label__span">
                          {get(x, 'attributes.last_digits')}
                        </span>
                        <div className="Radios__label__bg" />
                      </label>
                    </li>
                  ))}
                </ul>
              )
            })()}
          </SectionLoader>

          <p className="BuyCredits__new">
            <a onClick={onToggleModal('buyCreditsNewCard')}>
              {t('Credits.buyCreditWithNewCard')}
            </a>
          </p>
          <p className="Strike">{t('Credits.total')}</p>
          <p className="BuyCredits__p">¥{(~~amount).toLocaleString()}</p>

          <div className="Modal__button">
            <ButtonSubmit
              label={t('Credits.buyCredits')}
              isLoading={get(props, 'paymentsPurchaseCredit.isLoading')}
            />
          </div>
        </form>
      </Modal>

      <Modal
        isActive={modals === 'buyCreditsNewCard'}
        toggleFunction={onToggleModal('buyCreditsNewCard')}
        klassName="Modal__panel__wrapper--buy-credits-new-card"
      >
        <h3 className="Modal__h3">{t('Credits.buyCreditWithNewCard')}</h3>

        <div className="BuyCredits">
          <form onSubmit={onSubmitCreateCard}>
            <StripeCardForm onInputChange={onFormInputChange} />

            <label className="BuyCredits__add-check">
              <input
                type="checkbox"
                defaultChecked={false}
                name="default_card"
                onChange={onFormInputChange}
              />
              <CheckBox />
              {t('Credits.addCardNextTime')}
            </label>

            <p className="Strike">{t('Credits.total')}</p>
            <p className="BuyCredits__p">¥{(~~amount).toLocaleString()}</p>

            <div className="Modal__button">
              <ButtonSubmit
                label={t('Credits.buyCredits')}
                isLoading={
                  get(props, 'paymentsPurchaseCredit.isLoading') || isLoading
                }
              />
            </div>
          </form>
        </div>
      </Modal>

      <AutoRefillModal
        isActive={modals === 'autoRefill'}
        toggleFunction={onToggleModal('autoRefill')}
        autoRefillThreshold={get(
          props,
          'user.data.attributes.auto_refill_threshold'
        )}
        autoRefillCredit={get(
          props,
          'user.data.attributes.auto_refill_credit_amount'
        )}
        onSubmit={updateUser}
      />
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  userCards: getUserCards(state),
  paymentsPurchaseCredit: getPaymentsPurchaseCredit(state),
})

export default compose(
  withAuthorization(['owner']),
  withHeader(),
  connect(
    mapStateToProps,
    {
      loadUser,
      updateUser,
      loadUserCards,
      createUserCards,
      loadPaymentHistories,
      createPaymentsPurchaseCredit,
      createValidatePurchase,
      addNotification,
      loadUserDefaultCard,
    }
  ),
  injectStripe
)(CreditStripe)
