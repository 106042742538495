import React from 'react'
import { get, pickBy, flowRight as compose } from 'lodash'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  getMembers,
  getUser,
  loadUser,
  addNotification,
  loadMembers,
  clearMembers,
  updateMember,
  deleteMember,
  inviteMember,
  resentInvitation,
  changeOwner,
} from '@redux-action'

import withHeader from '@features/withHeader'
import withAuthorization from '@features/withAuthorization'

import ConfirmModal from '@shared/Modal/ConfirmModal'
import CentralHeader from '@layouts/CentralHeader'
import Notification from '@shared/Notification'
import Search from '@shared/forms/Search'
import Select from '@shared/forms/Select'
import { Button } from '@shared/style'
import {
  ProjectSection,
  ProjectContent,
  ProjectHeader,
} from '@shared/style/Project.style'
import { InviteForm, UsersTable } from '@layouts/UserManagement'

const defaultForm = {
  email: '',
  role: 'user',
}

const UserManagement = (props) => {
  const {
    members,
    user,
    loadUser,
    addNotification,
    loadMembers,
    clearMembers,
    updateMember,
    deleteMember,
    inviteMember,
    resentInvitation,
    changeOwner,
  } = props

  const [inviteForm, setInviteForm] = React.useState(defaultForm)
  const [selectedUser, setSelectedUser] = React.useState(undefined)
  const [cfInvite, setCfInvite] = React.useState(false)

  React.useEffect(() => {
    loadMembers()
  }, [])

  const { t } = useTranslation()
  let searchTO = React.useRef()

  const _getMeta = () => {
    let page = get(members, 'meta.page')

    if (!page || page < 0) {
      page = null
    }

    return pickBy({ page, per_page: get(members, 'meta.per_page') })
  }

  const onChangeForm = ({ target: { name, value } }) =>
    setInviteForm((form) => ({ ...form, [name]: value }))

  const onSubmit = () => {
    const { email, role } = inviteForm

    inviteMember({ email, role }).then((res) => {
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        addNotification({
          message: t('UserManagement.inviteSuccess', { name: email }),
          type: Notification.NOTIFICATION_TYPES.success,
        })
        loadMembers()
        setInviteForm(defaultForm)
        setCfInvite(false)
      }
    })
  }

  const onRemoveUser = (id) => {
    deleteMember({ id }).then((res) => {
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        addNotification({
          message: t('UserManagement.removeSuccess', {
            name: get(selectedUser, 'attributes.email'),
          }),
          type: Notification.NOTIFICATION_TYPES.success,
        })
        loadMembers()
      }
    })
  }

  const onChangeRole = (id, role) => {
    updateMember({ id, role }).then((res) => {
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        addNotification({
          message: t('UserManagement.changeRoleSuccess', {
            name: get(selectedUser, 'attributes.email'),
          }),
          type: Notification.NOTIFICATION_TYPES.success,
        })
        loadMembers()
      }
    })
  }

  const onChangeOwner = (id) => {
    changeOwner({ admin_id: id }).then((res) => {
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        addNotification({
          message: t('UserManagement.changeOwnerSuccess'),
          type: Notification.NOTIFICATION_TYPES.success,
        })
        loadUser()
        loadMembers()
      }
    })
  }

  const onChangePage = (page = members.meta.page) => {
    clearMembers()
    loadMembers({ ..._getMeta(), page })
  }

  const onChangePerPage = ({ target: { value } }) => {
    clearMembers()
    loadMembers({ ..._getMeta(), per_page: value })
  }

  const onSearch = ({ target: { value: query } }) => {
    window.clearTimeout(searchTO)
    searchTO = window.setTimeout(() => {
      clearMembers()
      loadMembers(pickBy({ ..._getMeta(), page: null, query }))
    }, 500)
  }

  const onFilterRole = ({ target: { value: role } }) => {
    clearMembers()
    loadMembers(pickBy({ ..._getMeta(), role }))
  }

  const onResentInvite = (id) => {
    resentInvitation({ id }).then((res) => {
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        addNotification({
          message: t('UserManagement.inviteSuccess', {
            name: get(selectedUser, 'attributes.email'),
          }),
          type: Notification.NOTIFICATION_TYPES.success,
        })
        loadMembers()
      }
    })
  }

  return (
    <React.Fragment>
      <CentralHeader
        header={t('UserManagement.userManagement')}
        breadcrumbs={[{ label: t('UserManagement.userManagement') }]}
      />

      <div className="Central__sub-header"></div>

      <ProjectContent>
        <div
          className="UserManagement"
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 350px',
            columnGap: 'var(--global-space)',
          }}
        >
          <div>
            <ProjectHeader>
              <h3>{t('UserManagement.userManagement')}</h3>
            </ProjectHeader>
            <ProjectSection>
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns:
                    'repeat(auto-fit, minmax(1px, max-content))',
                  gap: 'var(--global-space)',
                  alignItems: 'center',
                  marginBottom: 'var(--global-space)',
                  justifyContent: 'space-between',
                }}
              >
                <Select
                  value={get(members, 'meta.role', '')}
                  isLoading={members.isLoading}
                  onChange={onFilterRole}
                  label={t('UserManagement.role')}
                  options={[
                    { label: t('selectOptions.all'), value: '' },
                    { label: t('UserManagement.owner'), value: 'owner' },
                    { label: t('UserManagement.admin'), value: 'admin' },
                    {
                      label: t('UserManagement.user'),
                      value: 'user',
                    },
                  ]}
                />

                <Search
                  placeholder={t('UserManagement.user')}
                  onChange={onSearch}
                />
              </div>
              <UsersTable
                t={t}
                user={user}
                users={members.data}
                meta={members.meta}
                isLoading={members.isLoading}
                isLoaded={members.isLoaded}
                selectedUser={selectedUser}
                onRemoveUser={onRemoveUser}
                onChangeRole={onChangeRole}
                onChangeOwner={onChangeOwner}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                onSelectUser={setSelectedUser}
                onResentInvite={onResentInvite}
              />
            </ProjectSection>
          </div>
          <div>
            <InviteForm
              t={t}
              form={inviteForm}
              onChange={onChangeForm}
              onSubmit={() => setCfInvite(true)}
              isLoading={members.isLoading}
            />
          </div>
        </div>
      </ProjectContent>
      <ConfirmModal
        isActive={cfInvite}
        toggleFunction={() => setCfInvite(false)}
        title={t('UserManagement.confirmInviteMemberDesc', {
          name: inviteForm.email,
        })}
        buttons={
          <React.Fragment>
            <Button onClick={() => setCfInvite(false)}>
              {t('Misc.cancel')}
            </Button>
            <Button primary onClick={onSubmit} disabled={members.isLoading}>
              {members.isLoading ? t('Misc.loading') : t('Misc.ok')}
            </Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}

const mapState = (state) => ({
  members: getMembers(state),
  user: getUser(state),
})

export default compose(
  withAuthorization(['owner', 'admin']),
  withHeader(),
  connect(
    mapState,
    {
      getMembers,
      loadMembers,
      clearMembers,
      updateMember,
      deleteMember,
      inviteMember,
      resentInvitation,
      changeOwner,
      loadUser,
      addNotification,
    }
  )
)(UserManagement)
