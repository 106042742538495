import React from 'react'
import { get, omitBy } from 'lodash'
import className from 'classnames'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  getProject,
  getOutputTasksApi,
  loadOutputTasksApi,
  clearOutputTasksApi,
} from '@redux-action'

import {
  FormatDateWithTime,
  hasMore,
  useDateDiff,
  summarizeAnswer,
  getTypeFromTemplate,
} from '@helpers'

import SectionLoader from '@shared/SectionLoader'
import Select from '@shared/forms/Select'
import StatusColor from '@shared/StatusColor'
import Search from '@shared/forms/Search'
import ProjectImagePreview from '../ProjectImagePreview'
import ModerationLog from '@layouts/projects/ProjectSummary/ModerationLog'
import { Tooltip } from '@shared/Tooltip'

const ProjectApiTable = (props) => {
  const { clearOutputTasksApi, loadOutputTasksApi, project, nolabel } = props

  const [isActive, setIsActive] = React.useState(false)
  const [moderationId, setModerationId] = React.useState('')

  React.useEffect(() => {
    clearOutputTasksApi()
    loadOutputTasksApi(_getMeta())

    return clearOutputTasksApi
  }, [])

  let searchTO = React.useRef()
  const { t } = useTranslation()
  const getDateDiff = useDateDiff()
  const { id } = useParams()

  const _getMeta = () => {
    const params = omitBy(
      {
        page: 1,
        sort_by: get(props, 'outputTasksApi.meta.sort_by', 'created_at'),
        sort_direction: get(
          props,
          'outputTasksApi.meta.sort_direction',
          'desc'
        ),
        status: get(props, 'outputTasksApi.meta.status'),
        project_id: id,
        answer: get(props, 'outputTasksApi.meta.answer'),
      },
      (x) => !x
    )

    return params
  }

  const onLoadMore = (e) => {
    props.loadOutputTasksApi({
      ..._getMeta(),
      per_page: get(props, 'outputTasksApi.meta.per_page', 10) + 10,
    })
  }

  const onSort = (key) => (e) => {
    const sortDirection = get(props, 'outputTasksApi.meta.sort_direction')

    clearOutputTasksApi()
    loadOutputTasksApi(
      omitBy(
        {
          ..._getMeta(),
          page: get(props, 'outputTasksApi.meta.page', 1),
          sort_by: key,
          sort_direction: sortDirection === 'desc' ? 'asc' : 'desc',
        },
        (x) => !x
      )
    )
  }

  const onChangeTaskType = (e) => {
    clearOutputTasksApi()
    loadOutputTasksApi(
      omitBy(
        {
          ..._getMeta(),
          page: 1,
          status: e.target.value,
        },
        (x) => !x
      )
    )
  }

  const onSearch = (e) => {
    const query = e.target.value

    window.clearTimeout(searchTO)

    searchTO = window.setTimeout(() => {
      props.clearOutputTasksApi()
      props.loadOutputTasksApi(
        omitBy(
          {
            ..._getMeta(),
            page: null,
            task_id: query,
          },
          (x) => !x
        )
      )
    }, 500)
  }

  const formatAnswer = (answer, template) => {
    const type = getTypeFromTemplate(template)

    if (typeof answer === 'string') return answer
    if (type === 'text') return summarizeAnswer(answer, type)

    return JSON.stringify(answer)
  }

  const onChangeAnswer = (e) => {
    clearOutputTasksApi()
    loadOutputTasksApi(
      omitBy(
        {
          ..._getMeta(),
          page: 1,
          answer: e.target.value,
        },
        (x) => !x
      )
    )
  }

  const onClickRow = (e, moderationId) => {
    e.stopPropagation()

    setIsActive(true)
    setModerationId(moderationId)
  }

  const headers = [
    { value: 'taskId', label: t('Header.taskId') },
    { value: 'customId', label: t('Header.customId') },
    { value: 'customId2', label: t('Header.customId2') },
    { value: 'customId3', label: t('Header.customId3') },
    { value: 'customId4', label: t('Header.customId4') },
    { value: 'category', label: t('Header.category') },
    { value: 'createdAt', label: t('Header.createdAt') },
    { value: 'processedAt', label: t('Header.processedAt') },
    { value: 'duration', label: t('Header.duration') },
    { value: 'screenedBy', label: t('Header.screenedBy') },
    { value: 'consensus', label: t('Header.consensus') },
    { value: 'status', label: t('Header.status') },
    { value: 'answer', label: t('Misc.answer') },
    { value: 'reason', label: t('Header.ngReason') },
    { value: 'detail', label: t('Header.detail') },
  ]
  const outputTasksApi = get(props, 'outputTasksApi.data', [])
  const sortBy = get(props, 'outputTasksApi.meta.sort_by')
  const sortDirection = get(props, 'outputTasksApi.meta.sort_direction')

  if (!project.isLoaded) return null

  const template = get(props, 'project.data.attributes.template', '')

  return (
    <React.Fragment>
      <div
        className="Central__content__section Central__content__section--no-boder-top"
        css={{ overflowX: 'auto' }}
      >
        {!nolabel && (
          <h4 className="Project__content-subtitle">
            {t('ProjectApiTable.apiConnected')}
          </h4>
        )}
        <div className="Central__content__select-search">
          <Select
            value={get(props, 'outputTasksApi.meta.status', '')}
            isLoading={get(props, 'outputTasksApi.isLoading')}
            onChange={onChangeTaskType}
            label={t('Misc.status')}
            options={[
              { label: t('selectOptions.all'), value: '' },
              { label: t('selectOptions.processed'), value: 'processed' },
              {
                label: t('selectOptions.unprocessed'),
                value: 'unprocessed',
              },
            ]}
          />
          <Select
            value={get(props, 'outputTasksApi.meta.answer', '')}
            isLoading={get(props, 'outputTasksApi.isLoading')}
            onChange={onChangeAnswer}
            label={t('Misc.answer')}
            options={[
              { label: t('selectOptions.all'), value: '' },
              { label: t('selectOptions.approved'), value: 'approved' },
              { label: t('selectOptions.declined'), value: 'declined' },
            ]}
          />
          <Search onChange={onSearch} />
        </div>
        <SectionLoader
          isLoading={get(props, 'outputTasksApi.isLoading')}
          isLoaded={get(props, 'outputTasksApi.isLoaded')}
          isError={get(props, 'outputTasksApi.isError')}
          isEmpty={outputTasksApi.length === 0}
          alwaysDisplayChildren
        >
          <div className="Table">
            <div className="Table__tr">
              {headers.map((header, i) => (
                <div key={i} className="Table__td Table__td--th">
                  {(() => {
                    if (header.value === 'createdAt') {
                      return (
                        <a
                          className={className({
                            Table__td__caret: true,
                            'Table__td__caret--up':
                              sortBy === 'created_at' &&
                              sortDirection === 'asc',
                          })}
                          onClick={onSort('created_at')}
                        >
                          {header.label}
                        </a>
                      )
                    } else if (header.value === 'processedAt') {
                      return (
                        <a
                          className={className({
                            Table__td__caret: true,
                            'Table__td__caret--up':
                              sortBy === 'processed_at' &&
                              sortDirection === 'asc',
                          })}
                          onClick={onSort('processed_at')}
                        >
                          {header.label}
                        </a>
                      )
                    } else {
                      return header.label
                    }
                  })()}
                </div>
              ))}
            </div>
            {outputTasksApi.map((task, i) => (
              <div className="Table__tr" key={i} data-x={task}>
                <div
                  className="Table__td"
                  css={{ cursor: 'pointer' }}
                  onClick={(e) =>
                    onClickRow(e, get(task, 'attributes.id') || '')
                  }
                >
                  {/* For Future Usage */}
                  {/* <PopupHolder {...task} /> */}
                  {get(task, 'attributes.id')}
                </div>
                <div className="Table__td">
                  {get(task, 'attributes.custom_id') || '-'}
                </div>
                <div className="Table__td">
                  {get(task, 'attributes.custom_id_2') || '-'}
                </div>
                <div className="Table__td">
                  {get(task, 'attributes.custom_id_3') || '-'}
                </div>
                <div className="Table__td">
                  {get(task, 'attributes.custom_id_4') || '-'}
                </div>
                <div className="Table__td">
                  {get(task, 'attributes.category') || '-'}
                </div>
                <div className="Table__td">
                  {FormatDateWithTime(get(task, 'attributes.created_at'))}
                </div>
                <div className="Table__td">
                  {get(task, 'attributes.processed_at')
                    ? FormatDateWithTime(get(task, 'attributes.processed_at'))
                    : ''}
                </div>
                <div className="Table__td">
                  {get(task, 'attributes.processed_at')
                    ? getDateDiff(
                        get(task, 'attributes.created_at'),
                        get(task, 'attributes.processed_at')
                      )
                    : ''}
                </div>
                <div className="Table__td">
                  {get(task, 'attributes.screened_by').replace('Ai', 'AI')}
                </div>
                <div className="Table__td">
                  {get(task, 'attributes.consensus', false).toString()}
                </div>
                <div className="Table__td">
                  <StatusColor status={get(task, 'attributes.status')} />
                </div>
                <div
                  className="Table__td Table__td--ellipsis"
                  title={
                    typeof get(task, 'attributes.answer') === 'string'
                      ? get(task, 'attributes.answer')
                      : formatAnswer(
                          get(task, 'attributes.answer') || '',
                          template
                        )
                  }
                  data-tip
                  data-for="answer"
                >
                  <Tooltip
                    text={
                      <div css={{ width: '400px' }}>
                        {JSON.stringify(
                          get(task, 'attributes.answer'),
                          null,
                          2
                        )}
                      </div>
                    }
                  >
                    {formatAnswer(
                      get(task, 'attributes.answer') || '',
                      template
                    )}
                  </Tooltip>
                </div>
                <div className="Table__td">
                  {get(task, 'attributes.declined_reason') ?? '-'}
                </div>
                <div className="Table__td">
                  <ProjectImagePreview
                    item={task.attributes}
                    project={props.project}
                  />
                </div>
              </div>
            ))}
          </div>
          {hasMore(get(props, 'outputTasksApi')) && (
            <a className="LoadMore" onClick={onLoadMore}>
              {t('Misc.loadmore')}
            </a>
          )}
        </SectionLoader>
      </div>
      <ModerationLog
        isActive={isActive}
        toggleFunction={() => setIsActive(false)}
        moderationId={moderationId}
      />
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  project: getProject(state),
  outputTasksApi: getOutputTasksApi(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadOutputTasksApi: (params) => dispatch(loadOutputTasksApi(params)),
  clearOutputTasksApi: (params) => dispatch(clearOutputTasksApi(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectApiTable)
