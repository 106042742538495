import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import { addNotification, getProject, updateProject } from '@redux-action'

import Select from '@shared/forms/Select'
import Notification from '@shared/Notification'
import { ProjectSection, ProjectSubHeader } from '@shared/style/Project.style'
import { Input, Button } from '@shared/style'

import connectedIcon from '../../../../../../assets/images/icon-connected.svg'

const ProjectOutputSetting = ({ project, addNotification, updateProject }) => {
  const [form, setForm] = React.useState({ method: 'GET', postbackUrl: '' })
  const { t } = useTranslation()

  React.useEffect(() => {
    setForm({
      method: project.data.attributes.postback_method,
      postbackUrl: project.data.attributes.postback_url,
    })
  }, [project])

  const onChange = ({ target: { name, value } }) => {
    setForm((form) => ({ ...form, [name]: value }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const params = {
      id: project.data.attributes.id,
      name: project.data.attributes.name,
      template: project.data.attributes.template,
      postback_method: form.method,
      postback_url: form.postbackUrl,
    }

    updateProject(params).then((res) => {
      if (get(res, 'type', '').endsWith('_SUCCESS')) {
        addNotification({
          message: t('ProjectOutput.successUpdate'),
          type: Notification.NOTIFICATION_TYPES.success,
        })
      } else if (get(res, 'type', '').endsWith('_FAIL')) {
        addNotification({
          message: t('Misc.errorOccurred'),
          type: Notification.NOTIFICATION_TYPES.error,
        })
      }
    })
  }

  return (
    <ProjectSection css={{ alignSelf: 'start' }}>
      <ProjectSubHeader>2. {t('ProjectSetting.setWebhookURL')}</ProjectSubHeader>
      <form
        onSubmit={onSubmit}
        css={{ display: 'grid', rowGap: 'calc(var(--global-space) * 0.8)' }}
      >
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <img
            css={{
              width: '32px',
              height: '32px',
              marginRight: 'calc(var(--global-space) * 0.8)',
            }}
            src={connectedIcon}
          />
          Webhook URL
        </div>
        <div
          css={{
            display: 'flex',
          }}
        >
          <Select
            withOutLabel
            options={[
              { label: 'GET', value: 'GET' },
              { label: 'POST', value: 'POST' },
            ]}
            name="method"
            onChange={onChange}
            value={form.method}
            css={{ marginRight: 'calc(var(--global-space) * 0.8)' }}
          />
          <Input
            type="text"
            name="postbackUrl"
            onChange={onChange}
            value={form.postbackUrl || ''}
            required
          />
        </div>
        <Button type="submit" primary css={{ justifySelf: 'start' }}>
          {t('Misc.save')}
        </Button>
      </form>
    </ProjectSection>
  )
}

const mapState = (state) => ({
  project: getProject(state),
})

export default connect(
  mapState,
  { addNotification, updateProject }
)(ProjectOutputSetting)
