import React from 'react'
import PropTypes from 'prop-types'
import { get, flowRight as compose } from 'lodash'
import className from 'classnames'
import moment from 'moment'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { DateSelectorKlass } from './DateSelector'

import { getDatePicker, updateDatePicker } from '@redux-action'

class DateSelectorRange extends DateSelectorKlass {
  static propTypes = {
    ...DateSelectorKlass.propTypes,
  }

  static defaultProps = {
    ...DateSelectorKlass.defaultProps,
    isLoading: false,
  }

  state = {
    ...this.state,
    selectedMonth: moment(get(this.props, 'datePicker.endDate')).startOf(
      'month'
    ),
    startDate: this.props.startDate || get(this.props, 'datePicker.startDate'),
    endDate: this.props.endDate || get(this.props, 'datePicker.endDate'),
  }

  componentWillReceiveProps = (nextProps) => {
    // Override DateSelectorKlass's. Here we rely on local state.
  }

  _isActiveDay = (date) => {
    return (
      date.isSame(moment(this.state.startDate), 'day') ||
      date.isSame(moment(this.state.endDate), 'day')
    )
  }

  _isBetweenDay = (date) => {
    return date.isBetween(
      moment(this.state.startDate),
      moment(this.state.endDate)
    )
  }

  _isDisabledDate = ({ date, today }) => {
    return date.isAfter(today)
  }

  _isDisabledMonthPrev = () => {
    return false
  }

  _isDisabledMonthNext = () => {
    return (
      +this.state.selectedMonth.startOf('month') === +moment().startOf('month')
    )
  }

  onClick = (date) => () => {
    if (this.state.endDate) {
      this.setState({
        startDate: date,
        endDate: null,
      })
    } else {
      if (this.state.startDate.isAfter(date)) {
        this.setState({
          startDate: date,
          endDate: null,
        })
      } else {
        this.setState(
          {
            endDate: date,
            isActive: false,
          },
          () => {
            const data = {
              startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
              endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
            }

            this.props.useLocalState
              ? this.setState({ ...data }, () => this.props.useLocalState(data))
              : this.props.updateDatePicker(data)
          }
        )
      }
    }
  }

  render = () => {
    const { withIcon, t } = this.props

    return (
      <div className="DateSelectorRange">
        <div
          className="DateSelectorRange__handle"
          disabled={this.props.isLoading}
        >
          <a
            onClick={this.onTogglePanel}
            className={className('DateSelectorRange__date', {
              'DateSelectorRange__date--icon': withIcon,
            })}
          >
            {this.props.startDate
              ? moment(this.props.startDate).format('YYYY/MM/DD')
              : moment(this.state.startDate).format('YYYY/MM/DD')}
          </a>
          <span className="DateSelectorRange__to">to</span>
          <span
            className={className('DateSelectorRange__date', {
              'DateSelectorRange__date--icon': withIcon,
            })}
          >
            {this.props.endDate
              ? moment(this.props.endDate).format('YYYY/MM/DD')
              : this.state.endDate
              ? moment(this.state.endDate).format('YYYY/MM/DD')
              : t('DateSelector.selectEndDate')}
          </span>
        </div>
        {this.renderPanel(this.props.t)}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  datePicker: getDatePicker(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateDatePicker: (params) => dispatch(updateDatePicker(params)),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslation()
)(DateSelectorRange)