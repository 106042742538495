import React from 'react'
import { get } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  getCreditUsage,
  loadCreditUsage,
  clearCreditUsage,
} from '@redux-action'

import { getLastNYears, getMonths } from '@helpers'

import { ProjectSection, ProjectHeader } from '@shared/style/Project.style'
import Select from '@shared/forms/Select'
import SectionLoader from '@shared/SectionLoader'

const CreditUsage = ({ creditUsage, loadCreditUsage, clearCreditUsage }) => {
  const [selectedMonth, setSelectedMonth] = React.useState(moment().format('M'))
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  )
  React.useEffect(() => {
    clearCreditUsage()
    loadCreditUsage({
      month: selectedMonth,
      year: selectedYear,
    })
  }, [selectedMonth, selectedYear])

  const { t } = useTranslation()

  const onChangeMonth = ({ target: { value } }) => setSelectedMonth(value)
  const onChangeYear = ({ target: { value } }) => setSelectedYear(value)

  const headers = [t('Header.project'), t('ProjectItems.creditUsage')]

  const summary = get(creditUsage, 'data', []).reduce(
    (sum, cur) => sum + get(cur, 'total_amount', 0),
    0
  )

  return (
    <React.Fragment>
      <ProjectHeader>
        <h3>{t('ProjectItems.creditUsage')}</h3>
      </ProjectHeader>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(1px, max-content))',
          columnGap: '20px',
          marginBottom: '20px',
        }}
      >
        <Select
          label={t('Misc.year')}
          value={selectedYear}
          isLoading={creditUsage.isLoading}
          onChange={onChangeYear}
          options={getLastNYears(10).map((year) => ({
            label: year,
            value: year,
          }))}
          backLabel
        />
        <Select
          label={t('Misc.month')}
          value={selectedMonth}
          isLoading={creditUsage.isLoading}
          onChange={onChangeMonth}
          options={getMonths().map((month) => ({
            label: month,
            value: month,
          }))}
          backLabel
        />
      </div>
      <ProjectSection>
        <SectionLoader
          isLoading={creditUsage.isLoading}
          isLoaded={creditUsage.isLoaded}
          isError={creditUsage.isError}
          isEmpty={get(creditUsage, 'data', []).length === 0}
          alwaysDisplayChildren
        >
          <div className="Table">
            <div className="Table__tr">
              {headers.map((header) => (
                <div className="Table__td Table__td--th" key={header}>
                  {header}
                </div>
              ))}
            </div>
            {get(creditUsage, 'data', []).map((item) => (
              <div className="Table__tr" key={get(item, 'project_name', '')}>
                <div className="Table__td">{get(item, 'project_name', '')}</div>
                <div className="Table__td">{get(item, 'total_amount', '')}</div>
              </div>
            ))}
            {get(creditUsage, 'data', []).length > 0 && (
              <div className="Table__tr">
                <div className="Table__td">{t('Header.total')}</div>
                <div className="Table__td" css={{ fontWeight: 'bold' }}>
                  {summary}
                </div>
              </div>
            )}
          </div>
        </SectionLoader>
      </ProjectSection>
    </React.Fragment>
  )
}

const mapState = (state) => ({
  creditUsage: getCreditUsage(state),
})

export default connect(
  mapState,
  { loadCreditUsage, clearCreditUsage }
)(CreditUsage)
