import React from 'react'
import PropTypes from 'prop-types'
import { isObject, last } from 'lodash'
import { connect } from 'react-redux'
import className from 'classnames'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import * as Cookies from 'js-cookie'
import { Translation } from 'react-i18next'

import {
  addNotification,
  getNotifications,
  removeNotification,
} from '@redux-action'

import { COOKIES_KEY_NOTIFICATION } from '../../lib/helpers'

class Notification extends React.Component {
  static NOTIFICATION_TYPES = {
    error: '__error__',
    success: '__success__',
    warning: '__warning__',
  }

  componentDidMount = () => {
    const cookie = Cookies.get(COOKIES_KEY_NOTIFICATION)

    if (cookie) {
      this.props.addNotification(JSON.parse(cookie))
      Cookies.remove(COOKIES_KEY_NOTIFICATION)
    }
  }

  componentDidUpdate = () => {
    const data = last(this.props.notifications.data)

    if (
      this.props.notifications.data.length > 0
    ) {
      setTimeout(() => {
        this.props.removeNotification(data)
      }, 5000)
    }
  }

  render = () => {
    return (
      <Translation>
        {(t) => (
          <div className="Notification">
            <TransitionGroup component="ul">
              {this.props.notifications.data.map((noti) => (
                <CSSTransition
                  key={noti.index}
                  timeout={400}
                  classNames="Notification__ul__li"
                >
                  <li
                    className={className('Notification__ul__li', {
                      'Notification__ul__li--error':
                        noti.type === Notification.NOTIFICATION_TYPES.error,
                      'Notification__ul__li--success':
                        noti.type === Notification.NOTIFICATION_TYPES.success,
                      'Notification__ul__li--warning':
                        noti.type === Notification.NOTIFICATION_TYPES.warning,
                    })}
                  >
                    {(() => {
                      let message = noti.message ? noti.message : noti.key

                      if (isObject(message)) {
                        message = message[0]
                      }

                      return (
                        <p
                          className="Notification__ul__li__p"
                          title={noti.key}
                        >
                          {noti.type ===
                          Notification.NOTIFICATION_TYPES.error ? (
                            <React.Fragment>
                              <strong>{t('Misc.error')}:</strong> {message}
                            </React.Fragment>
                          ) : (
                            message
                          )}
                        </p>
                      )
                    })()}
                  </li>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
        )}
      </Translation>
    )
  }
}

const mapStateToProps = (state) => ({
  notifications: getNotifications(state),
})

const mapDispatchToProps = (dispatch) => ({
  removeNotification: (params) => dispatch(removeNotification(params)),
  addNotification: (params) => dispatch(addNotification(params)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification)
