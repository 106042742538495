import React from 'react'
import PropTypes from 'prop-types'
import { get, first } from 'lodash'
import className from 'classnames'
import { withTranslation } from 'react-i18next'

class Select extends React.Component {
  static propTypes = {}

  static defaultProps = {
    value: null,
    label: 'Tasks',
    withBullet: false,
    withOutLabel: false,
    onChange: () => {},
    options: [{ label: 'All', value: 'all' }, { label: 'Some', value: 'some' }],
  }

  render() {
    const {
      t,
      className: klassName,
      backLabel = false,
      name,
      onChange,
      label,
      value,
      options,
      isLoading,
      withOutLabel,
      withBullet,
    } = this.props

    return (
      <div
        className={className(`Select ${klassName}`, {
          'Select--with-bullet': withBullet,
        })}
      >
        {!withOutLabel && !backLabel && (
          <span className="Select__label">{label}:</span>
        )}
        <div className="Select__area">
          <div className="Select__display">
            <p className="Select__display__p">
              {(() => {
                let valueLabel = get(
                  options.find((option) => option.value == value),
                  'label'
                )
                if (!valueLabel) {
                  valueLabel = get(first(options), 'label')
                }
                if (isLoading) {
                  valueLabel = t('Misc.loading')
                }
                return <span>{valueLabel}</span>
              })()}
            </p>
          </div>
          <select name={name} onChange={onChange} value={value}>
            {options.map((option, i) => (
              <option value={option.value} key={i}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {backLabel && <span className="Select__label">&nbsp;{label}</span>}
      </div>
    )
  }
}

export default withTranslation()(Select)
