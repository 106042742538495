import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'

import Modal from '@shared/Modal'
import ButtonSubmit from '@shared/forms/ButtonSubmit'

const AutoRefillModal = (props) => {
  const { t } = props
  const [threshold, setThreshold] = useState(0)
  const [refillCredit, setRefillCredit] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (props.autoRefillThreshold)
      setThreshold(props.autoRefillThreshold)
  }, [props.autoRefillThreshold])

  useEffect(() => {
    if (props.autoRefillCredit)
      setRefillCredit(props.autoRefillCredit)
  }, [props.autoRefillCredit])

  const onUpdateAutoRefillCredit = () => {
    setIsLoading(true)
    props.onSubmit({
      auto_refill_credit_amount: refillCredit,
      auto_refill_threshold: threshold
    })
    setIsLoading(false)
  }

  return (
    <Modal
      isActive={props.isActive}
      toggleFunction={props.toggleFunction}
    >
      <h3 className="Modal__h3">{t('Credits.autoRefillCredit')}</h3>

      <form onSubmit={onUpdateAutoRefillCredit}>
        <div className="AutoRefill">
          <div className="BuyCredits">
            <input
              type="number"
              className="Input"
              placeholder={t('Credits.creditLessThan')}
              required
              value={threshold}
              onChange={(e) => setThreshold(e.target.value)}
            />

            <p className="Strike Strike--padded">
              {t('Credits.whenCreditLessThan')}
            </p>
            <p className="BuyCredits__p">
              ¥
              {(~~threshold).toLocaleString()}
            </p>
          </div>

          <div className="BuyCredits">
            <input
              type="number"
              className="Input"
              placeholder={t('Credits.autoRefill')}
              required
              value={refillCredit}
              onChange={(e) => setRefillCredit(e.target.value)}
            />

            <p className="Strike">{t('Credits.autoRefillFor')}</p>
            <p className="BuyCredits__p">
              ¥
              {(~~refillCredit).toLocaleString()}
            </p>
          </div>
        </div>

        <div className="Modal__button">
          <ButtonSubmit
            label={t('Misc.submit')}
            isLoading={isLoading}
          />
        </div>
      </form>
    </Modal>
  )
}

export default withTranslation()(AutoRefillModal)
