import React from 'react'
import { get, isNull } from 'lodash'
import { useTranslation } from 'react-i18next'

import { FormatDateWithTime } from '@helpers'

import { ProjectSubHeader } from '@shared/style/Project.style'
import { Pill } from '@shared/style'

const RequestInspection = ({ requestInspection }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <ProjectSubHeader css={{ marginBottom: '16px' }}>
        {t('ModerationLog.requestInspection')}
      </ProjectSubHeader>
      {!requestInspection.isLoaded ? (
        t('Misc.loading')
      ) : !isNull(get(requestInspection, 'data')) ? (
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr',
            gap: '12px',
            alignItems: 'center',
          }}
        >
          {t('Misc.status')} :
          <p>
            {get(requestInspection, 'data.attributes.status') === 'done' ? (
              <Pill>{t('Header.done')}</Pill>
            ) : (
              <Pill danger>{t('Header.failed')}</Pill>
            )}
          </p>
          {t('Header.updatedAt')} :
          <p>
            {FormatDateWithTime(
              get(requestInspection, 'data.attributes.updated_at')
            )}
          </p>
          <p css={{ textTransform: 'uppercase', alignSelf: 'start' }}>
            {get(requestInspection, 'data.attributes.postback_method')}
          </p>
          <p>{get(requestInspection, 'data.attributes.postback_url')}</p>
          <p css={{ alignSelf: 'start' }}>{t('Misc.response')} :</p>
          <pre css={{ whiteSpace: 'pre-wrap' }}>
            <code>
              {JSON.stringify(
                get(requestInspection, 'data.attributes.response'),
                null,
                4
              )}
            </code>
          </pre>
        </div>
      ) : (
        t('ModerationLog.haventSendYet')
      )}
    </React.Fragment>
  )
}

export default RequestInspection
