import axios from 'axios'
import * as Cookies from 'js-cookie'

import { COOKIES_KEY } from '@lib/helpers'

const axiosConfig = {
  responseType: 'json',
  headers: {
    'X-Api-Key': window.gon.X_API_KEY,
    Authorization: Cookies.get(COOKIES_KEY),
    'Accept-Language': 'en',
  },
}

export const axiosClients = {
  defaultV1: {
    client: axios.create({
      ...axiosConfig,
      baseURL: window.gon.CLIENT_URL_V1,
    }),
  },
  blobV1: {
    client: axios.create({
      ...axiosConfig,
      responseType: 'blob',
      baseURL: window.gon.CLIENT_URL_V1,
    }),
  },
  blob: {
    client: axios.create({
      ...axiosConfig,
      responseType: 'blob',
      baseURL: window.gon.CLIENT_URL_V3,
    }),
  },
  bare: {
    client: axios.create(axiosConfig),
  },
}