import React from 'react'

import Select from '@shared/forms/Select'

const RoleSelector = (props) => {
  const { value, isLoading, onChange, t } = props

  return (
    <Select
      label={t('UserManagement.role')}
      name="role"
      value={value}
      isLoading={isLoading}
      onChange={onChange}
      options={[
        {
          label: t('UserManagement.user'),
          value: 'user',
        },
        {
          label: t('UserManagement.admin'),
          value: 'admin',
        },
      ]}
    />
  )
}

export default RoleSelector
