import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Button = styled.button`
  display: inline-flex;
  border: 0;
  border-radius: 100px;
  padding: calc(var(--global-space) * 0.5) calc(var(--global-space) * 1.6);
  cursor: pointer;
  font-family: Montserrat;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: #b0c1d2;
  color: #fff;

  ${(props) => {
    if (props.primary) {
      return css`
        background-color: var(--c-green);
      `
    } else if (props.danger) {
      return css`
        background-color: var(--c-red);
      `
    }
  }}

  ${props => {
    if (props.disabled) {
      return css`
        opacity: 0.4;
        cursor: not-allowed;
      `
    }
  }}
`

export const Input = styled.input`
  border: 1px solid #eaebec;
  border-radius: 2px;
  font-size: 14px;
  letter-spacing: 0.44px;
  display: block;
  width: 100%;
  padding: calc(var(--global-space) * 0.5);
  appearance: none;
  background-color: transparent;

  ${props => {
    if (props.disabled) {
      return css`
        opacity: 0.6;
        background-color: #f1f1f1;
        cursor: not-allowed;
      `
    }
  }}
`

export const Pill = styled.span`
  padding: 5px 12px;
  border-radius: 30px;
  background-color: rgba(70, 215, 206, 0.2);
  color: var(--c-green);
  text-transform: uppercase;
  justify-self: start;
  font-size: 12px;

  ${(props) => {
    if (props.danger) {
      return css`
        background-color: #f9727240;
        color: var(--c-red);
      `
    }
  }}
`
